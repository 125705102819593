import React, { FC, memo } from 'react'
import { Formik } from 'formik'
import { Date as DateUtils, Object as ObjectUtils } from '@base39/ui-utils'
import i18n from '~i18n'
import { DatePicker } from '~components'
import toast from '~helpers/toast'
import LoanApi from '~services/loans'
import Grid from '~components/dumb/Grid'
import Input from '~components/dumb/Form/Input'
import InputPrice from '~components/dumb/Form/InputPrice'
import InputDocumentText from '~components/dumb/Form/InputDocumentText'
import { useStoreState } from '~commons/hooks'
import { FormProps } from '../interfaces'
import { lastPayStubValidationSchema } from './model'

const LastPayStub: FC<FormProps> = ({
  loanId,
  company,
  companyName,
  type,
  loading,
  setLoading,
  disableEdit,
  onApprove,
}) => {
  const formikRef = useStoreState((state) => state.deskDocuments.formikRef)
  const {
    salary: companySalary,
    netSalary: companyNetSalary,
    jobRole: companyJobRole,
    hiredAt: companyHiredAt,
  } = company ?? {}
  const initialValues = {
    tradeName: companyName,
    salary: companySalary,
    netSalary: companyNetSalary,
    jobRole: companyJobRole,
    hiredAt: DateUtils.parseDate(DateUtils.formatDateUTC(companyHiredAt), 'dd/MM/yyyy'),
  }

  const handleConfirm = (values) => {
    setLoading(true)

    const objValueChanged = ObjectUtils.getObjectChanged({
      initialValues,
      changedValues: values,
    })
    const objHasChanged = !ObjectUtils.isObjectEmpty(objValueChanged)

    if (objHasChanged) {
      LoanApi.setUpdateLoan({ loanId, company: objValueChanged })
        .then(() => onApprove(objValueChanged))
        .catch(() => toast.error(i18n.t('general.errorUnknown')))
        .finally(() => {
          setLoading(false)
        })
    } else {
      onApprove(values)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={lastPayStubValidationSchema}
      onSubmit={handleConfirm}
      innerRef={formikRef}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={`${type}-form`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                name="tradeName"
                label={i18n.t('user.companyName')}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <InputPrice
                name="salary"
                label={i18n.t('user.salary')}
                fullWidth
                disabled={disableEdit || loading}
              />
            </Grid>
            <Grid item xs={12}>
              <InputPrice
                name="netSalary"
                label={i18n.t('user.netSalary')}
                fullWidth
                disabled={disableEdit || loading}
              />
            </Grid>
            <Grid item xs={12}>
              <InputDocumentText
                name="jobRole"
                label={i18n.t('user.jobRole')}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={7}>
              <DatePicker
                name="hiredAt"
                label={i18n.t('user.hiredAt')}
                disabled={disableEdit || loading}
                required
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default memo(LastPayStub)
