import { Address, GetByZipCodeResponse } from '~types'
import federativeUnits from '~constants/federativeUnits'

const checkState = (value: string) => {
  const state = federativeUnits.find((obj) => obj.value === value)
  return state ? state.value : ''
}

export const formatAddress = ({
  addressLine,
  city,
  neighborhood,
  state,
  zipCode,
}: GetByZipCodeResponse): Address => ({
  number: '',
  complement: '',
  address: addressLine || '',
  city: city || '',
  neighborhood: neighborhood || '',
  state: checkState(state),
  zipCode: zipCode,
})
