import React, { memo } from 'react'
import { ChipOptionProps } from './interfaces'
import { ChipOptionStyled } from './styles'

const classes = {
  label: 'label',
  root: 'root',
}

const ChipOption = ({ checked, disabled, label, ...rest }: ChipOptionProps) => {
  return (
    <ChipOptionStyled
      classes={classes}
      aria-checked={checked}
      aria-disabled={disabled}
      role="menuitemradio"
      variant="default"
      color="primary"
      label={label}
      disabled={disabled}
      {...rest}
    />
  )
}

ChipOption.defaultProps = {
  checked: false,
}

export default memo(ChipOption)
