import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core'

export { CardActionArea, CardActions, CardContent, CardMedia }

export default Card
