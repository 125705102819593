import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { CreditPolicyApi } from '~services'

export interface UseCreateCreditPolicyProps {
  onSuccess?: ({ id }: { id: string }) => void
  onError?: (result: AxiosError) => void
}

export const useCreateCreditPolicy = ({
  onSuccess,
  onError,
}: UseCreateCreditPolicyProps) => {
  return useMutation(CreditPolicyApi.create, {
    onSuccess: onSuccess,
    onError: onError,
  })
}
