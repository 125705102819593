import styled from 'styled-components'

export const ViewerContent = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`

export const ToolbarStyled = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonContentStyled = styled.div`
  padding: 0px 2px;
`

export const ButtonMarginStyled = styled.div`
  padding: 0px 2px;
  margin-left: auto;
`

export const ButtonPageStyled = styled.div`
  display: flex;
  align-items: center;
`
