import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Grid from '../Grid'

export const ContainerStyled = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
`

export const TitleStyled = styled.h1`
  color: ${({ theme }) => theme.palette.gray[100]};
  font-size: 0.75rem;
  font-family: 'Montserrat SemiBold';
  text-transform: uppercase;
  line-height: 15px;
`

export const LabelStyled = styled.h2`
  color: ${({ theme }) => theme.palette.gray[100]};
  font-size: 0.75rem;
  line-height: 15px;
  font-family: 'Montserrat Regular';
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const ValueStyled = styled.span`
  color: ${({ theme }) => theme.palette.gray[600]};
  font-size: 1rem;
  line-height: 24px;
  font-family: 'Montserrat Regular';
  word-break: break-all;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const NavLinkStyled = styled(NavLink)`
  width: 100%;
  font-size: 14px;
  display: flex;
  text-decoration: none;
  align-items: center;
  height: 33px;
  border-radius: 4px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.gray[550]};

  :hover {
    background-color: ${({ theme }) => theme.palette.gray[400]};
  }

  &.selected {
    color: ${({ theme }) => theme.palette.white[0]};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`
