import React, { memo } from 'react'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AccordionStyled } from './styles'
import { AccordionProps } from './interface'

const Accordion: React.FC<AccordionProps> = ({ title: Title, children, ...props }) => {
  return (
    <AccordionStyled {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{Title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyled>
  )
}

Accordion.defaultProps = {
  defaultExpanded: true,
}

export default memo(Accordion)
