import { useQuery } from 'react-query'
import { CompaniesApi } from '~services'
import { formatCompany } from '~helpers/formatData'

const getCompanyById = (companyId: string) =>
  CompaniesApi.getById(companyId).then(formatCompany)

export const useGetCompanyById = (companyId: string) => {
  return useQuery(['company', companyId], () => getCompanyById(companyId), {
    enabled: !!companyId,
  })
}
