import { CAUSES_OF_REJECTION_LABELS } from '@base39/constants-tools'
import errorBoundary from './errorBoundary.json'
import installments from './installments.json'
import form from './form.json'
import general from './general.json'
import home from './home.json'
import header from './header.json'
import loan from './loan.json'
import proposal from './proposal.json'
import user from './user.json'
import simulation from './simulation.json'
import attachment from './attachment.json'
import constants from './constants.json'
import visualizations from './visualizations.json'
import documents from './documents.json'
import history from './history.json'
import inquiries from './inquiries.json'
import settings from './settings.json'
import creditPolicy from './credit-policy.json'

export default {
  attachment,
  constants,
  header,
  errorBoundary,
  causesOfRejection: CAUSES_OF_REJECTION_LABELS,
  form,
  general,
  home,
  installments,
  loan,
  proposal,
  simulation,
  user,
  visualizations,
  documents,
  history,
  inquiries,
  settings,
  creditPolicy,
}
