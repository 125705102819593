import React, { memo } from 'react'
import { useParams } from 'react-router'
import { useGetCompanyById, useGetLoanById } from '~services/hooks'
import { Params } from './interfaces'
import { MenuStyled, MenuContainerStyled } from './styles'
import { LeftMenu, RightMenu } from './sections'

const Menu = () => {
  const { loanId } = useParams<Params>()
  const { data: loan, isFetching: loadingLoan } = useGetLoanById(loanId)
  const { data: company, isFetching: loadingCompany } = useGetCompanyById(
    loan?.company?.ref,
  )

  return (
    <MenuContainerStyled>
      <MenuStyled aria-label="left-breadcrumb">
        <LeftMenu loan={loan} company={company} loading={loadingCompany || loadingLoan} />
      </MenuStyled>
      <MenuStyled aria-label="breadcrumb">
        <RightMenu loan={loan} loading={loadingLoan} />
      </MenuStyled>
    </MenuContainerStyled>
  )
}

export default memo(Menu)
