import React, { useMemo, useState, useEffect, FC } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash/debounce'
import { Autocomplete } from '@material-ui/lab'
import { CompaniesApi } from '~services'
import i18n from '~i18n'
import { withField } from '~components/dumb/Form/Field'
import { CompanySelectProps } from './interfaces'
import { formatCompanyLabel } from './utils'
import Input from './Input'

const CompanySelect: FC<CompanySelectProps> = (props) => {
  const {
    id,
    name,
    value,
    label,
    onChange,
    required,
    error,
    inputProps,
    disabled,
    ...rest
  } = props
  const [inputValue, setInputValue] = useState(value)
  const [params, setParams] = useState({
    page: 1,
    pageSize: 99999,
    sort: 'tradeName',
    q: value,
  })

  const { data, isLoading } = useQuery(['companies-list', params.q], () =>
    CompaniesApi.getAll(params),
  )

  useEffect(() => {
    const foundCompany = data?.rows.find((company) => company.id === value)

    setInputValue(foundCompany ? formatCompanyLabel(foundCompany) : '')
  }, [value, data])

  const getCompanies = useMemo(
    () => debounce((q) => setParams((data) => ({ ...data, q })), 1500),
    [],
  )

  const handleCompanyChange = ({ target }) => {
    getCompanies(target.value)
  }

  const onSelect = (_, onSelectValue) =>
    onChange({
      target: {
        id,
        name,
        value: onSelectValue,
      },
    })

  const renderInput = (params) => (
    <Input
      params={params}
      name={name}
      label={label}
      inputProps={inputProps}
      required={required}
      error={error}
      onChange={handleCompanyChange}
      isLoading={isLoading}
    />
  )

  return (
    <Autocomplete
      id={id}
      options={data?.rows || []}
      getOptionLabel={formatCompanyLabel}
      inputValue={inputValue}
      onInputChange={(event, onInputChangeValue) => setInputValue(onInputChangeValue)}
      renderOption={formatCompanyLabel}
      renderInput={renderInput}
      onChange={onSelect}
      freeSolo
      disabled={isLoading || disabled}
      {...rest}
    />
  )
}

CompanySelect.defaultProps = {
  value: '',
  label: i18n.t('simulation.allCompanies'),
  'data-testid': 'company-select',
}

export { CompanySelect }
export default withField(CompanySelect)
