import React, { memo, useCallback, useState } from 'react'
import { Router as RouterUtils } from '@base39/ui-utils'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import i18n from '~i18n'
import { Link, InputBase, InputAdornment, IconButton } from '~components'
import { useStoreActions } from '~hooks/useStore'
import { Autocomplete } from '~components/dumb/Form/Autocomplete'
import Divider from '~components/dumb/Divider'
import { OPTIONS_LOAN_FILTER } from '~commons/constants/loan'
import Navigation from '../Navigation'
import {
  ContainerStyled,
  IconButtonStyled,
  RightSectionStyled,
  ToolbarContentStyled,
  TopBarNavigationStyled,
  AppBarStyled,
  IconPaketa,
  FormStyled,
  IconSearch,
} from './styles'
import { Tabs } from './sections'
import MenuContent from './menu'

const menuId = 'header-menu'

function Header() {
  const location = useLocation<{ filter: { value: string; label: string } }>()
  const { logout } = useAuth0()
  const { setTabs } = useStoreActions(({ tabs }) => ({
    ...tabs,
  }))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterValue, setFilterValue] = useState(
    location?.state?.filter || OPTIONS_LOAN_FILTER[0],
  )
  const [inputValue, setInputValue] = useState('')
  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleLogout = useCallback(() => {
    setTabs([])
    logout({ returnTo: window.location.origin })
    sessionStorage.clear()
    handleMenuClose()
  }, [handleMenuClose, setTabs, logout])

  const filterSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    const value = event.target['1']?.value

    RouterUtils.push(`/visualizacoes?search=${value}`, {
      search: value,
      filter: filterValue || OPTIONS_LOAN_FILTER[0],
    })
  }

  const renderTopNavigation = useCallback(
    ({ path, icon, ...rest }) => (
      <Navigation key={path} path={path} icon={icon} {...rest} />
    ),
    [],
  )

  return (
    <ContainerStyled>
      <AppBarStyled classes={{ root: 'appbar' }} position="static" color="inherit">
        <Toolbar>
          <ToolbarContentStyled>
            <TopBarNavigationStyled>
              <Link to="/">
                <IconPaketa data-testid="header-iconPaketa" />
              </Link>
              {MenuContent.map(renderTopNavigation)}
            </TopBarNavigationStyled>
            <Tabs />
            <RightSectionStyled>
              <FormStyled
                noValidate
                onSubmit={filterSubmit}
                data-testid="header-searchForm"
              >
                <InputBase
                  className="filter-input"
                  placeholder={i18n.t('general.search')}
                  data-testid="header-search"
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        type="submit"
                        size="small"
                        data-testid="header-searchButton"
                        aria-label={i18n.t('general.search')}
                      >
                        <IconSearch data-testid="header-iconSearch" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Divider $position="vertical" />
                <Autocomplete
                  fullWidth
                  getOptionLabel={(option: { label: string }) => option.label}
                  options={OPTIONS_LOAN_FILTER}
                  value={filterValue}
                  onChange={(_event, newValue: { label: string; value: string }) => {
                    setFilterValue(newValue)
                  }}
                  inputValue={inputValue}
                  onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  width="400"
                />
              </FormStyled>
              <IconButtonStyled
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                size="small"
                onClick={handleProfileMenuOpen}
                data-testid="header-profile"
              >
                <AccountCircle />
              </IconButtonStyled>
            </RightSectionStyled>
          </ToolbarContentStyled>
        </Toolbar>
      </AppBarStyled>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>{i18n.t('general.logout')}</MenuItem>
      </Menu>
    </ContainerStyled>
  )
}

export default memo(Header)
