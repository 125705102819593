import { useQuery } from 'react-query'
import { CreditPolicyApi } from '~services'
import { formatCreditPolicies } from '~commons/helpers/formatData'
import { CreditPoliciesParams } from '~services/creditPolicy'

const getCreditPolicies = (params: CreditPoliciesParams) =>
  CreditPolicyApi.getCreditPolicies(params).then(formatCreditPolicies)

export const useGetCreditPolicies = (params: CreditPoliciesParams) => {
  return useQuery(['creditPolicy', params], () => getCreditPolicies(params))
}
