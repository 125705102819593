import React, { Fragment, ReactNode } from 'react'
import { useParams } from 'react-router'
import { Header, Menu, Tags, Assign } from '~components'
import { useGetLoanById } from '~services/hooks'
import {
  ContainerStyled,
  ContentStyled,
  LayoutStyled,
  CheckListStyled,
  GridStyled,
} from './styles'

interface IProps {
  menu?: boolean
  showSidebar?: boolean
  showHeader?: boolean
  children: ReactNode
}

const Layout = ({ children, showSidebar, showHeader, menu }: IProps) => {
  const { loanId } = useParams<{ loanId: string }>()

  const { data: loan } = useGetLoanById(loanId)

  const renderSidebar = () => {
    if (menu && loan) {
      const { id, assign, tags } = loan

      return (
        <Fragment>
          <Tags defaultValue={tags} loanId={id} />
          <Assign assign={assign} companyId={loan?.company?.ref} loanId={id} />
        </Fragment>
      )
    }
  }

  return (
    <ContainerStyled>
      {showHeader && <Header />}
      <LayoutStyled>
        {menu && <Menu />}
        <GridStyled>
          {showSidebar && <CheckListStyled>{renderSidebar()}</CheckListStyled>}
          <ContentStyled>{children}</ContentStyled>
        </GridStyled>
      </LayoutStyled>
    </ContainerStyled>
  )
}

Layout.defaultProps = {
  showHeader: true,
  showSidebar: true,
  menu: true,
}

export default Layout
