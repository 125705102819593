import styled, { css } from 'styled-components'
import Slider from '@material-ui/core/Slider'
import Typography from '~components/dumb/Typography'
import { PreviewImageStyledProps } from './interfaces'

export const PreviewImageStyled = styled.div<PreviewImageStyledProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 100%;
  visibility: ${({ visibility }) => (visibility ? 'visible' : 'hidden')};
  background-color: ${({ theme }) => theme.palette.gray[100]};
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  position: relative;
  padding: ${({ theme }) => theme.spacing(1)}px;
  transition: 0.5s all;
  box-shadow: inset 0 -1px 0 0 ${({ $bottomBoxShadowColor }) => $bottomBoxShadowColor};
  border-radius: 4px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      height: 38px;
      padding: 0 ${theme.spacing(1)}px;
    }
  `}
`

export const WrapPreviewCentered = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
`

export const WrapEditImageOptionStyled = styled.div`
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
  text-align: center;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: fit-content;
      height: 100%;
      flex-direction: row;
      align-items: center;
      margin: 0 ${theme.spacing(2)}px;
  `}
`

export const SliderTypographyStyled = styled(Typography)`
  margin: 0 ${({ theme }) => theme.spacing(1)}px 0 0;
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const SliderStyled = styled(Slider)`
  width: 70px;
  color: ${({ theme }) => theme.palette.primary.contrastText};

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: 80px;
  `}
`

export const WrapRotateIconsStyled = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  justify-content: space-around;

  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }
`
