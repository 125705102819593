import React, { FC } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'

interface IProps extends CheckboxProps {
  label?: string
}

const MuiCheckbox: FC<IProps> = ({ label, ...props }) => {
  return label ? (
    <FormControlLabel
      control={<Checkbox {...props} />}
      label={label}
      data-testid="form-control-label"
    />
  ) : (
    <Checkbox {...props} data-testid="checkbox" />
  )
}

export default MuiCheckbox
