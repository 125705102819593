import { lazy } from 'react'

const Company = lazy(() => import('~containers/Company'))
const DefaultLayout = lazy(() => import('~layouts/default'))

const path = '/empresas'

const Companies = [
  {
    path: `${path}/:companyId`,
    layout: DefaultLayout,
    component: Company,
    exact: true,
  },
]

export default Companies
