import i18n from '~i18n'
import toast from '~helpers/toast'

export const ERROR_ACTIONS = {
  500: () => toast.error(i18n.t('general.errorUnknown')),
  UNKNOWN: () => toast.error(i18n.t('general.errorUnknown')),
}

export const onResponseError = (error) => {
  const status = error?.response?.status
  const apiInterceptors = error?.config?.interceptors
  const action =
    apiInterceptors?.[status] ?? (ERROR_ACTIONS[status] || ERROR_ACTIONS.UNKNOWN)

  if (action) {
    action()
  }

  return Promise.reject(error)
}
