import React, { memo } from 'react'
import { EmptyState } from '~components'
import Table from './Table'
import { TableDataProps } from './interfaces'

function TableData(props: TableDataProps) {
  const { noDataLabel, noDataIcon, rows, loading } = props

  if (noDataLabel && !rows?.length && !loading) {
    return <EmptyState icon={noDataIcon} label={noDataLabel} />
  }

  return <Table {...props} />
}

export default memo(TableData)
