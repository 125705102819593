import React, { forwardRef } from 'react'
import MuiInput from '@material-ui/core/Input'
import { withField } from '../Field'
import SelectOptions from '../../SelectOptions'
import { SelectCoreStyled } from './style'
import { OptionsProps, SelectProps } from './interface'

const Select = ({ options, children, labelKey, valueKey, ...props }: SelectProps) => {
  const renderSelectOptions = () => (
    <SelectCoreStyled {...props} native input={<MuiInput />}>
      <SelectOptions options={options} labelKey={labelKey} valueKey={valueKey} />
    </SelectCoreStyled>
  )

  const renderDefaultSelect = () => (
    <SelectCoreStyled {...props} native input={<MuiInput />}>
      {children}
    </SelectCoreStyled>
  )

  return options ? renderSelectOptions() : renderDefaultSelect()
}

const SelectItem = forwardRef<HTMLOptionElement, OptionsProps>((props, ref) => (
  <option {...props} ref={ref} />
))

export { Select, SelectItem }
export default withField(Select)
