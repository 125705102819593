import { useQuery } from 'react-query'
import { CreditPolicyApi } from '~services'
import { formatCreditRevisions } from '~commons/helpers/formatData'
import { RevisionParams } from '~services/creditPolicy'

const getRevisionsById = (params: RevisionParams) =>
  CreditPolicyApi.getRevisionsById(params).then(formatCreditRevisions)

export const useGetRevisionsById = (params: RevisionParams) => {
  return useQuery(['creditPolicy-revisions-id', params], () => getRevisionsById(params), {
    enabled: !!params?.revisionId,
  })
}
