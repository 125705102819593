import styled from 'styled-components'
import { DialogContent } from '@material-ui/core'
import Button from '../../Button'

import { Paragraph } from '../../Text'

export const ParagraphStyled = styled(Paragraph)`
  font-size: 0.875rem;
  text-align: center;
`
export const ButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.gray[550]};
`
export const DialogContentStyled = styled(DialogContent)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`
