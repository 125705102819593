import styled from 'styled-components'

const SQUARE_TYPES = {
  square: {
    width: '16px',
  },
  tiny: {
    padding: 0,
    width: '16px',
  },
  large: {
    padding: '0.25rem',
    'border-radius': '2px',
    'text-transform': 'none',
  },
}

type SQUARE_KEYS = keyof typeof SQUARE_TYPES

export const SquareStyled = styled.div<{ type?: SQUARE_KEYS }>`
  background-color: ${({ color }) => color || '#000'};
  color: #fff;
  text-transform: uppercase;
  line-height: 10px;
  height: 16px;
  font-size: 0.5625rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;

  ${({ type }) => SQUARE_TYPES[type]}
`
