import React, { memo } from 'react'
import i18n from '~i18n/index'
import { DocumentStatusStyled } from './styles'
import { DocumentStatusInterface } from './interfaces'

const DocumentStatus = ({ type }: DocumentStatusInterface) => (
  <DocumentStatusStyled type={type}>
    {i18n.t(`documents.status.${type}`)}
  </DocumentStatusStyled>
)

export default memo(DocumentStatus)
