import React from 'react'
import { RowStyled, TitleStyled, DescriptionStyled } from './styles'

const ToastLayout = ({ title, description, icon }) => {
  return (
    <>
      <RowStyled data-testid="toast-icon">{icon}</RowStyled>
      <RowStyled>
        <TitleStyled data-testid="toast-title">{title}</TitleStyled>
      </RowStyled>
      <RowStyled>
        <DescriptionStyled data-testid="toast-description">
          {description}
        </DescriptionStyled>
      </RowStyled>
    </>
  )
}

export default ToastLayout
