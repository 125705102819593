import { useQuery } from 'react-query'
import { LoanApi } from '~services'
import { formatLogs } from '~helpers/formatData'

const getLogsByLoan = (loanId: string) => LoanApi.getLogsFromLoan(loanId).then(formatLogs)

export const useGetLogsByLoan = (loanId: string, isDisable?: boolean) => {
  return useQuery(['logs', loanId], () => getLogsByLoan(loanId), {
    enabled: !!loanId && !isDisable,
  })
}
