import React, { FC } from 'react'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import i18n from '~i18n'
import SliderTooltip from '../SliderTooltip'
import { ImageToolsProps } from './interfaces'
import {
  PreviewImageStyled,
  WrapPreviewCentered,
  WrapEditImageOptionStyled,
  SliderTypographyStyled,
  SliderStyled,
  WrapRotateIconsStyled,
} from './styles'

const ImageTools: FC<ImageToolsProps> = ({
  path,
  imageSettings,
  setImageSettings,
  handleRotateImage,
  updateImageSetting,
  displayEditor,
}) => {
  return (
    <>
      <PreviewImageStyled
        visibility={path}
        show={displayEditor}
        $bottomBoxShadowColor="#e2e2ea"
      >
        <WrapPreviewCentered>
          <WrapEditImageOptionStyled>
            <SliderTypographyStyled gutterBottom>
              {i18n.t('proposal.imageControllers.brightness')}
            </SliderTypographyStyled>
            <SliderStyled
              ValueLabelComponent={SliderTooltip}
              aria-label={i18n.t('proposal.imageControllers.brightness')}
              defaultValue={0}
              value={Number(imageSettings.brightness)}
              step={10}
              min={-100}
              onChange={(_, value) =>
                setImageSettings(updateImageSetting({ brightness: value }))
              }
            />
          </WrapEditImageOptionStyled>
          <WrapEditImageOptionStyled>
            <SliderTypographyStyled gutterBottom>
              {i18n.t('proposal.imageControllers.contrast')}
            </SliderTypographyStyled>
            <SliderStyled
              ValueLabelComponent={SliderTooltip}
              aria-label={i18n.t('proposal.imageControllers.contrast')}
              defaultValue={0}
              value={Number(imageSettings.contrast)}
              step={10}
              min={-100}
              onChange={(_, value) =>
                setImageSettings(updateImageSetting({ contrast: value }))
              }
            />
          </WrapEditImageOptionStyled>
          <WrapEditImageOptionStyled>
            <SliderTypographyStyled gutterBottom>
              {i18n.t('proposal.imageControllers.flip')}
            </SliderTypographyStyled>
            <WrapRotateIconsStyled>
              <RotateLeftIcon onClick={() => handleRotateImage(false)} />
              <RotateRightIcon onClick={() => handleRotateImage(true)} />
            </WrapRotateIconsStyled>
          </WrapEditImageOptionStyled>
        </WrapPreviewCentered>
      </PreviewImageStyled>
    </>
  )
}

export default ImageTools
