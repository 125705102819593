import styled from 'styled-components'
import { Title } from '~components/dumb/Text'

export const TitleStyled = styled(Title)`
  font-size: 0.875rem;
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  border-radius: 4px;
  text-transform: none;
  background-color: ${({ theme }) => theme.palette.white[300]};
  color: ${({ theme }) => theme.palette.gray[550]};
`
