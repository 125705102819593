import styled from 'styled-components'
import Card, { CardContent } from '../Card'

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
  box-shadow: none;
  height: 100%;
  padding-bottom: 72.5px;
`

export const CardContentStyled = styled(CardContent)`
  display: inherit;
  flex-direction: inherit;
  height: 100%;
`
