import React, { memo } from 'react'
import Pagination, { IPaginationProps } from '../Pagination'
import { PaginationContent } from './styles'

function PaginationUI({ count, page, onChange }: IPaginationProps) {
  return (
    <PaginationContent data-testid="pagination-content">
      <Pagination count={count} page={page} onChange={onChange} />
    </PaginationContent>
  )
}

export default memo(PaginationUI)
