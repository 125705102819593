import React, { FC, memo } from 'react'
import { TitleWithDividerProps } from './interfaces'
import { TitleStyled } from './styles'

const TitleWithDivider: FC<TitleWithDividerProps> = ({ title }) => {
  return (
    <div>
      <TitleStyled>{title}</TitleStyled>
    </div>
  )
}

export default memo(TitleWithDivider)
