import React, { FC } from 'react'
import { ColoredSquare } from '~components'
import i18n from '~i18n'
import { SQUARE_STATUS } from '~constants/squareStatus'
import { ALL_LOAN_STATUS } from '~constants/loan'
import { Types } from '~components/dumb/ColoredSquare'
import { ReactComponent as CloseIcon } from '~assets/images/svg/close.svg'
import { TabProps } from './interfaces'
import {
  TabStyled,
  TextContainerStyled,
  ContentStyled,
  TitleStyled,
  SubtitleStyled,
  IconButtonStyled,
  WrapperSquareStyled,
} from './styles'

const Tab: FC<TabProps> = ({
  title,
  subtitle,
  selected,
  onClose,
  onClick,
  tooltip = '',
  loanStatus,
}) => {
  const square = SQUARE_STATUS[loanStatus]?.color

  const handleClose = (ev) => {
    ev.stopPropagation()

    onClose()
  }

  return (
    <TabStyled role="button" aria-label={title} selected={selected} onClick={onClick}>
      <ContentStyled>
        <TextContainerStyled>
          <TitleStyled>{title}</TitleStyled>
          <SubtitleStyled>{subtitle}</SubtitleStyled>
        </TextContainerStyled>
        <IconButtonStyled aria-label={i18n.t('general.close')} onClick={handleClose}>
          <CloseIcon />
        </IconButtonStyled>
      </ContentStyled>
      <WrapperSquareStyled>
        <ColoredSquare
          color={square}
          tooltip={tooltip}
          initial={i18n.t(ALL_LOAN_STATUS[loanStatus])}
          type={Types.large}
        />
      </WrapperSquareStyled>
    </TabStyled>
  )
}

export default Tab
