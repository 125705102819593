import React from 'react'
import { CAUSES_OF_REJECTION } from '@base39/constants-tools'
import i18n from '~i18n'
import Select from '~components/dumb/Form/Select'
import { RejectItem } from '..'

const RejectDialogContent: React.FC = () => {
  return (
    <>
      {i18n.t('proposal.confirmDisapproveSubtitle')}
      <Select name="causeRejection" label={i18n.t('proposal.causeRejection')} fullWidth>
        <option value="" />
        {CAUSES_OF_REJECTION.map((item) => (
          <RejectItem key={item.value} value={item.value} label={item.label} />
        ))}
      </Select>
    </>
  )
}

export default RejectDialogContent
