import React from 'react'
import { DialogActions } from '~components/dumb/Dialog'
import { ActionsWrapperStyled, ActionsContentStyled } from './styles'
import { TActions } from './interfaces'

const Actions: TActions = ({ children }) => {
  return (
    <DialogActions>
      <ActionsWrapperStyled>
        <ActionsContentStyled>{children}</ActionsContentStyled>
      </ActionsWrapperStyled>
    </DialogActions>
  )
}

export default Actions
