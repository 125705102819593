import React, { FC, MouseEventHandler } from 'react'
import { Close as CloseIcon } from '@material-ui/icons'
import i18n from '~i18n'
import { ButtonStyled, DialogTitleStyled, HeaderWrapperStyled } from './styles'

interface IProps {
  onClose?: MouseEventHandler
}

const DialogHeader: FC<IProps> = ({ children, onClose }) => (
  <HeaderWrapperStyled data-testid="dialog-header">
    {onClose && (
      <ButtonStyled
        color="inherit"
        onClick={onClose}
        aria-label={i18n.t('general.close')}
      >
        <CloseIcon />
      </ButtonStyled>
    )}
    <DialogTitleStyled disableTypography>{children}</DialogTitleStyled>
  </HeaderWrapperStyled>
)

export default DialogHeader
