import { Date as DateUtils, Money as MoneyUtils } from '@base39/ui-utils'
import { Payment, PaymentDueBalance } from '~types'
import { FormattedPayment, FormattedPaymentsProps } from './interfaces'

export const formatPaymentStatus = (status: string, date: Date) => {
  if (status === 'PAID') {
    return status
  }

  const currentDate = DateUtils.getStartDay(new Date())
  const dueDate = DateUtils.getStartDay(new Date(date))

  return dueDate < currentDate ? 'EXPIRED' : status
}

const formatPayment = (payment: Payment, acquittanceValue?: number) => {
  return {
    dueDate: DateUtils.formatDateUTC(payment.dueDate),
    installmentValue: MoneyUtils.formatCurrency(payment.installmentValue),
    acquittanceValue: MoneyUtils.formatCurrency(acquittanceValue || 0),
    status: formatPaymentStatus(payment.status, payment.dueDate),
  }
}

export const formatPayments = ({
  payments,
  dueBalanceResponse,
}: FormattedPaymentsProps): FormattedPayment[] => {
  return payments?.map((payment) => {
    const formattedPayment: FormattedPayment = payment

    if (dueBalanceResponse?.payments?.length) {
      const [paymentData] = dueBalanceResponse?.payments?.filter(
        (paymentDueBalance: PaymentDueBalance) =>
          paymentDueBalance.payment === payment.id,
      )

      if (paymentData) {
        formattedPayment.acquittanceValue = paymentData.acquittanceValue
        formattedPayment.checked = true
      }
    }

    formattedPayment.formatted = formatPayment(payment, formattedPayment.acquittanceValue)

    return formattedPayment
  })
}
