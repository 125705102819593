import { Fund, GetFunds, Financial } from '~types'
import { FormattedCreditPolicyProps } from '~containers/CreditPolicy/Create/interfaces'
import {
  CreditPolicies,
  CreditPolicy as CreditPolicyInterface,
  CreditPolicyRevisions,
} from '~types/creditPolicy'
import { ApiSls, RestApi } from './api'

const path = '/credit-policy'

export interface GetFundParams {
  page: number
  pageSize: number
}

export interface GetFinancialParams extends GetFundParams {}

export interface CreditPoliciesParams {
  page?: number
  pageSize?: number
  sort?: string
}

export interface RevisionParams {
  revisionId: string
  page: number
  pageSize?: number
  sort?: string
}

const CreditPolicy = {
  getById(creditPolicyId: string) {
    return ApiSls.customRequest<CreditPolicyInterface>(
      `/v1/credit-policies/${creditPolicyId}`,
      {
        method: 'GET',
      },
    )
  },
  getSimulateId(simulateId: string) {
    return ApiSls.customRequest(`/v1${path}/simulation/${simulateId}`, {
      method: 'GET',
    })
  },
  getByLoanId(loanId: string) {
    return ApiSls.customRequest(`/v1${path}/loan/${loanId}`, {
      method: 'GET',
    })
  },
  getFund(fundId: string) {
    return ApiSls.customRequest<Fund>(`/v1/credit-lines/funds/${fundId}`, {
      method: 'GET',
    })
  },
  getFunds(params: GetFundParams) {
    return RestApi.customRequest<GetFunds>(`/funds`, {
      method: 'GET',
      params,
    })
  },
  getCreditPolicies(params: CreditPoliciesParams) {
    return ApiSls.customRequest<CreditPolicies>('/v1/credit-policies/', {
      method: 'GET',
      params,
    })
  },
  getFinancial(params: GetFinancialParams) {
    return RestApi.customRequest<Financial>(`/financial`, {
      method: 'GET',
      params,
    })
  },
  create(data: FormattedCreditPolicyProps) {
    return ApiSls.customRequest(`v1/credit-policies/`, {
      method: 'POST',
      data,
    })
  },
  getRevisionsById(params: RevisionParams) {
    return ApiSls.customRequest<CreditPolicyRevisions>(
      `/v1/credit-policies/revision/${params.revisionId}`,
      {
        method: 'GET',
        params,
      },
    )
  },
  update(data: FormattedCreditPolicyProps) {
    return ApiSls.customRequest(`v1/credit-policies/revision`, {
      method: 'POST',
      data,
    })
  },
}

export default CreditPolicy
