import { ApiSls } from '../api'
import {
  GetSimulationByInstallmentIdParams,
  GetSimulationByInstallmentId,
} from './interfaces'

const path = '/credit-lines'

const CreditLines = {
  getSimulationByInstallmentId({
    selectedInstallment,
    simulateId,
  }: GetSimulationByInstallmentIdParams) {
    return ApiSls.customRequest<GetSimulationByInstallmentId>(
      `/v1${path}/simulations/${simulateId}/installments/${selectedInstallment}`,
      {
        method: 'GET',
      },
    )
  },
}

export default CreditLines
