import { useQuery } from 'react-query'
import { CreditPolicyApi } from '~services'

const getCreditPolicyById = (creditPolicyId: string) =>
  CreditPolicyApi.getById(creditPolicyId)

export const useGetCreditPolicyById = (creditPolicyId: string) => {
  return useQuery(
    ['creditPolicy-by-id', creditPolicyId],
    () => getCreditPolicyById(creditPolicyId),
    {
      enabled: !!creditPolicyId,
    },
  )
}
