import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import { ButtonProps } from './interface'

const buttonsStyles = {
  success: css`
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.success.contrastText};
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 0.875rem;
    font-family: 'Montserrat SemiBold';
    width: 198px;
    white-space: nowrap;

    :hover {
      background-color: ${({ theme }) => theme.palette.success.main};
      filter: brightness(0.9);
    }
  `,
  error: css`
    background-color: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.error.contrastText};
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 0.875rem;
    font-family: 'Montserrat SemiBold';
    width: 198px;
    margin-left: 1.5rem;

    &.MuiButton-outlined {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.error.main};
      border-color: ${({ theme }) => theme.palette.error.main};
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.error.main};
      filter: brightness(0.9);

      &.MuiButton-outlined {
        background-color: transparent;
        color: ${({ theme }) => theme.palette.error.main};
        border-color: ${({ theme }) => theme.palette.error.main};
      }
    }
  `,
}

export const ButtonStyled = styled(Button)<{ $customColor: ButtonProps['color'] }>`
  padding: 0.5rem 1.25rem 0.5rem;
  box-shadow: none;
  text-transform: none;
  border-radius: 0.375rem;

  .MuiButton-label {
    font-family: 'Montserrat SemiBold';
    font-size: 0.875rem;
  }

  :hover {
    box-shadow: none;
  }

  ${({ $customColor }) => $customColor && buttonsStyles[$customColor]}
`
