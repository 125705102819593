import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '~components/dumb/Loading'

const Auth = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0()
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently])

  return <Loading />
}

export default Auth
