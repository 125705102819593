import React, { memo } from 'react'
import { ReactComponent as EditIcon } from '~assets/images/svg/edit.svg'
import i18n from '~commons/i18n'
import { EditButtonProps } from './interfaces'
import { ButtonStyled } from './styles'

const EditButton = ({ ...props }: EditButtonProps) => {
  return (
    <ButtonStyled
      variant="outlined"
      size="small"
      aria-label={i18n.t('general.edit')}
      {...props}
    >
      <EditIcon />
    </ButtonStyled>
  )
}

export default memo(EditButton)
