import React, { ReactElement, FC } from 'react'
import { Viewer, Worker, LocalizationMap } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin, ToolbarProps } from '@react-pdf-viewer/default-layout'
import { rotatePlugin } from '@react-pdf-viewer/rotate'
import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json'
import { PdfViewerProps } from './interfaces'
import {
  ViewerContent,
  ToolbarStyled,
  ButtonContentStyled,
  ButtonMarginStyled,
  ButtonPageStyled,
} from './styles'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const PdfViewer: FC<PdfViewerProps> = ({ url }) => {
  const rotatePluginInstance = rotatePlugin()
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance

  const showToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots

        return (
          <ToolbarStyled>
            <ButtonContentStyled>
              <ZoomOut />
            </ButtonContentStyled>
            <ButtonContentStyled>
              <Zoom />
            </ButtonContentStyled>
            <ButtonContentStyled>
              <ZoomIn />
            </ButtonContentStyled>
            <ButtonMarginStyled>
              <GoToPreviousPage />
            </ButtonMarginStyled>
            <ButtonPageStyled>
              <CurrentPageInput />
              <ButtonContentStyled> / </ButtonContentStyled>
              <NumberOfPages />
            </ButtonPageStyled>
            <ButtonContentStyled>
              <GoToNextPage />
            </ButtonContentStyled>
            <ButtonMarginStyled>
              <RotateBackwardButton />
            </ButtonMarginStyled>
            <ButtonContentStyled>
              <RotateForwardButton />
            </ButtonContentStyled>
            <ButtonContentStyled>
              <EnterFullScreen />
            </ButtonContentStyled>
            <ButtonContentStyled>
              <Print />
            </ButtonContentStyled>
          </ToolbarStyled>
        )
      }}
    </Toolbar>
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: showToolbar,
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  })

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
      <ViewerContent>
        <Viewer
          fileUrl={url}
          plugins={[defaultLayoutPluginInstance, rotatePluginInstance]}
          localization={pt_PT as unknown as LocalizationMap}
        />
      </ViewerContent>
    </Worker>
  )
}

export default PdfViewer
