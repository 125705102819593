import { useRequest } from 'ahooks'
import { UseLazyRequest } from './interfaces'

function useLazyRequest<R>(
  service: R,
  { onError, onCompleted, manual, ready }: UseLazyRequest,
): [any, { data: any; loading: boolean; error: any }] {
  const {
    data,
    error,
    loading,
    run: fetchData,
    ...rest
  } = useRequest(service, {
    manual: manual ?? true,
    ready: ready ?? true,
    throwOnError: onError ?? true,
    onSuccess: (result: any, params: any) => {
      if (onCompleted) {
        onCompleted(result, { params })
      }
    },
    onError: (err: any) => {
      if (onError) {
        onError(err)
      }
    },
  })

  return [fetchData, { data, loading, error, ...rest }]
}

export default useLazyRequest
