import React from 'react'
import Header from './Header'
import Content from './Content'
import MediaViewer from './MediaViewer'
import DocumentForm from './DocumentForm'
import AttachmentsData from './AttachmentsData'
import Actions from './Actions'
import { TDocumentModal } from './interfaces'
import { DialogWrapperStyled } from './styles'

const DocumentModal: TDocumentModal = ({ children, open }) => {
  return (
    <DialogWrapperStyled fullWidth maxWidth="lg" open={open}>
      {children}
    </DialogWrapperStyled>
  )
}

DocumentModal.Content = Content
DocumentModal.MediaViewer = MediaViewer
DocumentModal.Header = Header
DocumentModal.DocumentForm = DocumentForm
DocumentModal.AttachmentsData = AttachmentsData
DocumentModal.Actions = Actions

export default DocumentModal
export { Actions, MediaViewer, Header, Content, DocumentForm, AttachmentsData }
