import styled from 'styled-components'
import { Button } from '~components'

export const ButtonStyled = styled(Button)`
  width: 24px;
  min-width: 16px;
  padding: 0;
  border-radius: 4px;
  height: 24px;
  color: ${({ theme }) => theme.palette.gray[550]};
  border-color: ${({ theme }) => theme.palette.gray[550]};
  :hover {
    color: ${({ theme }) => theme.palette.error.main};
    border-color: ${({ theme }) => theme.palette.error.main};
    filter: brightness(0.9);

    svg {
      path {
        fill: ${({ theme }) => theme.palette.error.main};
      }
    }
  }

  &.Mui-disabled {
    opacity: 50%;
  }
`
