import { ITag } from '~models/loan'
import { PaymentsRequest } from '~containers/Installments/interfaces'
import { RestApi, ApiSls } from '../api'
import {
  GetLoansParams,
  ICreateSplitBillet,
  IDocumentsRequest,
  IInstallmentRequest,
  SetLoanStatusParams,
  SetLoanStatusProps,
  UpdateLoanBankParams,
  UpdateLoanParams,
  UpdateLoanProposalParams,
} from './interfaces'

const path = '/loans'

const Loans = {
  getLoanById(id: string) {
    return ApiSls.customRequest(`/v1${path}/${id}`, {
      method: 'GET',
    })
  },
  getLoans(params: GetLoansParams) {
    return ApiSls.customRequest(`/v1${path}/`, { method: 'GET', params })
  },
  setLoanStatus({ id, status, note }: SetLoanStatusProps) {
    return RestApi.customRequest(`${path}/${id}/status`, {
      method: 'POST',
      params: { status, note },
    })
  },
  setTags(id: string, tags: ITag[]) {
    return RestApi.customRequest(`${path}/${id}/tags`, {
      method: 'PATCH',
      data: { tags },
    })
  },
  setAssign(id: string, data) {
    return RestApi.customRequest(`${path}/${id}/assign`, { method: 'PATCH', data })
  },
  getLogsFromLoan(id: string) {
    return RestApi.customRequest(`${path}/${id}/logs`, {
      method: 'GET',
    })
  },
  getOptionalDismiss(id: string) {
    return RestApi.customRequest(`${path}/${id}/optional-dismiss`, {
      method: 'GET',
    })
  },
  getDocuments(params: IDocumentsRequest) {
    return RestApi.customRequest(`${path}/${params.id}/documents`, {
      method: 'GET',
      params,
    })
  },
  setOptionalDismiss(id: string, data) {
    return RestApi.customRequest(`${path}/${id}/optional-dismiss`, {
      method: 'PUT',
      data,
    })
  },

  updateLoanProposal({ data, id }: UpdateLoanProposalParams) {
    return RestApi.customRequest<void>(`${path}/${id}`, { method: 'PUT', data })
  },

  getLoanDueBalance(id: string, date?: string) {
    return RestApi.customRequest<string, PaymentsRequest>(
      `${path}/${id}/due-balance${date ? `?paymentDate=${date}` : ''}`,
      {
        method: 'GET',
      },
    )
  },
  createAcquittanceBillet(data: IInstallmentRequest) {
    return RestApi.customRequest(`${path}/${data.id}/create-acquittance-billet`, {
      method: 'POST',
      data,
    })
  },
  createRefinancingLoan(data: IInstallmentRequest) {
    return RestApi.customRequest(`${path}/${data.id}/create-refinancing-loan`, {
      method: 'POST',
      data,
    })
  },
  createSplitBillet(data: ICreateSplitBillet) {
    return RestApi.customRequest(`${path}/${data.id}/split-billet`, {
      method: 'POST',
      data,
    })
  },
  editSplitBillet(data: ICreateSplitBillet) {
    return RestApi.customRequest(`${path}/${data.id}/split-billet`, {
      method: 'PUT',
      data,
    })
  },
  removeSplitBillet(data) {
    return RestApi.customRequest(`${path}/${data.id}/billet`, {
      method: 'DELETE',
      data,
    })
  },
  getLoansCount() {
    return ApiSls.customRequest(`v1${path}/count`, { method: 'GET' })
  },
  getCountInProgress() {
    return RestApi.customRequest(`${path}/count-progress`, { method: 'GET' })
  },
  getLoansAssign(params) {
    return ApiSls.customRequest(`/v1${path}/assign`, { method: 'GET', params })
  },
  getAcquittanceBillets(id: string) {
    return ApiSls.customRequest(`/v1${path}/acquittance-billets/${id}`, {
      method: 'GET',
    })
  },
  setUpdateLoan(data: UpdateLoanParams) {
    return ApiSls.customRequest(`/v1${path}/${data.loanId}`, { method: 'PATCH', data })
  },
  updateLoanBankOld({ bank, loanId }: UpdateLoanBankParams) {
    return RestApi.customRequest(`${path}/${loanId}/bank`, {
      method: 'PATCH',
      data: { bank },
    })
  },
  updateLoanBankData({ bank, loanId }: UpdateLoanBankParams) {
    return ApiSls.customRequest(`/v1/bank${path}/${loanId}/update`, {
      method: 'PATCH',
      data: bank,
    })
  },
  setAcquittance({ loanId, acquittance }: SetLoanStatusParams) {
    return RestApi.customRequest(`${path}/${loanId}/acquittance`, {
      method: 'PATCH',
      data: { acquittance },
    })
  },
}

export * from './interfaces'
export default Loans
