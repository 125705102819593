import { ApiSls } from './api'

const path = '/tools/s3'

const Storage = {
  get(data) {
    return ApiSls.customRequest(`/v1${path}/sign`, { method: 'POST', data })
  },
}

export default Storage
