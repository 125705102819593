import styled from 'styled-components'
import Typography from '~components/dumb/Typography'

export const Text = styled(Typography)`
  font-size: 0.75rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.black[0]};
  line-height: 1rem;
`

export const TitleStyled = styled(Text)`
  font-family: 'Montserrat SemiBold';
`

export const ValueStyled = styled(Text)`
  font-family: 'Montserrat Regular';
`
export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.palette.white[300]};
  height: 66px;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;

  :not(:first-child):not(:last-child) {
    margin: ${({ theme }) => theme.spacing(0, 3)};
  }
`
