import { Action, action, Computed, computed } from 'easy-peasy'

type PayloadTab = {
  id: string
  status: string
  company: {
    tradeName: string
    cnpj: string
  }
  user: {
    name: string
    cpf: string
  }
}

type LoanId = string

export interface TabsModel {
  tabs: PayloadTab[]
  addTab: Action<TabsModel, PayloadTab>
  removeTab: Action<TabsModel, LoanId>
  setTabs: Action<TabsModel, PayloadTab[] | []>
  getTabs: Computed<TabsModel, any>
}

const tabs: TabsModel = {
  tabs: [],
  addTab: action((state, payload: PayloadTab) => {
    if (state.tabs.length > 0) {
      const tabIndex = state.tabs.findIndex((tab) => tab.id === payload.id)

      if (tabIndex < 0) {
        state.tabs.push(payload)
      } else {
        state.tabs[tabIndex] = payload
      }
    } else {
      state.tabs.push(payload)
    }
  }),
  removeTab: action((state, payload: LoanId) => {
    const tabsFiltered = state.tabs.filter((tab) => tab.id !== payload)
    state.tabs = tabsFiltered
  }),
  setTabs: action((state, payload: PayloadTab[] | []) => {
    state.tabs = payload
  }),
  getTabs: computed((state: any) => () => {
    return state.tabs
  }),
}

export default tabs
