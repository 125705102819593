import React, { FC, memo } from 'react'
import { CardActions } from '../Card'
import { Header } from './sections'
import { ContainerProps } from './interfaces'
import { CardStyled, CardContentStyled } from './styles'

const Container: FC<ContainerProps> = (props) => {
  const { children, actions, company, loan, loading, isHeaderActive, ...rest } = props

  return (
    <CardStyled data-testid={props['data-testid']} {...rest}>
      {isHeaderActive && <Header loan={loan} company={company} loading={loading} />}

      <CardContentStyled>{children}</CardContentStyled>
      {actions && <CardActions data-testid="container-actions">{actions}</CardActions>}
    </CardStyled>
  )
}

Container.defaultProps = {
  'data-testid': 'container',
  isHeaderActive: false,
}

export default memo(Container)
