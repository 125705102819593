import styled from 'styled-components'

export const ButtonStyled = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.white[0]};
`

export const WrapperStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  > div {
    height: 100%;
    width: 100%;
    padding: 0;

    > button {
      box-shadow: none !important;
      background: transparent;
      border: 0;

      > * {
        display: none;
      }
    }
  }
`
