import { useQuery } from 'react-query'
import { CreditPolicyApi } from '~services'
import { formatCreditPolicy } from '~helpers/formatData'

const getCreditPolicyBySimulateId = (simulateId: string) =>
  CreditPolicyApi.getSimulateId(simulateId).then(formatCreditPolicy)

export const useGetCreditPolicyBySimulateId = (simulateId: string) => {
  return useQuery(
    ['creditPolicy-by-simulate', simulateId],
    () => getCreditPolicyBySimulateId(simulateId),
    {
      enabled: !!simulateId,
    },
  )
}
