import { createStore, createTypedHooks, persist, useStoreRehydrated } from 'easy-peasy'
import model, { StoreModel } from '../../models'

const typedHooks = createTypedHooks<StoreModel>()

const store = createStore(
  {
    user: persist(model.user, { storage: 'localStorage' }),
    tabs: persist(model.tabs, { storage: 'sessionStorage' }),
    deskDocuments: model.deskDocuments,
    loans: persist(model.loans, { storage: 'sessionStorage' }),
  },
  { name: 'Cockpit' },
)

export const useStoreActions = typedHooks.useStoreActions
export const useStoreDispatch = typedHooks.useStoreDispatch
export const useStoreState = typedHooks.useStoreState
export { useStoreRehydrated }

export default store
