import { Action, action } from 'easy-peasy'
import { MaritalStatus } from '~types'
import { Address } from './interfaces'

export interface IUser {
  id: string
  name?: string
  gender?: string
  isPublicPerson?: boolean
  birthday?: string
  cpf?: string
  rg?: string
  rgState?: string
  rgEmitter?: string
  email?: string
  phoneNumber?: string
  maritalStatus?: MaritalStatus
  status?: string | number
  native?: string
  nationality?: string
  birthdayDate?: Date | string
  address?: Address
  tenant?: string
  createdAt?: Date | string
}

export interface UserModel {
  user: IUser
  adminsList: IUser[]
  setUser: Action<UserModel, IUser>
  setAdminsList: Action<UserModel, IUser[]>
}

const user: UserModel = {
  user: null,
  adminsList: [],
  setAdminsList: action((state: UserModel, payload: IUser[]) => {
    state.adminsList = payload
  }),
  setUser: action((state: UserModel, payload: IUser) => {
    state.user = payload
  }),
}

export default user
