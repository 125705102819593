import { useQuery } from 'react-query'
import { LoanApi } from '~services'
import { formatListLoans } from '~helpers/formatData'
import { GetLoansParams } from '~services/loans'

const getLoans = (params: GetLoansParams) =>
  LoanApi.getLoans(params).then(formatListLoans)

export const useGetLoans = (params: GetLoansParams) => {
  return useQuery(['loan', params], () => getLoans(params), {
    enabled: !!params,
  })
}
