import React from 'react'
import i18n from '~commons/i18n'
import { DialogHeader, DialogWrapper, DialogActions } from '..'
import Button from '../../Button'
import { DialogConfirmationProps } from './interfaces'
import { ButtonStyled, DialogContentStyled, ParagraphStyled } from './styles'

const DialogConfirmation = ({
  open,
  onClose,
  onConfirm,
  title,
  loading = false,
  description,
  confirmText = i18n.t('general.confirm'),
  maxWidth = 'xs',
  type = 'primary',
}: DialogConfirmationProps) => {
  return (
    <DialogWrapper open={open} maxWidth={maxWidth}>
      <DialogHeader onClose={onClose}>{title}</DialogHeader>
      <DialogContentStyled>
        <ParagraphStyled>{description}</ParagraphStyled>
      </DialogContentStyled>
      <DialogActions $justifyContent="space-around">
        <ButtonStyled color="default" onClick={onClose} variant="text" disabled={loading}>
          {i18n.t('general.cancel')}
        </ButtonStyled>
        <Button
          variant={type === 'error' ? 'outlined' : 'contained'}
          onClick={onConfirm}
          color={type}
          isLoading={loading}
          disabled={loading}
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </DialogWrapper>
  )
}

export default DialogConfirmation
