import { String as StringUtils } from '@base39/ui-utils'
import { CreateMenuItemsProps } from './interfaces'

export const createMenuItems = ({ loan, company }: CreateMenuItemsProps) => [
  {
    href: `/empresas/${company?.id}`,
    label: company?.tradeName,
    tooltip: `${company?.tradeName} - ${StringUtils.formatCNPJ(company?.cnpj)}`,
  },
  {
    href: `/clientes/${loan?.user}`,
    label: loan?.personal?.name,
  },
  {
    href: `/propostas/${loan?.id}`,
    label: loan?.id ? `#${loan?.id}` : '',
  },
]
