import { Date as DateUtils, String as StringUtil } from '@base39/ui-utils'
import { Company } from '~types'
import { CompanyFormatted } from './interfaces'

export const formatCompany = (company: Company): CompanyFormatted => {
  return {
    ...company,
    formatted: {
      cnpj: StringUtil.formatCNPJ(company.cnpj),
      phone: StringUtil.formatPhone(company.hrContact?.phone),
      address: `${company.address?.address}, ${company.address?.number} ${company.address?.neighborhood} - ${company.address?.city} - ${company.address?.state}`,
      hiredAt: DateUtils.formatDateUTC(company.hiredAt),
    },
  }
}
