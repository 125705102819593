import React, { FC } from 'react'
import { ToastContainerProps } from 'react-toastify'
import { ReactComponent as CloseIcon } from '~assets/images/svg/close.svg'
import { ToastContainerStyled, TransparentButtonStyled } from './styles'

const CloseButton = ({ props }) => (
  <TransparentButtonStyled {...props}>
    <CloseIcon />
  </TransparentButtonStyled>
)

const ToastContainer: FC<ToastContainerProps> = (props) => {
  return <ToastContainerStyled closeButton={CloseButton} {...props} />
}

export default ToastContainer
