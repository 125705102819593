import styled from 'styled-components'

export const HeaderStyled = styled.div`
  padding: ${({ theme }) => theme.spacing(3)}px;
`

export const DashStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[400]};
  width: 100%;
`
