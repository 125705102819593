import styled from 'styled-components'
import MailIcon from '@material-ui/icons/Mail'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import { ReactComponent as Archive } from '~assets/images/svg/archive.svg'
import IconButton from '~components/dumb/IconButton'

export const TransparentButtonStyled = styled.button`
  font-family: 'Montserrat SemiBold';
  white-space: nowrap;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-style: none;
  display: flex;
  background-color: transparent;
  align-items: center;
  align-self: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  cursor: pointer;

  svg {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 0.25rem;
  }
`

export const RelativeWrapperStyled = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`

export const ListItemTextStyled = styled(ListItemText)`
  white-space: nowrap;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.palette.white[0]};

  .MuiTypography-root {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  :hover {
    background: ${({ theme }) => theme.palette.gray[400]};
  }
`

export const ListStyled = styled(List)`
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.white[0]};
  top: 2.5rem;
`

export const TabContainerStyled = styled.div`
  display: flex;
  flex: 1 0 auto;
`

export const FormStyled = styled.form`
  white-space: nowrap;
  background-color: ${({ theme }) => theme.palette.white[400]};
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 0.25rem;

  .filter-input {
    padding: 0px;
  }
`

export const IconButtonStyled = styled(IconButton)`
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const RightSectionStyled = styled.div`
  display: flex;
`

export const ToolbarContentStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const TopBarNavigationStyled = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const IconArchive = styled(Archive)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[0]};
  height: 24px;
  width: 24px;
`

export const IconMail = styled(MailIcon)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[0]};
  height: 24px;
  width: 24px;
`
