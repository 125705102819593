import { MaxCredit } from '~types/simulations'
import { ApiSls } from './api'

const path = '/v1/customers'

const Customers = {
  getMaxCredit(userId: string) {
    return ApiSls.customRequest<MaxCredit>(`${path}/${userId}/max-credit`, {
      method: 'GET',
    })
  },
}

export default Customers
