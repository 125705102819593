import React, { FC, LazyExoticComponent, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { UsersApi } from '~services'
import { Loading, PrivateRoute } from '~components'
import { useStoreActions } from '~hooks/useStore'
import { parseJwt } from '~helpers/user'
import Auth from '../Auth'
import DefaultLayout from './Layout'

interface IProps {
  component: LazyExoticComponent<any> | FC<any>
  layout?: LazyExoticComponent<any> | FC<any>
  condition?: string
  backTo?: string
  title?: string
  path?: string[] | string
  open?: boolean
  showSidebar?: boolean
  showHeader?: boolean
  menu?: boolean
  featureFlag?: string
  backgroundLayout?: string
}

const AppRoute: FC<IProps> = ({
  component: Component,
  layout: Layout,
  backTo,
  title,
  backgroundLayout,
  showSidebar,
  showHeader,
  menu,
  condition: conditionName,
  ...otherProps
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const setUser = useStoreActions(({ user }) => user.setUser)
  const [token, setToken] = useState<string>(null)

  const ComponentWithLayout = (props) => (
    <Layout
      {...props}
      showSidebar={showSidebar}
      showHeader={showHeader}
      title={title}
      backTo={backTo}
      menu={menu}
      backgroundColor={backgroundLayout}
    >
      <Component {...props} title={title} />
    </Layout>
  )

  const userIsAuthenticated = isAuthenticated && token

  const component = isAuthenticated ? ComponentWithLayout : Loading

  const conditionsOptions = {
    IS_NOT_AUTHENTICATED: { condition: !userIsAuthenticated, redirectPath: '/propostas' },
    DEFAULT: { condition: userIsAuthenticated, redirectPath: '/' },
    OPEN: { condition: true },
  }
  const {
    condition = conditionsOptions.DEFAULT.condition,
    redirectPath = conditionsOptions.DEFAULT.redirectPath,
  } = conditionsOptions[conditionName] ?? {}

  const storeToken = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently()
      sessionStorage.setItem('token', token)
      setToken(token)
      const id = parseJwt(token)['https://api.paketa.io/claims/id']
      const user = await UsersApi.get(id)

      setUser({
        id,
        ...user,
      })
    }
  }

  storeToken()

  if (!userIsAuthenticated) {
    return <Auth />
  }

  return (
    <PrivateRoute
      component={component}
      condition={condition}
      redirectPath={redirectPath}
      {...otherProps}
    />
  )
}

AppRoute.defaultProps = {
  layout: DefaultLayout,
  backTo: null,
  title: null,
}

export default AppRoute
