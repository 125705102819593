import { useQuery } from 'react-query'
import { LoanApi } from '~services'
import { formatLoan } from '~helpers/formatData'

const getLoanById = (id: string) => LoanApi.getLoanById(id).then(formatLoan)

export const useGetLoanById = (id: string) => {
  return useQuery(['loan', id], () => getLoanById(id), {
    enabled: !!id,
  })
}
