import React, { FC } from 'react'
import i18n from '~i18n'
import { AgeChip, Grid, DisplayData } from '~components'
import DisplayField from '../DisplayField'
import { HeaderStyled, DashStyled } from './styles'
import { HeaderProps } from './interfaces'

const Header: FC<HeaderProps> = ({ company, loan, loading }) => {
  return (
    <>
      <HeaderStyled data-testid="container-header">
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={3}>
            <DisplayField loading={loading} value={loan?.personal?.name} type="title" />
            <DisplayField loading={loading} value={company?.tradeName} type="subtitle" />
            <DisplayField
              loading={loading}
              value={company?.formatted.cnpj}
              type="smallTitle"
            />
          </Grid>
          <DisplayData
            title={i18n.t('user.cpf')}
            value={loan?.formatted?.personal?.cpf}
            xs={2}
            loading={loading}
          />

          <DisplayData
            title={i18n.t('user.birthday')}
            value={loan?.formatted?.personal?.birthday}
            tag={<AgeChip age={loan?.formatted?.personal?.age} />}
            xs={4}
            loading={loading}
          />
        </Grid>
      </HeaderStyled>
      <DashStyled />
    </>
  )
}

Header.defaultProps = {
  loading: false,
}

export default Header
