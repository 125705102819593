import { lazy } from 'react'

const PrivacyPolicy = lazy(() => import('~containers/PrivacyPolicy'))
const Layout = lazy(() => import('~containers/PrivacyPolicy/Layout'))

const path = '/privacidade'

const privacyPolicy = [
  {
    path,
    component: PrivacyPolicy,
    layout: Layout,
    condition: 'OPEN',
    exact: true,
  },
]

export default privacyPolicy
