import React, { FC } from 'react'
import { CircularProgress as CircularProgressUI } from '@material-ui/core'
import { ProgressWrapperStyled } from './styles'

interface IProps {
  size: number
  className?: string
}

const CircularProgress: FC<IProps> = ({ size, className }) => {
  return (
    <ProgressWrapperStyled role="status">
      <CircularProgressUI size={size} className={className} />
    </ProgressWrapperStyled>
  )
}

export default CircularProgress
