import { useEffect } from 'react'
import { useStoreActions } from '~hooks'
import { UseAddTabsProps } from './interfaces'

export const useAddTabs = ({ loan, company }: UseAddTabsProps) => {
  const { addTab } = useStoreActions((action) => action.tabs)

  useEffect(() => {
    if (loan && company) {
      addTab({
        id: loan.id,
        status: loan.status,
        user: {
          name: loan.personal.name,
          cpf: loan?.formatted?.personal.cpf,
        },
        company: {
          cnpj: company?.cnpj,
          tradeName: company?.tradeName,
        },
      })
    }
  }, [company, loan, addTab])
}
