import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { ReactComponent as SuccessIcon } from '~assets/images/svg/success.svg'
import { ReactComponent as WarnIcon } from '~assets/images/svg/status-warning.svg'
import { ReactComponent as ErrorIcon } from '~assets/images/svg/status-error.svg'
import ToastLayout from '~components/dumb/ToastLayout'

const defaultOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 8000,
}

const getOptions = (options: ToastOptions) => ({ ...defaultOptions, ...options })

const success = (title: string, description?: string, options?: ToastOptions) =>
  toast.success(
    <ToastLayout title={title} description={description} icon={<SuccessIcon />} />,
    getOptions(options),
  )

const error = (title: string, description?: string, opts?: ToastOptions) => {
  const options = getOptions(opts)
  const { toastId } = options

  if (toastId === undefined || !toast.isActive(toastId)) {
    toast.error(
      <ToastLayout title={title} description={description} icon={<ErrorIcon />} />,
      options,
    )
  }
}

const warn = (title: string, description?: string, options?: ToastOptions) =>
  toast.warn(
    <ToastLayout title={title} description={description} icon={<WarnIcon />} />,
    getOptions(options),
  )

const info = (message: string, options: ToastOptions) =>
  toast.info(message, getOptions(options))

const defaultToast = (message: string, options: ToastOptions) =>
  toast(message, getOptions(options))

export default {
  defaultOptions,
  getOptions,
  success,
  error,
  warn,
  info,
  default: defaultToast,
}
