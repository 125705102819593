import React from 'react'
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom'
import Feature from '../Feature'

interface IProps extends RouteProps {
  component: any
  condition: boolean
  redirectPath?: string
  featureFlag?: string
}

const PrivateRoute = ({
  component: Component,
  condition,
  redirectPath,
  featureFlag,
  ...rest
}: IProps) => {
  const history = useHistory()
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        condition ? (
          <Feature feature={featureFlag} onDeactivated={() => history.replace('/404')}>
            <Component {...routeProps} />
          </Feature>
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
