import { CompaniesList } from '~models/company'
import { RestApi, ApiSls } from './api'

const path = '/companies'

export interface ListCompaniesParams {
  page?: number
  pageSize?: number
  q?: string
  sort?: string
}

const Companies = {
  getAll(params) {
    return RestApi.customRequest<CompaniesList>(path, { method: 'GET', params })
  },
  getRhUsers(companyId: string) {
    return RestApi.customRequest(`${path}/${companyId}/rh`, { method: 'GET' })
  },
  getById(companyId: string) {
    return ApiSls.customRequest(`v1/customers${path}/${companyId}`, {
      method: 'GET',
    })
  },
  list(params: ListCompaniesParams) {
    return ApiSls.customRequest(`v1${path}/`, {
      method: 'GET',
      params,
    })
  },
}

export default Companies
