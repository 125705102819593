import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import RemoveIcon from '@material-ui/icons/Remove'
import { WrapZoomControls, WrapZoomOutMapStyled, FabStyled } from './styles'
import { ZoomControlProps } from './interfaces'

const ZoomControls: React.FC<ZoomControlProps> = ({
  path,
  displayEditor,
  handleZoomOutMap,
  handleZoom,
  imageSettings,
}) => {
  return (
    <WrapZoomControls visibility={path} show={displayEditor}>
      <WrapZoomOutMapStyled size="small" aria-label="out-map" onClick={handleZoomOutMap}>
        <ZoomOutMapIcon />
      </WrapZoomOutMapStyled>
      <FabStyled size="small" aria-label="zoom-in" onClick={() => handleZoom(false)}>
        <AddIcon />
      </FabStyled>
      <FabStyled
        size="small"
        aria-label="zoom-out"
        onClick={() => handleZoom(true)}
        disabled={imageSettings.zoomOutMap}
      >
        <RemoveIcon />
      </FabStyled>
    </WrapZoomControls>
  )
}

export default ZoomControls
