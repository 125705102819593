import React from 'react'
import { withField } from '../Field'
import { InputNumber } from '../InputNumber'
import { InputNumberProps } from '../InputNumber/interfaces'

const InputPrice = (props: InputNumberProps) => (
  <InputNumber
    {...props}
    prefix="R$ "
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
  />
)

export { InputPrice }
export default withField(InputPrice)
