import qs from 'qs'
import { Document } from '~types'
import { RestApi, ApiSls } from '../api'
import {
  ListDocumentsToAnalyseProps,
  UpdateStatusProps,
  UploadDocumentProps,
} from './interfaces'

const path = '/documents'

const Documents = {
  updateStatus({ documentId, status }: UpdateStatusProps) {
    return ApiSls.customRequest(`v1${path}/${documentId}/status`, {
      method: 'PATCH',
      data: { status },
    })
  },
  getListDocumentsToAnalyse(params: ListDocumentsToAnalyseProps) {
    return ApiSls.customRequest('/v1/desk-analysis/', { method: 'GET', params })
  },

  getRandomDocumentsToAnalyse() {
    return ApiSls.customRequest(`v1/desk-analysis/random`, { method: 'GET' })
  },
  getDocumentsToAnalyseByLoanId(id: string) {
    return ApiSls.customRequest(`v1/desk-analysis/${id}`, { method: 'GET' })
  },
  getUrl(id: string, type: string) {
    return RestApi.customRequest(`${path}/${id}/url`, { method: 'GET', params: { type } })
  },
  uploadDocument(document: UploadDocumentProps) {
    return ApiSls.customRequest<UploadDocumentProps, Document>(`v1${path}/`, {
      method: 'POST',
      data: document,
    })
  },
  getDocumentsByIds(id: string[]) {
    return ApiSls.customRequest<Document[]>(`v1${path}/`, {
      method: 'GET',
      params: { id },
      paramsSerializer(params) {
        return qs.stringify(params, { indices: false })
      },
    })
  },
}

export default Documents
