import { CreateMenuItemsProps, Menu } from './interfaces'

const filterMenuEnabled = (menu: Menu[]) => menu.filter((item) => item.isActive)

export const createMenuItems = ({ loan }: CreateMenuItemsProps): Menu[] => {
  const menu = [
    {
      href: `/propostas/${loan?.id}`,
      label: 'Resumo',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/dados-pessoais`,
      label: 'Dados Pessoais',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/credito`,
      label: 'Crédito',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/consultas`,
      label: 'Consultas',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/parcelas`,
      label: 'Parcelas',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/pagamentos`,
      label: 'Pagamento',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/anexos`,
      label: 'Anexos',
      isActive: true,
    },
    {
      href: `/propostas/${loan?.id}/historico`,
      label: 'Histórico',
      isActive: true,
    },
  ]

  return filterMenuEnabled(menu)
}
