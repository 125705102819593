import React, { FC } from 'react'
import { LoadingStyled, ContainerStyled, CircularIconStyled, LogoStyled } from './styles'
import { LoadingProps } from './interfaces'

const Loading: FC<LoadingProps> = ({
  full,
  className,
  width = '100%',
  height = '100%',
  children,
}) => {
  return (
    <LoadingStyled
      $full={full}
      className={className}
      $width={width}
      $height={height}
      data-testid="loading"
    >
      <ContainerStyled>
        <CircularIconStyled />
        <LogoStyled />
      </ContainerStyled>
      {children}
    </LoadingStyled>
  )
}

export default Loading
