import React, { memo, useMemo } from 'react'
import { ColoredSquare } from '~components'
import { MenuItem } from '~components/dumb/Menu'
import { getStatusLabel } from '~helpers/loan'
import { Types } from '~components/dumb/ColoredSquare'
import { WrapperSquareStyled } from './styles'
import { MenuStatusOptionsProps } from './interfaces'

const MenuStatusOptions: React.FC<MenuStatusOptionsProps> = ({
  onClick,
  square,
  statusOption,
  tooltip = '',
}) => {
  const label = useMemo(() => getStatusLabel(statusOption), [statusOption])
  return (
    <MenuItem data-testid={`STATUS_${statusOption}`} onClick={onClick}>
      <WrapperSquareStyled>
        <ColoredSquare color={square.color} tooltip={tooltip} type={Types.tiny} />
      </WrapperSquareStyled>
      {label}
    </MenuItem>
  )
}

export default memo(MenuStatusOptions)
