import i18n from '~i18n'
import { MaritalStatus } from '~types'
import MARITAL_STATUS from '~constants/maritalStatus'

export const convertGender = (gender: string): string =>
  gender === 'M' ? i18n.t('general.male') : i18n.t('general.female')

export const getMaritalStatus = (status: MaritalStatus): string => MARITAL_STATUS[status]

export const getPublicPersonLabel = (isPublicPerson: boolean): string =>
  isPublicPerson ? i18n.t('general.yes') : i18n.t('general.no')

export const formatRgEmitter = (rgEmitter: string, rgState: string): string =>
  `${rgEmitter}/${rgState}`

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}
