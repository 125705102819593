import React, { FC } from 'react'
import i18n from '~i18n'
import { installmentsStatus } from '~constants/installment'
import ColoredSquare, { Types } from '../ColoredSquare'
import { ChildrenStyled, InstallmentContainerStyled } from './styles'
import { InstallmentStatusProps } from './interfaces'

const InstallmentStatus: FC<InstallmentStatusProps> = ({ children, status }) => {
  const { color, label, initial } = installmentsStatus[status]
  return (
    <InstallmentContainerStyled>
      {children && <ChildrenStyled>{children}</ChildrenStyled>}
      <ColoredSquare
        data-testid="status"
        tooltip={i18n.t(label)}
        initial={i18n.t(initial)}
        color={color}
        type={Types.square}
      />
    </InstallmentContainerStyled>
  )
}

export default InstallmentStatus
