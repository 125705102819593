import { AcquittanceBillet } from '~types/acquittanceBillet'
import { AcquittanceBilletFormatted } from './interfaces'

export const formatAcquittance = (
  acquittances: AcquittanceBillet[],
): AcquittanceBilletFormatted[] => {
  return acquittances.map((acquittance) => {
    const formattedAcquittances: AcquittanceBilletFormatted = acquittance

    return formattedAcquittances
  })
}
