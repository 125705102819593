import React, { FC, Fragment, memo } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import get from 'lodash.get'
import TableHead from '@material-ui/core/TableHead'
import Skeleton from '../Skeleton'
import Pagination from './Pagination'
import { TableCellStyled, TableContainerStyled, TableRowStyled } from './styles'
import { TableProps } from './interfaces'

const TableUI: FC<TableProps> = ({
  onClickCell,
  rows,
  headers,
  className,
  hasPagination = false,
  pagination,
  loading,
}) => {
  const { count, page, onChange } = pagination || {}

  const handleClickCell = (cell: object) => {
    if (onClickCell) {
      onClickCell(cell)
    }
  }

  const renderHeadRow = (row, index) => (
    <Fragment key={`head-row-${index}`}>
      <TableCellStyled>{row.label}</TableCellStyled>
    </Fragment>
  )

  const renderCell = ({ cell, header, key }) => (
    <TableCellStyled
      onClick={() => handleClickCell(cell)}
      $clickable={!!onClickCell}
      component="th"
      scope="row"
      key={`cell-${key}`}
    >
      {get(cell, header?.value)}
    </TableCellStyled>
  )

  const renderBodyRow = (cell, index) => (
    <TableRowStyled data-testid={`body-cell-${index}`} key={`body-cell-${index}`}>
      {headers.map((header, key) => renderCell({ cell, header, key }))}
    </TableRowStyled>
  )

  const renderSkeleton = () => {
    const amountRows = 10
    return [...new Array(amountRows)].map((_, key) => {
      return (
        <tr key={key}>
          {headers?.map((_, index) => (
            <th key={`skeleton-${key}-${index}`}>
              <Skeleton height={48} />
            </th>
          ))}
        </tr>
      )
    })
  }

  return (
    <TableContainerStyled data-testid="table" $loading={loading}>
      <Table className={className} aria-label="table">
        <TableHead data-testid="table-head">
          <TableRowStyled>{headers?.map(renderHeadRow)}</TableRowStyled>
        </TableHead>
        <TableBody data-testid="table-body">
          {loading && renderSkeleton()}
          {!loading && rows?.map(renderBodyRow)}
        </TableBody>
      </Table>
      {hasPagination && <Pagination count={count} page={page} onChange={onChange} />}
    </TableContainerStyled>
  )
}

export default memo(TableUI)
