import styled, { css } from 'styled-components'
import { DialogWrapper } from '~components/dumb/Dialog'

export const ContentWrapperStyled = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

export const DialogWrapperStyled = styled(DialogWrapper)`
  .paper {
    height: 100%;
  }
`
export const AgeContentStyled = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`
export const AgeChipContentStyled = styled.div<{ $visible: boolean }>`
  ${({ $visible }) =>
    !$visible &&
    css`
      display: none;
    `}
  margin-right: 8px;
`
