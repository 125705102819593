import { Money as MoneyUtils } from '@base39/ui-utils'
import { MaxInstallmentValueFormatted } from './interfaces'

export const formatMaxInstallmentValue = (data: {
  value: number
}): MaxInstallmentValueFormatted => ({
  ...data,
  formatted: {
    value: MoneyUtils.formatCurrency(data.value),
  },
})
