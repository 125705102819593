import { useQuery } from 'react-query'
import { CreditLinesApi, GetSimulationByInstallmentIdParams } from '~services'
import { formatSimulation } from '~helpers/formatData'

export const getSimulationByInstallmentId = ({
  selectedInstallment,
  simulateId,
}: GetSimulationByInstallmentIdParams) =>
  CreditLinesApi.getSimulationByInstallmentId({ selectedInstallment, simulateId }).then(
    formatSimulation,
  )

export const useGetSimulationById = (
  data: GetSimulationByInstallmentIdParams,
  { enabled = true } = {},
) => {
  return useQuery(
    ['simulation', data?.selectedInstallment, data?.simulateId],
    () =>
      getSimulationByInstallmentId({
        selectedInstallment: data?.selectedInstallment,
        simulateId: data?.simulateId,
      }),
    {
      enabled: !!data?.selectedInstallment && !!data?.simulateId && enabled,
    },
  )
}
