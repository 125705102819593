import styled from 'styled-components'
import Typography from '~components/dumb/Typography'

export const TitleStyled = styled(Typography)`
  margin-top: 5px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.palette.success.contrastText};
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.success.contrastText};
  font-family: 'Montserrat Regular';
  font-size: 0.875rem;
`

export const RowStyled = styled.div`
  display: flex;
`
