import React, { memo } from 'react'
import { EmptyStateProps } from './interfaces'
import { ContainerStyled, TitleStyled } from './styles'

const EmptyState: React.FC<EmptyStateProps> = ({ icon: Icon, label }) => (
  <ContainerStyled data-testid="empty-state">
    <div>
      {Icon && <Icon role="graphics-document" />}
      <TitleStyled role="heading">{label}</TitleStyled>
    </div>
  </ContainerStyled>
)

export default memo(EmptyState)
