import React, { FC, useCallback, memo } from 'react'
import { PdfViewer } from '~components'
import i18n from '~i18n'
import { ViewProps } from './interfaces'
import { DocumentImageStyled, NotFoundImgStyled } from './styles'

const Viewer: FC<ViewProps> = ({
  path,
  alt,
  imgReference,
  isPdf,
  imageSettings,
  wrapHeight,
  imgHeight,
}) => {
  const { rotate, horizontal, zoomOutMap, zoom } = imageSettings

  const updateSliderFilter = useCallback((filter: number) => {
    const negativeValue =
      filter === -100 ? 0 : `0.${100 - Number(filter.toString()?.replace('-', ''))}`
    const positiveValue = filter === 100 ? 2 : `1.${filter}`
    return filter < 0 ? negativeValue : positiveValue
  }, [])

  if (!path) {
    return (
      <NotFoundImgStyled>
        <p>{i18n.t('documents.notFound')}</p>
      </NotFoundImgStyled>
    )
  }

  return isPdf ? (
    <PdfViewer url={path} />
  ) : (
    <DocumentImageStyled
      ref={imgReference}
      src={path}
      alt={alt}
      contrast={updateSliderFilter(Number(imageSettings?.contrast))}
      brightness={updateSliderFilter(Number(imageSettings?.brightness))}
      rotate={rotate}
      horizontal={horizontal}
      zoomOutMap={zoomOutMap}
      zoom={zoom}
      wrapHeight={wrapHeight}
      imgHeight={imgHeight}
    />
  )
}

export default memo(Viewer)
