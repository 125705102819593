import React from 'react'
import { Formik, Form } from 'formik'
import { DialogActions, DialogContent } from '@material-ui/core'
import { DialogWrapper, DialogHeader, Button } from '~components'
import i18n from '~i18n'
import { DialogConfirmationFormProps } from './interfaces'

function DialogConfirmationForm<T>({
  title,
  loading,
  open,
  initialValues,
  validationSchema,
  onClose,
  onConfirm,
  hasValidation = true,
  render,
}: DialogConfirmationFormProps<T>) {
  const handleClose = () => {
    !loading && onClose()
  }

  const handleConfirm = (values, formikEvents) => {
    onConfirm({ values, formikEvents })
  }

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      data-testid="alert-dialog"
    >
      <DialogHeader onClose={onClose}>{title}</DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
        validateOnMount={hasValidation && validationSchema?.isValidSync(initialValues)}
      >
        {({ handleSubmit, ...otherProps }) => {
          return (
            <Form onSubmit={handleSubmit} data-testid="alert-dialog-form">
              <DialogContent
                id="alert-dialog-description"
                data-testid="alert-dialog-description"
              >
                {render && render({ handleSubmit, ...otherProps })}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  disabled={loading}
                  variant="outlined"
                  size="medium"
                  data-testid="alert-dialog-button-cancel"
                >
                  {i18n.t('general.close')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  disabled={loading}
                  data-testid="alert-dialog-button-confirmation"
                >
                  {i18n.t(loading ? 'general.loading' : 'general.confirm')}
                </Button>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </DialogWrapper>
  )
}

export default DialogConfirmationForm
