import styled from 'styled-components'
import { Title, Paragraph } from '../Text'
import Chip from '../Chip'

export const TitleStyled = styled(Title)`
  color: ${({ theme }) => theme.palette.gray[550]};
  font-size: 1.5rem;
  line-height: 29px;
  margin: ${({ theme }) => theme.spacing(2, 0, 0.5, 0)};
  text-transform: none;
`
export const SubtitleStyled = styled(Title)`
  color: ${({ theme }) => theme.palette.gray[550]};
  font-size: 1rem;
  line-height: 19.5px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  text-transform: none;
`

export const ChipStyled = styled(Chip)`
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`
export const ParagraphStyled = styled(Paragraph)`
  font-size: 0.875rem;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`
