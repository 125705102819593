import styled, { css } from 'styled-components'
import Typography from '../Typography'
import { ValueStyledProps, FieldStyledProps } from './interfaces'

export const FieldStyled = styled(Typography)<FieldStyledProps>`
  color: ${({ theme }) => theme.palette.gray[700]};
  font-family: 'Montserrat Regular';
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 22px;
  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${$fontWeight};
    `}

  ${({ $opacity }) =>
    $opacity &&
    css`
      opacity: ${$opacity};
    `}
`

export const ValueStyled = styled(Typography)<ValueStyledProps>`
  font-family: 'Montserrat Regular';
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.gray[700]};
  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${$fontWeight};
    `}
`

export const TitleStyled = styled(FieldStyled)`
  font-family: 'Montserrat SemiBold';
  color: ${({ theme }) => theme.palette.gray[700]};
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 0.5rem;
`

export const HighlightStyled = styled(ValueStyled)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: 'Montserrat Bold';
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
`

export const ValueBiggestStyled = styled(Typography)`
  font-family: 'Montserrat Medium';
  color: ${({ theme }) => theme.palette.gray[700]};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 6px;
`

export const ParagraphStyled = styled(Typography)`
  font-family: 'Montserrat Regular';
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.gray[600]};
`
