import yup from '~helpers/yup'

export const initialValues = {
  causeRejection: '',
}

export const validationSchema = yup.object().shape({
  causeRejection: yup.string().required(),
})

export const rejectedStatus = {
  IN_ANALYSIS_PAKETA: 'REJECTED_PAKETA',
  IN_ANALYSIS_RH: 'REJECTED_RH',
}
