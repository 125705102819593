import { lazy } from 'react'
import theme from '~commons/constants/theme'

const NotFound = lazy(() => import('~containers/NotFound'))
const Layout = lazy(() => import('~layouts/default'))

const notFound = [
  {
    path: '/404',
    component: NotFound,
    layout: Layout,
    backgroundLayout: theme.palette.white[0],
    exact: true,
  },
]

export default notFound
