import React from 'react'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import i18n from '~commons/i18n'
import Tooltip from '../../Tooltip'
import { LabelInfoProps } from './interfaces'
import { ContentStyled } from './styles'

const LabelInfo = ({ label, tooltip }: LabelInfoProps) => {
  return (
    <ContentStyled>
      {label}
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoIcon
            className="info"
            fontSize="small"
            aria-label={i18n.t('general.info')}
          />
        </Tooltip>
      )}
    </ContentStyled>
  )
}

export default LabelInfo
