import { ApiSls } from './api'

const path = '/credit-lines'

const Payments = {
  getByLoanId(loanId: string) {
    return ApiSls.customRequest(`/v1${path}/payments/${loanId}`, {
      method: 'GET',
    })
  },
}

export default Payments
