import React, { FC } from 'react'
import { ChipOption } from '~components'
import { withField } from '~components/dumb/Form/Field'
import { TaxTypeProps } from './interfaces'
import { ChipsContent, TaxTypeContainerStyled, TaxTypeLabelStyled } from './styles'

const TaxType: FC<TaxTypeProps> = ({ title, value, policyType, onChange }) => (
  <TaxTypeContainerStyled>
    <TaxTypeLabelStyled>{title}</TaxTypeLabelStyled>
    <ChipsContent>
      <ChipOption
        checked={value === 'ABSOLUTE'}
        onClick={onChange}
        label="R$"
        disabled={policyType !== 'ADVANCE'}
      />
      <ChipOption
        checked={value === 'PERCENTAGE'}
        onClick={onChange}
        label="%"
        disabled={policyType !== 'PAYROLL'}
      />
    </ChipsContent>
  </TaxTypeContainerStyled>
)

const TaxTypeField = withField(TaxType)

export { TaxType }
export default TaxTypeField
