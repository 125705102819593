import React from 'react'
import i18n from 'i18next'
import withSkeleton from '~hocs/withSkeleton'
import { Field, Value } from '../Text'
import Grid from '../Grid'
import { DisplayDataProps } from './interfaces'
import { ContentStyled, TagContainerStyled } from './styles'

const DisplayData = ({
  title,
  value,
  loading,
  xs,
  fontWeight,
  tag,
  id,
}: DisplayDataProps) => {
  const ValueOrSkeleton = withSkeleton(Value, loading)
  const labelledby = id || title?.replace(/ /g, '')

  return (
    <Grid item xs={xs} role="region" aria-labelledby={labelledby}>
      <Field $fontWeight={fontWeight} $opacity="48%" id={labelledby}>
        {title}
      </Field>
      <ContentStyled>
        <ValueOrSkeleton
          $fontWeight={fontWeight}
          role="contentinfo"
          aria-labelledby={labelledby}
        >
          {value || i18n.t('general.notInformed')}
        </ValueOrSkeleton>
        <TagContainerStyled>{tag}</TagContainerStyled>
      </ContentStyled>
    </Grid>
  )
}

DisplayData.defaultProps = {
  xs: 6,
}

export default DisplayData
