import React, { useMemo } from 'react'
import { Links } from '..'
import { LeftMenuProps } from './interfaces'
import { createMenuItems } from './utils'

const LeftMenu = ({ loan, company, loading }: LeftMenuProps) => {
  const items = useMemo(() => createMenuItems({ loan, company }), [loan, company])

  return (
    <>
      <Links items={items} loading={loading} />
    </>
  )
}

export default LeftMenu
