import React from 'react'
import {
  String as StringUtils,
  Age as AgeUtils,
  Money as MoneyUtils,
  Date as DateUtils,
} from '@base39/ui-utils'
import { Loan, Personal, Address, UserCompany, ListLoans } from '~types'
import i18n from '~i18n'
import { ColoredSquare } from '~components'
import {
  convertGender,
  formatRgEmitter,
  getMaritalStatus,
  getPublicPersonLabel,
} from '~helpers/user'
import { getBirthdayDescription, getStatusLabel } from '~helpers/loan'
import { Types } from '~components/dumb/ColoredSquare'
import { SQUARE_STATUS } from '~commons/constants/squareStatus'
import { ListLoansFormatted, LoanFormatted } from './interfaces'
import { WrapperNameStyled, WrapperSquareStyled } from './styles'

const formatPersonal = (personal: Personal): LoanFormatted['formatted']['personal'] => ({
  phoneNumber: StringUtils.formatPhone(personal?.phoneNumber),
  cpf: StringUtils.formatCPF(personal?.cpf),
  rgIssueDate: DateUtils.formatDate(personal?.rgIssueDate, 'DD/MM/YYYY'),
  birthday: getBirthdayDescription(personal?.birthdayDate as Date),
  age: AgeUtils.getAge(personal?.birthdayDate as Date),
  gender: convertGender(personal?.gender),
  rgEmitter: formatRgEmitter(personal?.rgEmitter, personal?.rgState),
  isPublicPerson: getPublicPersonLabel(personal?.isPublicPerson),
  maritalStatus: getMaritalStatus(personal?.maritalStatus),
})

const formatAddress = (address: Address): LoanFormatted['formatted']['address'] => ({
  zipCode: StringUtils.formatZip(address?.zipCode),
  address: `${address?.address}, ${address?.number}`,
  city: `${address?.city}/${address?.state}`,
})

const formatUserCompany = (
  userCompany: UserCompany,
): LoanFormatted['formatted']['company'] => ({
  netSalary: MoneyUtils.formatCurrency(userCompany?.netSalary),
  salary: MoneyUtils.formatCurrency(userCompany?.salary),
  hiredAt: DateUtils.formatDateUTC(userCompany?.hiredAt),
  admissionDate: `${DateUtils.formatDateUTC(
    userCompany?.hiredAt,
  )} - ${DateUtils.getMonthFromDate(userCompany?.hiredAt)} ${i18n.t('general.months')}`,
})

export const formatLoan = (loan: Loan): LoanFormatted => {
  return {
    ...loan,
    formatted: {
      personal: formatPersonal(loan.personal),
      address: formatAddress(loan.address),
      company: formatUserCompany(loan.company),
      value: MoneyUtils.formatCurrency(loan.value),
      requestDate: DateUtils.formatDate(loan.requestDate, 'L HH:mm'),
      paymentDate: DateUtils.formatDate(loan?.paymentDate, 'L HH:mm'),
      updatedAt: DateUtils.formatDate(loan.updatedAt, 'L HH:mm'),
    },
  }
}

export const renderUserRow = (name: string, status: string) => {
  const square = SQUARE_STATUS[status]
  return (
    <WrapperNameStyled>
      <WrapperSquareStyled>
        <ColoredSquare
          color={square.color}
          initial={square.initial}
          tooltip={i18n.t(square.tooltip)}
          type={Types.square}
        />
      </WrapperSquareStyled>
      {name}
    </WrapperNameStyled>
  )
}

export const formatListLoans = (data: ListLoans): ListLoansFormatted => {
  return {
    ...data,
    rows: data?.rows.map((row) => ({
      ...row,
      formattedName: renderUserRow(row?.personal?.name, row.status),
      formattedCompany: `${row.company?.tradeName} - ${StringUtils.formatCNPJ(
        row.company?.cnpj,
      )}`,
      formattedRequested: String(DateUtils.formatDate(row.requestDate, 'DD/MM/YYYY')),
      formattedUpdatedAt: String(DateUtils.formatDate(row.updatedAt, 'DD/MM/YYYY')),
      formattedStatus: String(getStatusLabel(row.status)),
      formattedValue: MoneyUtils.formatCurrency(row.value),
      formattedAssign: row?.assign?.user?.name,
      formattedPersonalPhone: StringUtils.formatPhone(row?.personal?.phoneNumber),
      formattedPersonalAddress: `${row?.address?.address}, ${row?.address?.number} ${row?.address?.neighborhood} - ${row?.address?.city} - ${row?.address?.state}`,
    })),
  }
}
