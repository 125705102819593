import React, { memo } from 'react'
import { CircularProgress } from '@material-ui/core'
import { TextField } from '~components'
import { InputProps } from './interfaces'

const Input = (props: InputProps) => {
  const { params, isLoading, inputProps, ...rest } = props

  const endAdornment = (
    <>
      {isLoading && <CircularProgress size={24} />}
      {params.InputProps.endAdornment}
    </>
  )

  const InputProps = {
    ...params.InputProps,
    endAdornment,
  }

  return (
    <TextField
      {...params}
      inputProps={{ ...params.inputProps, ...inputProps }}
      InputProps={InputProps}
      {...rest}
    />
  )
}

Input.defaultProps = {
  isLoading: false,
}

export default memo(Input)
