import styled, { css } from 'styled-components'
import Fab from '@material-ui/core/Fab'
import { WrapZoomControlsProps } from './interfaces'

export const WrapZoomControls = styled.div<WrapZoomControlsProps>`
  width: 40px;
  visibility: ${({ visibility }) => (visibility ? 'visible' : 'hidden')};
  position: absolute;
  right: 24px;
  bottom: 50px;
  height: 152px;
  z-index: 1;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-around;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      right: 38px;
      bottom: 62px;
    }
  `}
`
export const WrapZoomOutMapStyled = styled(Fab)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

export const FabStyled = styled(Fab)`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`
