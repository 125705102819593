import { createInstance } from '@optimizely/react-sdk'

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY,
})
interface IProps {
  attributes: any
  feature: string
  id: string
}

function featureSdk({ feature, id, attributes }: IProps) {
  return optimizely.onReady().then(() => {
    return optimizely.client.isFeatureEnabled(feature, id, attributes)
  })
}

export default featureSdk
