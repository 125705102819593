import { lazy } from 'react'

const Loan = lazy(() => import('~containers/Loan'))
const Summary = lazy(() => import('~containers/Summary'))
const Inquiries = lazy(() => import('~containers/Inquiries'))
const Credit = lazy(() => import('~containers/Credit'))
const PersonalData = lazy(() => import('~containers/PersonalData'))
const Installments = lazy(() => import('~containers/Installments'))
const Attachments = lazy(() => import('~containers/Attachments'))
const History = lazy(() => import('~containers/History'))
const Payments = lazy(() => import('~containers/Payments'))

const path = '/propostas'

const loans = [
  {
    path: `${path}/:loanId`,
    component: Loan,
    exact: false,
  },
]

export const loanSubRoutes = [
  {
    path: `${path}/:loanId`,
    component: Summary,
    exact: true,
  },
  {
    path: `${path}/:loanId/credito`,
    component: Credit,
  },
  {
    path: `${path}/:loanId/consultas`,
    component: Inquiries,
  },
  {
    path: `${path}/:loanId/usuario`,
    component: PersonalData,
  },
  {
    path: `${path}/:loanId/dados-pessoais`,
    component: PersonalData,
  },
  {
    path: `${path}/:loanId/parcelas`,
    component: Installments,
  },
  {
    path: `${path}/:loanId/pagamentos`,
    component: Payments,
  },
  {
    path: `${path}/:loanId/anexos`,
    component: Attachments,
  },
  {
    path: `${path}/:loanId/historico`,
    component: History,
  },
]

export default loans
