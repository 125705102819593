import React, { FC, memo } from 'react'
import { Grid } from '~components'
import { Field, Value } from '~components/dumb/Text'
import { formatValue } from '~helpers/element'
import { AttachmentsFormProps } from './interfaces'

const AttachmentsForm: FC<AttachmentsFormProps> = ({ type, data }) => {
  if (!data[type]) {
    return null
  }

  const renderField = ({ length, label, value, type: fieldType }, index: number) => {
    return (
      <Grid item xs={length} key={`document-field-${fieldType}-${index}`}>
        <Field>{label}</Field>
        <Value>{formatValue(value)}</Value>
      </Grid>
    )
  }

  return data[type].fields?.map(renderField)
}

export default memo(AttachmentsForm)
