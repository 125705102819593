import { useQuery } from 'react-query'
import { CustomersApi } from '~services'
import { formatMaxCredit } from '~helpers/formatData'

const getMaxCreditByUserId = (userId: string) =>
  CustomersApi.getMaxCredit(userId).then(formatMaxCredit)

export const useGetMaxCredit = (userId: string) => {
  return useQuery(['maxCredit', userId], () => getMaxCreditByUserId(userId), {
    enabled: !!userId,
  })
}
