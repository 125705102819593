import styled from 'styled-components'
import { Paragraph } from '~components/dumb/Text'

export const TaxTypeContainerStyled = styled.div`
  width: 80px;
`

export const TaxTypeLabelStyled = styled(Paragraph)`
  font-family: 'Montserrat SemiBold';
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.gray[550]};
  margin: ${({ theme }) => theme.spacing(2, 0)};
`

export const ChipsContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 68px;
`
