import styled, { css } from 'styled-components'
import { TableCell, TableContainer, TableRow } from '@material-ui/core'
import { TableCellStyledProps } from './interfaces'

export const TableContainerStyled = styled(TableContainer)<{ $loading: boolean }>`
  tr:nth-child(even) {
    background: ${({ theme }) => theme.palette.white[300]};
  }

  ${({ $loading }) =>
    $loading &&
    css`
      tr:nth-child(even) {
        background: transparent;
      }
    `}
`

export const TableCellStyled = styled(TableCell)<TableCellStyledProps>`
  padding: 0.75rem;

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`

export const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.white[100]};
  }
`

export const PaginationContent = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
`
