import styled from 'styled-components'
import { DialogTitle } from '@material-ui/core'
import Button from '../../Button'

export const ButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.gray[100]};
  background-color: transparent;
  min-width: 0px;
  padding: 0px;
  margin: 1rem;
  position: absolute;
  top: 0px;
  right: 0px;

  :hover {
    background-color: transparent;
  }
`

export const DialogTitleStyled = styled(DialogTitle)`
  font-family: 'Montserrat SemiBold';
  width: 100%;
  padding: 0px;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.gray[550]};
`

export const HeaderWrapperStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[200]};
  padding: 1.75rem 2rem;
`
