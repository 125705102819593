import styled from 'styled-components'
import { Button } from '~components'
import Popover from '~components/dumb/Popover'

export const ActionsStyled = styled.div`
  height: 72.5px;
  position: sticky;
  width: 100%;
  z-index: 2;
  overflow: hidden;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[100]};
  margin-top: -4.594rem;
`

export const ButtonStatusStyled = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 1px;
  font-size: 0.875rem;
  min-width: auto;
  width: 1rem;
`

export const ButtonChoiceStyled = styled(Button)`
  && {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  min-width: 200px;
  font-size: 0.875rem;
`

export const GroupButtonStyled = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.palette.white[0]};
`

export const SendAsLabelStyled = styled.div`
  color: ${({ theme }) => theme.palette.white[0]};
  padding-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  opacity: 0.8;
`

export const PopoverStyled = styled(Popover)`
  .paperStyled {
    border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  }
`
