import { Money as MoneyUtils, Date as DateUtils, Tenant } from '@base39/ui-utils'
import { Logs, LogMessage } from '~types'
import i18n from '~i18n'
import { FormattedLogs, LogsData } from './interface'

const getMessageFinancial = (value: Logs['document']) => {
  const hasQuery = value?.doc && value?.doc?.query
  const doc = value?.doc
  const logsMessage = hasQuery
    ? doc?.query?.['third-party-status'] || doc?.query?.['thirdPartyStatus']
    : doc?.['third-party-status']

  return i18n.t(logsMessage, value)
}

const getNoteMessage = ({ note, causeRejection }) => {
  const noteMsg = note
    ? `- ${i18n.t('loan.logs.NOTE', {
        note,
      })}`
    : ''

  return `${i18n.t(`causesOfRejection.${causeRejection}`)} ${noteMsg}`
}

const getMessage = (message: LogMessage, value: Logs['document']) => {
  if (['MONEYPLUS_CALLBACK', 'ABC_CALLBACK'].includes(message)) {
    return getMessageFinancial(value)
  }

  if (message === 'NOTE') {
    const { note, causeRejection } = value.doc
    return getNoteMessage({ note, causeRejection })
  }

  const tenant = Tenant.getTenant()

  return i18n.t([`loan.logs.${tenant}.${message}`, `loan.logs.${message}`], value)
}

export const formatLogs = (logs: LogsData): FormattedLogs[] =>
  logs?.result.map((log) => ({
    id: log?.id,
    message: getMessage(log?.message, log?.document),
    name: log.user?.name || log?.document?.doc?.loan?.personal?.email,
    updatedAt: DateUtils.formatDate(log?.updatedAt, 'DD/MM/YYYY'),
    createdAt: DateUtils.formatDate(log?.createdAt, 'DD/MM/YYYY'),
    type: log?.type,
    status: log?.message,
    note: log?.document?.doc?.note,
    causeRejection: log?.document?.doc?.causeRejection,
    proposalValue: MoneyUtils.formatCurrency(log?.document?.doc?.loan?.value),
    simulationId: log?.document?.doc?.loan?.proposal?.simulateId,
    selectedInstallment: log?.document?.doc?.loan?.proposal?.selectedInstallment,
  }))
