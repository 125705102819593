import React from 'react'
import withSkeletonLoading from '~commons/hocs/withSkeleton'
import i18n from '~commons/i18n'
import ArrowBack from '../ArrowBack'
import { PolicyTitleProps } from './interface'
import { TitleStyled, ParagraphStyled, SubtitleStyled, ChipStyled } from './styles'

const PolicyTitle = ({
  title,
  subtitle,
  type,
  hasType = false,
  description,
  loading = false,
  onClick,
}: PolicyTitleProps) => {
  const heightSkeleton = 32
  const withSkeleton = 200

  const TitleSkeleton = withSkeletonLoading(
    TitleStyled,
    loading,
    heightSkeleton,
    withSkeleton,
  )
  const SubtitleSkeleton = withSkeletonLoading(
    SubtitleStyled,
    loading,
    heightSkeleton,
    withSkeleton / 2,
  )
  const ChipSkeleton = withSkeletonLoading(
    ChipStyled,
    loading,
    heightSkeleton,
    withSkeleton / 2,
  )
  const ParagraphSkeleton = withSkeletonLoading(ParagraphStyled, loading)

  return (
    <>
      <ArrowBack onClick={onClick} />
      <TitleSkeleton>{title}</TitleSkeleton>
      {subtitle && <SubtitleSkeleton>{subtitle}</SubtitleSkeleton>}
      {hasType && (
        <div>
          <ChipSkeleton
            label={i18n.t([`creditPolicy.policyTypes.${type}`, 'general.notInformed'])}
            color="primary"
            variant="outlined"
          />
        </div>
      )}
      {description && <ParagraphSkeleton>{description}</ParagraphSkeleton>}
    </>
  )
}

export default PolicyTitle
