import { RestApi, ApiSls } from '../api'
import { GetUserByCpf, ValidateBankProps } from './interfaces'

const path = '/users'

const Users = {
  get(id: string) {
    return RestApi.customRequest(`${path}/${id}`, { method: 'GET' })
  },

  getByCpf(cpf: string) {
    return RestApi.customRequest<GetUserByCpf>(`${path}/cpf/${cpf}`, { method: 'GET' })
  },

  getAdminUsers() {
    return RestApi.customRequest(`${path}/admins`, { method: 'GET' })
  },

  setValidateBank(data: ValidateBankProps) {
    return ApiSls.customRequest<ValidateBankProps, any>('/v1/customers/validate-bank', {
      method: 'POST',
      data,
    })
  },
}

export default Users
