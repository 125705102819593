import {
  AddSimulationParams,
  AvailableFirstDueDates,
  Simulation,
  SimulationId,
  MaxInstallmentValueParams,
  MaxInstallmentValue,
} from '~types/simulations'
import { RestApi } from './api'

const path = '/simulations'

const Simulations = {
  addSimulation(data: AddSimulationParams) {
    return RestApi.customRequest<Simulation>(path, { method: 'POST', data })
  },
  getAvailableFirstDueDates(companyId: string) {
    return RestApi.customRequest<AvailableFirstDueDates>(
      `${path}/${companyId}/available-first-due-dates`,
      {
        method: 'GET',
      },
    )
  },
  getMaxInstallmentValue(params: MaxInstallmentValueParams) {
    return RestApi.customRequest<MaxInstallmentValue>(`${path}/max-installment-value`, {
      method: 'GET',
      params,
    })
  },
  getSimulationById(id: string) {
    return RestApi.customRequest<SimulationId>(`${path}/${id}`, {
      method: 'GET',
    })
  },
}

export default Simulations
