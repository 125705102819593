import styled, { css } from 'styled-components'
import { ReactComponent as CircularIcon } from '~assets/images/svg/circular.svg'
import Theme from '~constants/theme'
import { LoadingStyledProps } from './interfaces'

export const LoadingStyled = styled.div<LoadingStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${({ $full }) =>
    $full &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 999;
    `};
`

export const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LogoIcon = Theme.logo.short

export const LogoStyled = styled(LogoIcon)`
  width: 50px;
  height: 50px;
`
export const CircularIconStyled = styled(CircularIcon)`
  animation: spin 0.7s linear infinite;
  width: 90px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  height: 90px;
`
