import { lazy } from 'react'

const CreditPolicies = lazy(() => import('~containers/CreditPolicies'))
const CreditPolicy = lazy(() => import('~containers/CreditPolicy'))
const CreateCreditPolicy = lazy(() => import('~containers/CreditPolicy/Create'))
const BackofficeLayout = lazy(() => import('~layouts/backoffice'))

const path = '/politicas-credito'
const featureFlag = 'ckpt_credit_policies'

const creditPolicy = [
  {
    path,
    component: CreditPolicies,
    layout: BackofficeLayout,
    exact: true,
    featureFlag,
  },
  {
    path: `${path}/criar-regra`,
    component: CreateCreditPolicy,
    layout: BackofficeLayout,
    exact: true,
    featureFlag,
  },
  {
    path: `${path}/:creditPolicyId`,
    component: CreditPolicy,
    layout: BackofficeLayout,
    exact: true,
    featureFlag,
  },
  {
    path: `${path}/:creditPolicyId/editar-regra`,
    component: CreateCreditPolicy,
    layout: BackofficeLayout,
    exact: true,
    featureFlag,
  },
  {
    path: `${path}/:creditPolicyId/criar-regra`,
    component: CreateCreditPolicy,
    layout: BackofficeLayout,
    exact: true,
    featureFlag,
  },
]

export default creditPolicy
