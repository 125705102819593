import React, { FC, memo } from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { DocumentViewer, PdfViewer } from '~components'
import i18n from '~i18n'
import { MediaViewerProps } from './interface'
import {
  DocumentContainer,
  ImageActionsStyled,
  DocumentActionsStyled,
  ImageWrapper,
  GridStyled,
} from './styles'

const MediaViewer: FC<MediaViewerProps> = ({ isPdf, uri, onNext, onPrev }) => {
  const component = isPdf ? (
    <DocumentContainer>
      <PdfViewer url={uri} />
    </DocumentContainer>
  ) : (
    <DocumentContainer>
      <DocumentViewer path={uri} alt={i18n.t('general.imageEditor')} />
    </DocumentContainer>
  )

  const hasNext = !!onNext

  return hasNext ? (
    <ImageWrapper>
      <GridStyled container>
        <GridStyled item xs={12}>
          {component}
        </GridStyled>
      </GridStyled>
      <ImageActionsStyled>
        <DocumentActionsStyled
          aria-label={i18n.t(`general.next`)}
          color="primary"
          onClick={onNext}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </DocumentActionsStyled>
        <DocumentActionsStyled
          aria-label={i18n.t(`general.previous`)}
          color="primary"
          onClick={onPrev}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </DocumentActionsStyled>
      </ImageActionsStyled>
    </ImageWrapper>
  ) : (
    component
  )
}

export default memo(MediaViewer)
