import styled, { css } from 'styled-components'
import Typography from '~components/dumb/Typography'
import Grid from '~components/dumb/Grid'
import { DocumentResolutionStyledProps } from './interfaces'

export const DocumentContentStyled = styled.div`
  position: relative;
  height: 100%;
  max-height: 600px;
  width: 100%;
  max-width: 800px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`

export const DocumentResolutionStyled = styled.div<DocumentResolutionStyledProps>`
  height: calc(100% - 63px);
  overflow-y: auto;

  ${({ theme, hasEditor }) => css`
    ${theme.breakpoints.up('sm')} {
      height: ${hasEditor ? 'calc(100% - 38px)' : '100%'};
    }
  `}
`

export const TypographyStyled = styled(Typography)`
  && {
    color: #222d5d;
  }
`

export const LabelBlueStyled = styled.div`
  font-size: 1rem;
  color: ${({ theme: { palette } }) => palette.secondary.main};
`

export const ValueLightStyled = styled.div`
  font-size: 1rem;
  color: ${({ theme: { palette } }) => palette.primary.main};
`

export const LabelBlueLargeStyled = styled.div`
  font-size: 1.5rem;
  color: ${({ theme: { palette } }) => palette.secondary.main};
`

const baseItemStyled = css`
  min-height: 65px;
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const WrapFiltersStyled = styled(Grid)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

export const FilterItemStyled = styled.div`
  ${baseItemStyled}
`

export const CheckboxItemStyled = styled.div`
  ${baseItemStyled}

  padding-right: 0;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`

export const WrapSearchMultipleStyled = styled.div`
  ${baseItemStyled}

  overflow-x: auto;
  max-width: 100%;
`
