import React, { memo } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import i18nCalendar from '~i18n/calendar'
import { getLanguage } from '~helpers/locale'
import i18n from '~commons/i18n'
import { MUIDatePickerProps } from './interfaces'

const localeUser = i18nCalendar[getLanguage()]

const localeMap = {
  'pt-BR': ptBR,
}

const MUIDatePicker = (props: MUIDatePickerProps) => {
  const { id, name, inputProps, FormHelperTextProps, format } = props
  const { InputLabelProps, value, onlyDate, ...rest } = props
  const fieldIdentifier = id || name
  const labelledby = `${fieldIdentifier}-label`
  const describedby = `${fieldIdentifier}-helperText`
  const valueIsString = typeof value === 'string'
  const formattedValue =
    onlyDate && valueIsString ? value.substring(0, 10).concat('T00:00') : value

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[getLanguage()] || ptBR}
    >
      <KeyboardDatePicker
        {...rest}
        value={formattedValue}
        format={format}
        InputLabelProps={{
          htmlFor: fieldIdentifier,
          id: labelledby,
          ...InputLabelProps,
          classes: {
            root: 'root',
            ...InputLabelProps?.classes,
          },
        }}
        inputProps={{
          'aria-labelledby': labelledby,
          'aria-describedby': describedby,
          ...inputProps,
        }}
        FormHelperTextProps={{
          id: describedby,
          'aria-labelledby': labelledby,
          role: 'alert',
          ...FormHelperTextProps,
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

MUIDatePicker.defaultProps = {
  format: localeUser.formats.L,
  onlyDate: false,
  clearable: true,
  DialogProps: {
    maxWidth: false,
  },
  minDateMessage: i18n.t('form.minDateMessage'),
  maxDateMessage: i18n.t('form.maxDateMessage'),
  invalidDateMessage: i18n.t('form.invalidDateMessage'),
}

export default memo(MUIDatePicker)
