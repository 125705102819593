import styled from 'styled-components'
import { NavLink, NavLinkProps } from 'react-router-dom'
interface NavLinkStyledProps extends NavLinkProps {
  isRevert?: boolean
}

export const NavLinkStyled = styled(NavLink)<NavLinkStyledProps>`
  height: 64px;
  width: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.gray[0]};

  &.selected {
    color: ${({ theme }) => theme.palette.white[0]};
    background-color: ${({ theme }) => theme.palette.primary.main};

    svg {
      color: ${({ theme }) => theme.palette.white[0]};
      fill: ${({ theme }) => theme.palette.white[0]};
    }
  }

  svg {
    max-height: 24px;
    fill: #17bae6;
  }
`

export const NavTextStyled = styled.span`
  margin-top: 5px;
  font-size: 0.5rem;
  font-weight: 700;
`
