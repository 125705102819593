import styled, { css } from 'styled-components'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { AutocompleteStyledProps } from './interfaces'

export const SmallTextFieldStyled = styled(TextField)`
  && {
    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.gray[700]};
      margin-left: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    .MuiInputBase-root::after,
    .MuiInputBase-root::before {
      border-width: 0px;
    }
  }
`
export const AutoCompleteStyled = styled(Autocomplete)<AutocompleteStyledProps>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};

  .MuiAutocomplete-endAdornment {
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .MuiFormControl-root,
  .MuiInputBase-root {
    background: ${({ theme }) => theme.palette.white[300]};
    border-radius: 0.25rem;
  }

  .MuiInputBase-input {
    ${({ $hideInput }) =>
      $hideInput &&
      css`
        display: none;
      `}
    background-color: transparent;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px;
  }

  .MuiChip-root {
    background: ${({ theme }) => theme.palette.gray[400]};
    border-width: 0px;

    .MuiSvgIcon-root {
      height: 15px;
      color: ${({ theme }) => theme.palette.white[400]};
      cursor: pointer;
    }
  }
`
