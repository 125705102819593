import styled from 'styled-components'

export const ListStyled = styled.ul`
  margin: 0;
`

export const TextStyled = styled.p`
  margin: 0;
  margin-bottom: 0.5rem;
`
