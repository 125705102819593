import React, { memo } from 'react'
import DeleteButton from '../DeleteButton'
import EditButton from '../EditButton'
import { ActionsButtonProps } from './interfaces'
import { ContainerStyled } from './styles'

const ActionsButton = ({
  onEdit,
  disabledEdit,
  onDelete,
  disabledDelete,
}: ActionsButtonProps) => {
  return (
    <ContainerStyled>
      {!!onEdit && <EditButton onClick={onEdit} disabled={disabledEdit} />}
      {!!onDelete && <DeleteButton onClick={onDelete} disabled={disabledDelete} />}
    </ContainerStyled>
  )
}

export default memo(ActionsButton)
