import styled, { css } from 'styled-components'
import Link, { ILinkProps } from '~components/dumb/Link'

export const LinkStyled = styled(Link)<ILinkProps>`
  display: flex;
  align-items: center;
  height: inherit;
  padding: 15px 6px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black[0]};
  ${({ $isLink }) =>
    $isLink &&
    css`
      :hover {
        text-decoration: none;
        background-color: ${({ theme }) => theme.palette.gray[400]};
      }
    `}
  border-right: 1px solid ${({ theme }) => theme.palette.gray[300]};
  text-align: center;
  font-size: 0.75rem;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.palette.gray[400]};
    `}
  cursor: ${({ to }) => (to ? 'pointer' : 'default')};
`
export const WrapperLink = styled.span<{ $isLoading: boolean }>`
  height: inherit;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      padding: 4px;
      display: flex;
      border-right: 1px solid ${({ theme }) => theme.palette.gray[300]};
    `}
`
