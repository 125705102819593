import React from 'react'
import i18n from '~i18n'
import withSkeletonLoading from '~commons/hocs/withSkeleton'
import { DisplayFieldProps } from './interfaces'
import { DisplayStyled } from './styles'

const DisplayField = ({ type, value, loading }: DisplayFieldProps) => {
  const DisplayOrSkeleton = withSkeletonLoading(DisplayStyled, loading, 20)

  return (
    <DisplayOrSkeleton $type={type}>
      {value || i18n.t('general.notInformed')}
    </DisplayOrSkeleton>
  )
}

export default DisplayField
