import {
  Money as MoneyUtils,
  Date as DateUtils,
  Number as NumberUtils,
} from '@base39/ui-utils'
import { GetSimulationByInstallmentId } from '~services'
import { formatInstallmentValue } from '../../util'
import { SimulationFormatted } from './interfaces'

export const formatSimulation = (
  simulation: GetSimulationByInstallmentId,
): SimulationFormatted => ({
  ...simulation,
  formatted: {
    totalValue: MoneyUtils.formatCurrency(simulation.totalValue),
    monthlyCET: NumberUtils.formatPercent(simulation.monthlyCET),
    monthlyFee: NumberUtils.formatPercent(simulation.monthlyFee),
    yearlyCET: NumberUtils.formatPercent(simulation.yearlyCET),
    yearlyFee: NumberUtils.formatPercent(simulation.yearlyFee),
    firstDueDate: DateUtils.formatDateUTC(simulation.firstDueDate),
    installmentValue: MoneyUtils.formatCurrency(simulation.installmentValue),
    insuranceValue: MoneyUtils.formatCurrency(simulation.insuranceValue),
    registerFee: MoneyUtils.formatCurrency(simulation.registerFee),
    insuranceFee: NumberUtils.formatNumber(simulation.insuranceFee),
    iofValue: MoneyUtils.formatCurrency(simulation.iofValue),
    installmentsAndValue: formatInstallmentValue({
      installments: simulation.installments,
      installmentValue: simulation.installmentValue,
    }),
  },
})
