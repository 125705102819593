import React, { memo } from 'react'
import { SelectOptionsProps } from './interfaces'

const SelectOptions = ({ options, labelKey, valueKey, notBlank }: SelectOptionsProps) => (
  <>
    {!notBlank && <option value="" />}
    {options?.map((props) => {
      return (
        <option key={props[valueKey]} value={props[valueKey]}>
          {props[labelKey]}
        </option>
      )
    })}
  </>
)

SelectOptions.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
  notBlank: false,
}

export default memo(SelectOptions)
