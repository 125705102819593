import { lazy } from 'react'

const Customer = lazy(() => import('~containers/Customer'))
const DefaultLayout = lazy(() => import('~layouts/default'))

const path = '/clientes'

const Customers = [
  {
    path: `${path}/:userId`,
    layout: DefaultLayout,
    component: Customer,
    exact: true,
  },
]

export default Customers
