import styled from 'styled-components'

export const InstallmentContainerStyled = styled.div`
  display: flex;
`

export const ChildrenStyled = styled.div`
  min-width: 120px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`
