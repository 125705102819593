import styled, { css } from 'styled-components'

const position = {
  horizontal: css`
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.gray[400]};
    margin: ${({ theme }) => theme.spacing(2)}px 0;
    width: 100%;
  `,
  vertical: css`
    border: none;
    background-color: ${({ theme }) => theme.palette.gray[300]};
    height: 100%;
    width: 2px;
    margin: 0 ${({ theme }) => theme.spacing(2)}px;
  `,
}

const Divider = styled.hr<{ $position?: 'vertical' | 'horizontal' }>`
  ${({ $position }) => position[$position || 'horizontal']}
`

export default Divider
