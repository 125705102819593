import styled from 'styled-components'
import { IconButton } from '~components'

export const TabStyled = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.white[100] : 'transparent'};
  height: 64px;
  max-width: 210px;
  padding: 0.5rem;
`

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TextContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
  }
`

export const WrapperSquareStyled = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TitleStyled = styled.h2`
  margin: 0;
  color: #171725;
  font-family: 'Montserrat SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
`

export const SubtitleStyled = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.palette.gray[100]};
  font-family: 'Montserrat Regular';
  font-size: 10px;
  letter-spacing: 0;
  line-height: 13px;
`

export const IconButtonStyled = styled(IconButton)`
  :hover {
    background: transparent;
  }

  svg {
    width: 8px;
    height: 8px;
    color: #696974;
    path {
      fill: currentcolor;
    }
  }
`
