import React, { useMemo } from 'react'
import { Links } from '..'
import { RightMenuProps } from './interfaces'
import { createMenuItems } from './utils'

const RightMenu = ({ loan, loading }: RightMenuProps) => {
  const items = useMemo(() => createMenuItems({ loan }), [loan])

  return (
    <>
      <Links items={items} loading={loading} />
    </>
  )
}

export default RightMenu
