import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useStoreState } from '~hooks/useStore'

const IntercomUser = ({ children }) => {
  const { user } = useStoreState(({ user }) => user)
  const { update } = useIntercom()

  useEffect(() => {
    if (user) {
      const intercomUserData = {
        company: {
          companyId: user?.tenant,
        },
        name: user?.name,
        email: user?.email,
        created_at: user?.createdAt,
      }

      update(intercomUserData)
    }
  }, [update, user])

  return children
}

export default IntercomUser
