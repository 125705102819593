import styled from 'styled-components'
import Select from '@material-ui/core/Select'

export const SelectCoreStyled = styled(Select)`
  select {
    height: 32px;
    box-sizing: border-box;
    padding-right: ${({ theme }) => theme.spacing(4)}px;
  }
  .MuiSelect-icon {
    z-index: 1;
  }
`
