import { useQuery } from 'react-query'
import { CompaniesApi } from '~services'
import { ListCompaniesParams } from '~services/companies'

const getCompanies = (params: ListCompaniesParams) => CompaniesApi.list(params)

export const useGetCompanies = (params: ListCompaniesParams) => {
  return useQuery(['companies', params], () => getCompanies(params), {
    enabled: !!params,
  })
}
