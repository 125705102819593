import React, { memo } from 'react'
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination'

export interface IPaginationProps extends PaginationProps {}

function PaginationUI(props: PaginationProps) {
  return <Pagination color="primary" {...props} />
}

export default memo(PaginationUI)
