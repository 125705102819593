import styled, { css } from 'styled-components'
import Typography from '~components/dumb/Typography'
import { DisplayFieldProps } from './interfaces'

const TextType = {
  title: css`
    font-size: 1rem;
    font-family: 'Montserrat Bold';
    line-height: 19px;
  `,
  subtitle: css`
    line-height: 19px;
    font-weight: 600;
    font-family: 'Montserrat SemiBold';
    font-size: 0.75rem;
  `,
  smallTitle: css`
    line-height: 15px;
    font-weight: normal;
    font-family: 'Montserrat Regular';
    font-size: 0.75rem;
  `,
}

export const DisplayStyled = styled(Typography)<{
  $type: DisplayFieldProps['type']
}>`
  color: ${({ theme }) => theme.palette.gray[700]};

  ${({ $type }) => TextType[$type]}
`
