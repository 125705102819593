import React, { FC, memo, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import helper from './utils'
import { DocumentViewerProps, ImageSettings } from './interfaces'
import { ImageTools, Viewer, ZoomControls } from './sections'
import { DocumentContentStyled, DocumentResolutionStyled } from './styles'

const initialImageSettings = {
  contrast: 0,
  brightness: 0,
  rotate: 0,
  horizontal: false,
  zoomOutMap: false,
  zoom: 100,
}

const imgZoomValues = {
  zoomOutMapValue: 90,
  initialZoomOutValue: 90,
  initialZoomInValue: 110,
  zoomValue: 10,
}

const DocumentViewer: FC<DocumentViewerProps> = ({ path, alt }) => {
  const isPdf = useMemo(() => !!(path && path.toLowerCase().match(/\.pdf/)), [path])
  const displayEditor = useMemo(() => !isPdf, [isPdf])
  const [imageSettings, setImageSettings] = useState<ImageSettings>(initialImageSettings)
  const [wrapHeight, setWrapHeight] = useState<number>()
  const [imgHeight, setImageHeight] = useState<number>()
  const [zoomFull, setZoomFull] = useState<boolean>(false)
  const refDocumentResolutionStyled = useRef<HTMLDivElement>(null)
  const refDocumentImageStyled = useRef<HTMLDivElement>(null)
  const { zoomOutMapValue, initialZoomOutValue, initialZoomInValue, zoomValue } =
    imgZoomValues

  const updateImageHeight = useCallback(() => {
    if (refDocumentImageStyled.current) {
      setImageHeight(refDocumentImageStyled.current.offsetHeight)
    }
  }, [refDocumentImageStyled])

  const updateImageSetting = (updatedValues: object) => ({
    ...imageSettings,
    ...updatedValues,
  })

  useEffect(() => {
    setWrapHeight(refDocumentResolutionStyled.current.offsetHeight)
    updateImageHeight()
  }, [updateImageHeight, refDocumentResolutionStyled])

  useEffect(() => {
    updateImageHeight()
  }, [imageSettings.horizontal, imageSettings.zoom, updateImageHeight])

  const handleRefDocumentImageStyled = (ref: HTMLImageElement) => {
    if (ref) {
      refDocumentImageStyled.current = ref
    }
  }

  const handleRotateImage = (positive: boolean) => {
    const DEGRESS = 90
    const direction = positive
      ? helper.calcRotation(imageSettings.rotate + DEGRESS)
      : helper.calcRotation(imageSettings.rotate - DEGRESS)
    const updatedValues = updateImageSetting({
      horizontal: !imageSettings.horizontal,
      rotate: direction,
    })
    setImageSettings(updatedValues)
  }

  const handleZoomOutMap = () => {
    const updatedValues = updateImageSetting({
      ...imageSettings,
      zoom: zoomFull ? initialImageSettings.zoom : zoomOutMapValue * 2,
      zoomOutMap: false,
    })

    setImageSettings(updatedValues)
    setZoomFull(!zoomFull)
  }

  const handleZoom = (zoomOut: boolean) => {
    const { zoom: currentZoom } = imageSettings
    const initialZoom = zoomOut ? initialZoomOutValue : initialZoomInValue
    let value = initialZoom

    if (currentZoom) value = zoomOut ? currentZoom - zoomValue : currentZoom + zoomValue

    const updatedValues = updateImageSetting({
      zoom: value,
      zoomOutMap: false,
    })
    setImageSettings(updatedValues)
  }

  return (
    <>
      <DocumentContentStyled data-testid="document-viewer">
        <ImageTools
          path={path}
          imageSettings={imageSettings}
          setImageSettings={setImageSettings}
          handleRotateImage={handleRotateImage}
          updateImageSetting={updateImageSetting}
          displayEditor={displayEditor}
        />

        <ZoomControls
          path={path}
          displayEditor={displayEditor}
          handleZoomOutMap={handleZoomOutMap}
          handleZoom={handleZoom}
          imageSettings={imageSettings}
        />

        <DocumentResolutionStyled
          ref={refDocumentResolutionStyled}
          hasEditor={displayEditor}
        >
          <Viewer
            path={path}
            alt={alt}
            imgReference={handleRefDocumentImageStyled}
            isPdf={isPdf}
            imageSettings={imageSettings}
            wrapHeight={wrapHeight}
            imgHeight={imgHeight}
          />
        </DocumentResolutionStyled>
      </DocumentContentStyled>
    </>
  )
}

export default memo(DocumentViewer)
