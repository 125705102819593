import React from 'react'
import { SQUARE_STATUS } from '~constants/squareStatus'
import { StatusProps } from '~commons/helpers/loan'
import { MenuStatusOptions } from '..'
import { ListMenuStatusOptionsProps } from './interfaces'

const ListMenuStatusOptions = ({
  onClickOption,
  allStatus,
}: ListMenuStatusOptionsProps) => {
  return (
    <>
      {allStatus.length &&
        allStatus.map((statusOption: StatusProps, index) => {
          const square = SQUARE_STATUS[statusOption]

          return (
            <MenuStatusOptions
              key={`status-${index}`}
              statusOption={statusOption}
              onClick={() => onClickOption(statusOption)}
              square={square}
            />
          )
        })}
    </>
  )
}

export default ListMenuStatusOptions
