import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'

export const ChipStyled = styled(Chip)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 28px;
  background: ${({ theme }) => theme.palette.warning.main};
  border-radius: 6px;

  svg {
    width: 11px;
    color: ${({ theme }) => theme.palette.warning.contrastText};
    margin: 0;
  }

  .MuiChip-label {
    padding: 0;
    color: ${({ theme }) => theme.palette.warning.contrastText};
  }
`
