import React, { memo, FC } from 'react'
import { CustomLink } from '..'
import { LinksProps } from './interfaces'

const Links: FC<LinksProps> = ({ items, loading }) => {
  return (
    <>
      {items?.map((item) => (
        <CustomLink
          key={item?.href}
          href={item?.href}
          label={item?.label}
          tooltip={item?.tooltip}
          loading={loading}
        />
      ))}
    </>
  )
}

export default memo(Links)
