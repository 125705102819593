import { lazy } from 'react'
const Visualizations = lazy(() => import('~containers/Visualizations'))
const Layout = lazy(() => import('~containers/Visualizations/Layout'))

const path = '/visualizacoes'

const visualizations = [
  {
    path,
    layout: Layout,
    component: Visualizations,
    exact: true,
  },
]

export default visualizations
