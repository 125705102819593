import React from 'react'
import { DialogConfirmationForm } from '~components'
import { RejectDialogProps } from './interfaces'
import { TypeDialog } from './utils'

const DialogConfimation: React.FC<RejectDialogProps> = ({
  open,
  loading,
  onClose,
  onConfirm,
  type,
}) => {
  return (
    <DialogConfirmationForm
      title={TypeDialog[type].title}
      render={TypeDialog[type].render}
      open={open}
      loading={loading}
      onClose={onClose}
      onConfirm={onConfirm}
      initialValues={TypeDialog[type].initialValues}
      validationSchema={TypeDialog[type].validationSchema}
      hasValidation={TypeDialog[type].hasValidation}
    />
  )
}

export default DialogConfimation
