import yup from '~helpers/yup'
import i18n from '~commons/i18n'

export const personalValidationSchema = yup.object().shape({
  name: yup.string().required().min(3).max(50),
  birthdayDate: yup.date().nullable().disableFuture(),
  cpf: yup.string().required(),
  native: yup.string().required().min(3).max(50),
  nationality: yup.string().required(),
  rg: yup
    .string()
    .matches(/^[aA-zZ0-9]+$/g, i18n.t('form.onlyMatches'))
    .required()
    .min(1)
    .max(15),
  rgState: yup.string().min(2).max(2).required(),
  rgEmitter: yup.string().required(),
  rgIssueDate: yup.date().when('type', {
    is: (type) => type === 'BACK_DOCUMENT',
    then: yup.date().required().nullable().disableFuture(),
    otherwise: yup.date().nullable().disableFuture(),
  }),
  motherFullName: yup.string().required().documentText(),
})

export const lastPayStubValidationSchema = yup.object().shape({
  hiredAt: yup.date().required().nullable(),
  jobRole: yup.string().required().documentText(),
  netSalary: yup
    .number()
    .lessThan(yup.ref('salary'), i18n.t('general.netSalaryMustBeLowerThanSalary'))
    .required(),
  salary: yup
    .number()
    .moreThan(yup.ref('netSalary'), i18n.t('general.salaryMustBeGreaterThanNetSalary'))
    .required(),
})

export const addressValidationSchema = yup.object().shape({
  zipCode: yup.string().zipCode().required(),
  address: yup.string().min(3).max(50).required(),
  complement: yup.string().max(100),
  number: yup.string().max(10).required(),
  city: yup.string().min(3).required(),
  state: yup.string().required(),
  neighborhood: yup.string().min(3).max(100).required(),
})
