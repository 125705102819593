import React, { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Tooltip } from '~components'
import withSkeleton from '~hocs/withSkeleton'
import { LinkStyled, WrapperLink } from './styles'
import { CustomLinkProps, LocationProps } from './interfaces'

const CustomLink: React.FC<CustomLinkProps> = ({
  tooltip,
  href,
  loading = false,
  label,
}) => {
  const { pathname } = useLocation<LocationProps>()
  const isSelected = href === pathname
  const heightLink = 0
  const widthLink = 100
  const LinkwithSkeleton = withSkeleton(LinkStyled, loading, heightLink, widthLink)

  return (
    <Tooltip title={tooltip}>
      <WrapperLink $isLoading={loading} data-testid="wrapper-link">
        <LinkwithSkeleton
          color="inherit"
          to={href}
          $isLink={href}
          $isSelected={isSelected}
        >
          {label}
        </LinkwithSkeleton>
      </WrapperLink>
    </Tooltip>
  )
}

export default memo(CustomLink)
