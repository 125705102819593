import React from 'react'
import { InputProps } from '@material-ui/core/Input'
import { withField } from '../Field'
import { onKeyDownNumber, onPasteNumber } from '../utils'
import { MuiInputStyled } from './style'

const getInputProps = (type, inputProps) => {
  if (type === 'number') {
    return {
      ...inputProps,
      onPaste: onPasteNumber,
      onKeyDown: onKeyDownNumber,
    }
  }

  return inputProps
}

const Input = ({ inputProps, ...props }: InputProps) => (
  <MuiInputStyled {...props} inputProps={getInputProps(props.type, inputProps)} />
)

Input.defaultProps = {
  type: 'text',
  inputProps: {},
}

export { Input }
export default withField(Input)
