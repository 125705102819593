import React, { FC, memo, useMemo } from 'react'
import { Formik } from 'formik'
import {
  String as StringUtils,
  Date as DateUtils,
  Object as ObjectUtils,
  Age as AgeUtils,
} from '@base39/ui-utils'
import { COUNTRIES, RG_EMITTERS } from '@base39/constants-tools'
import i18n from '~i18n'
import { DatePicker } from '~components'
import { Personal } from '~types'
import LoanApi from '~services/loans'
import { queryClient } from '~commons/react-query'
import Grid from '~components/dumb/Grid'
import Input from '~components/dumb/Form/Input'
import Select from '~components/dumb/Form/Select'
import InputDocumentText from '~components/dumb/Form/InputDocumentText'
import AgeChip from '~components/smart/AgeChip'
import { useStoreState } from '~commons/hooks'
import { AgeChipContentStyled, AgeContentStyled } from '../../styles'
import { personalValidationSchema } from './model'
import { FormPersonalDataProps } from './interfaces'

const PersonalData: FC<FormPersonalDataProps> = ({
  user,
  loanId,
  type,
  loading,
  setLoading,
  onApprove,
}) => {
  const formikRef = useStoreState((state) => state.deskDocuments.formikRef)
  const initialValues = useMemo(() => {
    const {
      name,
      cpf,
      native,
      nationality,
      maritalStatus,
      isPublicPerson,
      rg,
      rgEmitter,
      rgState,
      birthdayDate,
      rgIssueDate,
      motherFullName,
    } = user || {}

    return {
      name,
      cpf: StringUtils.formatCPF(cpf),
      native,
      nationality,
      maritalStatus,
      rg,
      rgState,
      rgEmitter,
      birthdayDate: DateUtils.parseDate(
        DateUtils.formatDateUTC(birthdayDate),
        'dd/MM/yyyy',
      ),
      isPublicPerson,
      rgIssueDate: rgIssueDate
        ? DateUtils.parseDate(DateUtils.formatDateUTC(rgIssueDate), 'dd/MM/yyyy')
        : null,
      motherFullName,
      type,
    }
  }, [user, type])

  const handleConfirm = (values: Personal) => {
    const objValueChanged = ObjectUtils.getObjectChanged({
      initialValues,
      changedValues: values,
    })
    const objHasChanged = !ObjectUtils.isObjectEmpty(objValueChanged)

    if (objHasChanged) {
      setLoading(true)

      LoanApi.setUpdateLoan({
        loanId,
        personal: objValueChanged,
      })
        .then(async () => {
          onApprove(objValueChanged)

          await queryClient.refetchQueries(['loan', loanId], {
            active: true,
            exact: true,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      onApprove(objValueChanged)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={personalValidationSchema}
      onSubmit={handleConfirm}
      innerRef={formikRef}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id={`${type}-form`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                name="name"
                label={i18n.t('user.name')}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="cpf"
                label={i18n.t('user.cpf')}
                required
                disabled
                mask="cpf"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <AgeContentStyled>
                <AgeChipContentStyled $visible={!!AgeUtils.getAge(values?.birthdayDate)}>
                  <AgeChip age={AgeUtils.getAge(values?.birthdayDate)} />
                </AgeChipContentStyled>
                <DatePicker
                  name="birthdayDate"
                  label={i18n.t('user.birthday')}
                  disabled={loading}
                  disableFuture
                />
              </AgeContentStyled>
            </Grid>
            <Grid item xs={12}>
              <Input
                name="rg"
                label={i18n.t('user.rgCnh')}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="rgEmitter"
                label={i18n.t('user.rgEmitter')}
                fullWidth
                disabled={loading}
              >
                <option data-testid="emitters-option" value="" />
                {RG_EMITTERS.map(({ rgEmitter, description }) => (
                  <option
                    data-testid="emitters-option"
                    value={rgEmitter}
                    key={`emitter-option-${rgEmitter}`}
                  >
                    {rgEmitter}
                    {description && ` - ${description}`}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputDocumentText
                name="rgState"
                label={i18n.t('user.rgState')}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              {type === 'BACK_DOCUMENT' && (
                <DatePicker
                  name="rgIssueDate"
                  label={i18n.t('user.rgIssueDate')}
                  disabled={loading}
                  disableFuture
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <InputDocumentText
                name="motherFullName"
                label={i18n.t('user.motherName')}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <InputDocumentText
                name="native"
                label={i18n.t('user.native')}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="nationality"
                label={i18n.t('user.nationality')}
                margin="none"
                fullWidth
              >
                <option value="" />
                {COUNTRIES.map(({ countryName }) => (
                  <option value={countryName} key={`state-option-${countryName}`}>
                    {countryName}
                  </option>
                ))}
              </Select>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default memo(PersonalData)
