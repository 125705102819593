import React from 'react'
import { InputProps } from '@material-ui/core/Input'
import { fieldsValidations } from '~commons/helpers/form'
import { Input } from '../Input'
import { withField } from '../Field'

export const InputDocumentText = (props: InputProps) => {
  const { onChange } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    event.target.value = fieldsValidations.documentText(value)

    onChange(event)
  }

  return <Input {...props} onChange={handleChange} />
}

export default withField(InputDocumentText)
