import styled from 'styled-components'
import { FormControl as MuiFormControl, InputLabel } from '@material-ui/core'

export const MuiFormControlStyled = styled(MuiFormControl)`
  border-color: #f1f1f1;
  border-radius: 4px;
  font-weight: 400;
  margin: 0 0 10px 0;

  & fieldset {
    border-color: #f1f1f1;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }
`

export const InputLabelStyled = styled(InputLabel)`
  display: inherit;
`
