import { GetByZipCodeResponse } from '~types'
import { ApiSls } from './api'

const path = 'addresses'

const Address = {
  getAddressByZipCode(zipCode: string) {
    return ApiSls.customRequest<GetByZipCodeResponse>(`v1/${path}/${zipCode}`, {
      method: 'GET',
      interceptors: {
        404: false,
      },
    })
  },
}

export default Address
