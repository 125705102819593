import React, { Suspense, memo } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import routes from '~routes'
import { Loading } from '~components'
import AppRoute from './AppRoute'

const Main = () => {
  return (
    <Suspense fallback={<Loading full={true} />}>
      <Switch>
        {routes.map((route, index) => (
          <AppRoute key={index} {...route} />
        ))}
        <Route path="*" component={() => <Redirect from="*" to="/" />} />
      </Switch>
    </Suspense>
  )
}

export default memo(Main)
