import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { Title } from '~components/dumb/Text'

export const WrapperStyled = styled.div`
  margin-top: 0.5rem;

  .MuiInputBase-root {
    max-height: 28px;
  }
`

export const InputStyled = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.paper};

  > .MuiInputBase-root {
    border-color: red;
    padding: 0.25rem;

    > fieldset {
      border-color: ${({ theme }) => theme.palette.gray[300]};
      padding: 0.25rem;
    }

    :hover {
      > fieldset {
        border-color: ${({ theme }) => theme.palette.gray[300]};
      }
    }

    .Mui-disabled {
      background-color: transparent;
    }

    .MuiChip-root {
      background-color: ${({ theme }) => theme.palette.gray[400]};
      border-radius: 0.25rem;
      height: 1.5rem;

      .MuiChip-label {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
      }

      .MuiChip-deleteIcon {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  }
`

export const TitleStyled = styled(Title)`
  text-transform: uppercase;
`
