import React, { FC, ReactNode } from 'react'
import { TypographyProps } from '@material-ui/core/Typography'
import { TypographyStyled } from './styles'

interface IProps extends TypographyProps {
  children?: ReactNode
  className?: string
}

const Typography: FC<IProps> = ({ children, ...props }) => {
  return (
    <TypographyStyled data-testid="typography" {...props}>
      {children}
    </TypographyStyled>
  )
}

export default Typography
