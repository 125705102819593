import styled, { css } from 'styled-components'
import { DocumentStatusType } from './interfaces'

export const DocumentStatusStyled = styled.span<{ type: DocumentStatusType }>`
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 8px;

  ${({ type }) => type && documentStatusClass[type]}
`

const documentStatusClass = {
  PENDING: css`
    background-color: ${({ theme }) => theme.palette.gray['400']};
    color: ${({ theme }) => theme.palette.gray['700']};
  `,
  APPROVED: css`
    background-color: rgba(61, 213, 152, 0.2);
    color: #00b894;
  `,
  DENIED: css`
    background-color: rgba(252, 90, 90, 0.2);
    color: ${({ theme }) => theme.palette.error.main};
  `,
}
