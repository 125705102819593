import styled from 'styled-components'

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 3rem;
  line-height: 1;
`
