import { createTheme } from '@material-ui/core/styles'
import { Shadows } from '@material-ui/core/styles/shadows'
import { Tenant } from '@base39/ui-utils'
import { abcTheme, paketaTheme, defaultTheme, sahcredTheme } from './themeSheet'

const whiteLabelThemes = {
  abc: abcTheme,
  paketa: paketaTheme,
  sahcred: sahcredTheme,
  default: defaultTheme,
}

const tenant = Tenant.getTenant()
const themeDefined = whiteLabelThemes[tenant] || whiteLabelThemes.default

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat Regular',
    subtitle2: {
      fontWeight: 600,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 340,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  ...themeDefined,
  palette: {
    ...themeDefined.palette,
    black: {
      0: '#000000',
      100: 'rgba(0,0,0,0.75)',
    },
    gray: {
      0: '#b5b5be',
      100: '#92929d',
      200: '#E5E5E5',
      300: '#DBDDDE',
      400: '#e2e2ea',
      500: '#d5d5dc',
      550: '#7B7B7B',
      600: '#4A4A4A',
      700: '#555555',
      800: '#44444F',
    },
    white: {
      0: '#ffffff',
      100: '#fafafb',
      200: '#f1f1f5',
      300: '#f5f5f5',
      400: '#f8f8f8',
    },
  },
  shadows: Array(25).fill('none') as Shadows,
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: '6px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#44444F',
        opacity: '48%',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.875em',
      },
    },
    MuiInputBase: {
      input: {
        '&.Mui-disabled': {
          color: '#c4c4c4',
          backgroundColor: '#f5f5f5',
        },
        fontFamily: 'Montserrat Medium',
        fontSize: '16px',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Montserrat Regular',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Montserrat Regular',
        textTransform: 'none',
      },
      textColorPrimary: {
        color: '#92929d',
        '&.Mui-selected': {
          fontWeight: 700,
        },
        '&.Mui-disabled': {
          color: '#92929d',
        },
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Montserrat Regular',
        color: '#555555',
      },
      head: {
        fontFamily: 'Montserrat Bold',
        color: '#7B7B7B',
      },
    },
  },
})

export default theme
