import styled from 'styled-components'
import { ReactComponent as ArrowBackIcon } from '~assets/images/svg/arrow-left.svg'

export const ContentStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.white[300]};
`

export const ContainedIconStyled = styled(ArrowBackIcon)`
  color: ${({ theme }) => theme.palette.gray[550]};
  width: 24px;
  height: 24px;
`
