import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const TransparentButtonStyled = styled.button`
  outline: none;
  border: none;
  height: fit-content;
  background: transparent;
  cursor: pointer;
`

export const ToastContainerStyled = styled(ToastContainer).attrs({})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 16px;
    width: 320px;
    margin: 0;
    padding: 17px 16px 10px 15px;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
  .Toastify__toast-body {
    margin: 0;
  }
  .Toastify__progress-bar {
  }
`
