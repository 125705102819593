import i18n from '~i18n'

export const MARITAL_STATUS = {
  single: i18n.t('constants.maritalStatus.single'),
  married: i18n.t('constants.maritalStatus.married'),
  widower: i18n.t('constants.maritalStatus.widower'),
  separated: i18n.t('constants.maritalStatus.separated'),
  divorced: i18n.t('constants.maritalStatus.divorced'),
}

export default MARITAL_STATUS
