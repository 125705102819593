import { lazy } from 'react'

const Simulator = lazy(() => import('~containers/Simulator'))

const path = '/simulador'

const simulator = [
  {
    path,
    component: Simulator,
    exact: true,
    menu: false,
  },
]

export default simulator
