import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '~components/dumb/IconButton'
import { ReactComponent as HomeIcon } from '~assets/images/svg/home.svg'
import { ReactComponent as Archive } from '~assets/images/svg/archive.svg'
import { ReactComponent as MailIcon } from '~assets/images/svg/mail.svg'
import { ReactComponent as SearchIcon } from '~assets/images/svg/search.svg'
import { ReactComponent as MoneyIcon } from '~assets/images/svg/money.svg'
import { ReactComponent as SettingsIcon } from '~assets/images/svg/settings.svg'
import Theme from '~constants/theme'

export const AppBarStyled = styled(AppBar)`
  .MuiToolbar-gutters {
    padding: 0 2rem;
  }
`

export const ContainerStyled = styled.div`
  box-shadow: 0px 2px 6px ${({ theme }) => theme.palette.gray[500]};
  z-index: 1;
`

export const FormStyled = styled.form`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 406px;
  background-color: ${({ theme }) => theme.palette.white[300]};
  padding: 0.25rem 0 0.25rem 0.5rem;
  border-radius: 8px;

  .filter-input {
    padding: 0px;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.gray[700]};
    input::placeholder {
      color: ${({ theme }) => theme.palette.gray[700]};
      opacity: 1;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }
`

export const IconButtonStyled = styled(IconButton)`
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const RightSectionStyled = styled.div`
  display: flex;
  min-width: 292px;
`

export const ToolbarContentStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const TopBarNavigationStyled = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 192px;
`

export const IconArchive = styled(Archive)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[100]};
  height: 24px;
  width: 24px;
`

export const IconMail = styled(MailIcon)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[100]};
  height: 1.5rem;
  width: 1.5rem;
`

export const IconHome = styled(HomeIcon)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[100]};
  height: 1.5rem;
  width: 1.5rem;
`

export const MoneyIconStyled = styled(MoneyIcon)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[100]};
  height: 1.5rem;
  width: 1.5rem;
`

const LogoMain = Theme.logo.main

export const IconPaketa = styled(LogoMain)`
  height: 2rem;
  width: 6rem;
  margin-right: 2rem;
`

export const IconSearch = styled(SearchIcon)`
  height: 20px;
  width: 20px;
`

export const SettingsIconStyled = styled(SettingsIcon)`
  path {
    fill: currentColor;
  }
  color: ${({ theme }) => theme.palette.gray[100]};
  height: 1.5rem;
  width: 1.5rem;
`
