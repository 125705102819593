import styled from 'styled-components'

export const ContentStyled = styled.span`
  font-weight: 400;
  display: flex;
  align-items: center;

  .info {
    margin-left: 0.5rem;
  }
`
