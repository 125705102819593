import { createGlobalStyle } from 'styled-components'
import './fonts.css'

const globalStyle = createGlobalStyle`
  * {
    outline: none;
    box-sizing: border-box;
    font-family: "Montserrat Regular";
  }

  html {
    font-family: "Montserrat Regular", "Helvetica", "Arial", sans-serif;
  }

  body {
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #root {
    width: 100%;
    min-height: 100%;
    display: flex;
    background-color: #fff;
  }
`

export default globalStyle
