import React, { useRef, useEffect, FC } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { SliderTooltipProps } from './interfaces'

const SliderTooltip: FC<SliderTooltipProps> = (props) => {
  const { children, open, value } = props
  const popperRef = useRef(null)

  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update()
    }
  }, [popperRef])

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  )
}

export default SliderTooltip
