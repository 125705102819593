import React, { FC, memo } from 'react'
import { spacing } from '@material-ui/system'
import { styled } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { ButtonProps } from './interface'
import { ButtonStyled } from './styles'

const ButtonWithSpacing = styled(ButtonStyled)(spacing)

const CUSTOM_COLORS = ['error', 'success']

const ButtonUI: FC<ButtonProps> = ({ children, isLoading, startIcon, ...props }) => {
  const color = CUSTOM_COLORS.includes(props.color) ? undefined : props.color

  return (
    <ButtonWithSpacing
      data-testid="button"
      $customColor={props.color}
      {...props}
      color={color}
      startIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : startIcon}
    >
      {children}
    </ButtonWithSpacing>
  )
}

ButtonUI.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'small',
  isLoading: false,
}

export default memo(ButtonUI)
