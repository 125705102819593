import { useQuery } from 'react-query'
import { LoanApi } from '~services'
import { formatAcquittance } from '~commons/helpers/formatData'

const getAcquittanceBillets = (loanId: string) =>
  LoanApi.getAcquittanceBillets(loanId).then(formatAcquittance)

export const useGetAcquittanceBillets = (loanId: string) => {
  return useQuery(['acquittanceBillets', loanId], () => getAcquittanceBillets(loanId), {
    enabled: !!loanId,
  })
}
