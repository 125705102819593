import React, { memo, FC, useCallback, useState, useRef } from 'react'
import { String as StringUtils } from '@base39/ui-utils'
import { useParams, useHistory } from 'react-router-dom'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Tab } from '~components'
import { useOutsideClick, useWindowDimensions } from '~hooks'
import { useStoreActions, useStoreState } from '~hooks/useStore'
import {
  TransparentButtonStyled,
  TabContainerStyled,
  RelativeWrapperStyled,
  ListStyled,
  ListItemTextStyled,
} from './styles'
import { IParams, IProps } from './interfaces'

const TabSection: FC<IProps> = () => {
  const { width } = useWindowDimensions()
  const { tabs } = useStoreState(({ tabs }) => tabs)
  const { removeTab, setTabs } = useStoreActions(({ tabs }) => ({
    ...tabs,
  }))
  const ref = useRef()
  const { loanId } = useParams<IParams>()
  const history = useHistory()
  const [showOptions, setShowOptions] = useState(false)
  const padding = 40
  const menuLeft = 192
  const menuRigth = 292
  const tabWidth = 208
  const availableWidth = width - menuLeft - menuRigth - padding
  const tabQuantity = Math.floor(availableWidth / tabWidth)

  useOutsideClick(ref, () => {
    setShowOptions(false)
  })

  const handleTabClick = useCallback((id) => history.push(`/propostas/${id}`), [history])

  const renderTab = useCallback(
    ({ user: { name }, company, id, status }) => {
      const selected = loanId === id
      const subtitle = `${company?.tradeName} - ${StringUtils.formatCNPJ(company?.cnpj)}`

      const handleTabClose = () => {
        const anotherLoans = tabs.filter((loan) => loan.id !== id)
        const lastLoanOpen = anotherLoans?.[anotherLoans.length - 1]?.id
        history.push(`/propostas${lastLoanOpen ? `/${lastLoanOpen}` : ''}`)
        removeTab(id)
      }

      return (
        <Tab
          key={`${id}-tab`}
          selected={selected}
          title={name}
          subtitle={subtitle}
          onClick={() => handleTabClick(id)}
          onClose={handleTabClose}
          loanStatus={status}
        />
      )
    },
    [handleTabClick, history, loanId, removeTab, tabs],
  )

  const indexToSliceArray = tabQuantity
  const mainLoanTabs = tabs.slice(0, indexToSliceArray)
  const anotherLoansTabs = tabs.slice(indexToSliceArray)
  const showMoreButton = !!anotherLoansTabs.length
  const handleClickMoreTabs = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions)
  }

  return (
    <TabContainerStyled>
      {mainLoanTabs.map(renderTab)}
      <RelativeWrapperStyled ref={ref}>
        {showMoreButton && (
          <TransparentButtonStyled onClick={handleClickMoreTabs}>
            Mais <KeyboardArrowDownIcon />
          </TransparentButtonStyled>
        )}
        {showOptions && (
          <ListStyled>
            {anotherLoansTabs.map((currentLoan, index) => {
              const {
                user: { name },
                id,
              } = currentLoan
              return (
                <ListItemTextStyled
                  onClick={() => {
                    const loansWithoutCurrent = tabs.filter((loan) => loan.id !== id)
                    handleTabClick(id)

                    setTabs([currentLoan, ...loansWithoutCurrent])
                    setShowOptions(false)
                  }}
                  primary={name}
                  key={index}
                />
              )
            })}
          </ListStyled>
        )}
      </RelativeWrapperStyled>
    </TabContainerStyled>
  )
}

export default memo(TabSection)
