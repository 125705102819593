import { useQuery } from 'react-query'
import { MaxInstallmentValueParams } from '~types'
import { SimulationApi } from '~services'
import { formatMaxInstallmentValue } from '~helpers/formatData'

const getMaxInstallmentValue = ({
  netSalary,
  salary,
  userId,
}: MaxInstallmentValueParams) =>
  SimulationApi.getMaxInstallmentValue({
    netSalary,
    salary,
    userId,
  }).then(formatMaxInstallmentValue)

export const useGetMaxInstallmentValue = ({
  netSalary,
  salary,
  userId,
}: MaxInstallmentValueParams) => {
  return useQuery(
    ['maxInstallmentValue', netSalary, salary, userId],
    () => getMaxInstallmentValue({ netSalary, salary, userId }),
    {
      enabled: !!netSalary && !!salary && !!userId,
    },
  )
}
