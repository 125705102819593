import styled from 'styled-components'
import { Button, Grid } from '~components'

export const DocumentContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const DocumentActionsStyled = styled(Button)`
  display: flex;
  min-width: 21px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
`

export const ImageActionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`

export const GridStyled = styled(Grid)`
  height: 100%;
`
