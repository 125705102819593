import React, { FC } from 'react'
import { PersonalData, LastPayStub, ProofAddress } from './Forms'
import { FormProps } from './interfaces'

const FORMS = {
  FRONT_DOCUMENT: PersonalData,
  BACK_DOCUMENT: PersonalData,
  LAST_PAY_STUB: LastPayStub,
  PROOF_OF_ADDRESS: ProofAddress,
}

const DocumentForm: FC<FormProps> = ({
  type,
  user,
  loanId,
  company,
  companyName,
  address,
  loading,
  setLoading,
  onApprove: handleApprove,
}) => {
  const Form = FORMS[type]
  const disableEdit = company?.lockSalary || !!company?.consignableMargin

  if (!Form) {
    return null
  }

  return (
    <Form
      loading={loading}
      setLoading={setLoading}
      user={user}
      loanId={loanId}
      company={company}
      companyName={companyName}
      address={address}
      type={type}
      disableEdit={disableEdit}
      onApprove={handleApprove}
    />
  )
}

export default DocumentForm
