import authRoutes from './auth'
import home from './home'
import loans from './loans'
import documents from './documents'
import privacyPolicy from './privacy-policy'
import visualizations from './visualizations'
import customer from './customer'
import company from './company'
import simulator from './simulator'
import creditPolicy from './credit-policy'
import notFound from './notFound'

const routes = [
  ...home,
  ...loans,
  ...documents,
  ...authRoutes,
  ...privacyPolicy,
  ...visualizations,
  ...customer,
  ...company,
  ...simulator,
  ...creditPolicy,
  ...notFound,
]

export default routes
