import React from 'react'
import { MoneyCardProps } from './interface'
import { TitleStyled, ValueStyled, ContainerStyled } from './styles'

const MoneyCard = ({ id, title, value }: MoneyCardProps): JSX.Element => {
  const labelledby = `${id}-labelledby`
  const describedby = `${id}-describedby`

  return (
    <ContainerStyled
      id={id}
      aria-labelledby={labelledby}
      aria-describedby={describedby}
      role="section"
    >
      <TitleStyled id={labelledby} aria-describedby={describedby} role="heading">
        {title}
      </TitleStyled>
      <ValueStyled id={describedby} aria-labelledby={labelledby} role="contentinfo">
        {value}
      </ValueStyled>
    </ContainerStyled>
  )
}

export default MoneyCard
