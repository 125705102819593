import styled from 'styled-components'
import { Accordion as MuiAccordion } from '@material-ui/core'

export const AccordionStyled = styled(MuiAccordion)`
  background-color: ${({ theme }) => theme.palette.white[300]};
  margin-bottom: 1.5rem;
  border: none;

  .MuiCollapse-wrapper {
    padding-bottom: 1rem;
  }

  &.MuiAccordion-root:before {
    display: none;
  }
`
