import { useQuery } from 'react-query'
import { CreditPolicyApi } from '~services'
import { formatCreditPolicy } from '~helpers/formatData'

const getCreditPolicyByLoanId = (loanId: string) =>
  CreditPolicyApi.getByLoanId(loanId).then((data) => {
    return formatCreditPolicy(data)
  })

export const useGetCreditPolicyByLoanId = (loanId: string) => {
  return useQuery(
    ['creditPolicy-by-loan', loanId],
    () => getCreditPolicyByLoanId(loanId),
    {
      enabled: !!loanId,
    },
  )
}
