import React, { FC } from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Highlight } from '../Text'
import { WrapperStyled } from './styles'

interface IProps {}

const NoData: FC<IProps> = () => {
  return (
    <WrapperStyled data-testid="no-data">
      <HighlightOffIcon color="primary" fontSize="inherit" />
      <Highlight>Sem informações</Highlight>
    </WrapperStyled>
  )
}

export default NoData
