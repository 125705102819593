import React, { memo } from 'react'
import { ReactComponent as TrashIcon } from '~assets/images/svg/btn-trash.svg'
import i18n from '~commons/i18n'
import { DeleteButtonProps } from './interfaces'
import { ButtonStyled } from './styles'

const DeleteButton = ({ ...props }: DeleteButtonProps) => {
  return (
    <ButtonStyled
      variant="outlined"
      size="small"
      aria-label={i18n.t('general.exclude')}
      {...props}
    >
      <TrashIcon />
    </ButtonStyled>
  )
}

export default memo(DeleteButton)
