import React from 'react'
import { Mask as MaskUtils } from '@base39/ui-utils'
import MaskedInput from 'react-input-mask'
import { CircularProgress, InputAdornment } from '~components'
import { withField } from '../Field'
import { Input } from '../Input'
import { InputMaskProps } from './interfaces'

const InputMask = ({ onChange, disabled, mask, isLoading, ...props }: InputMaskProps) => {
  const handleChange = (event) => {
    event.target.value = MaskUtils.getUnmask(mask)(event.target.value)
    onChange(event)
  }

  const maskFormat = MaskUtils.getMask(mask)

  return (
    <MaskedInput {...props} disabled={disabled} mask={maskFormat} onChange={handleChange}>
      {(inputProps) => (
        <Input
          disabled={disabled}
          endAdornment={
            isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={25} />
              </InputAdornment>
            )
          }
          {...inputProps}
        />
      )}
    </MaskedInput>
  )
}

InputMask.defaultProps = {
  maskChar: '',
  disabled: false,
  type: 'tel',
}

export { InputMask }
export default withField(InputMask)
