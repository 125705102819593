import React from 'react'
import withSkeletonLoading from '~commons/hocs/withSkeleton'
import i18n from '~commons/i18n'
import Feature from '../Feature'
import Grid from '../Grid'
import { SidebarDataProps } from './interfaces'
import {
  ContainerStyled,
  LabelStyled,
  ValueStyled,
  TitleStyled,
  NavLinkStyled,
} from './styles'

const SidebarData = ({ title, data, loading }: SidebarDataProps) => {
  const valueSkeletonHeight = 32
  const valueSkeletonWidth = '100%'
  const ValueWithSkeleton = withSkeletonLoading(
    ValueStyled,
    loading,
    valueSkeletonHeight,
    valueSkeletonWidth,
  )
  return (
    <ContainerStyled container spacing={1} direction="column" data-testid="sidebar-data">
      {title && (
        <Grid item xs>
          <TitleStyled data-testid="title">{title}</TitleStyled>
        </Grid>
      )}
      {data.map((row) =>
        row?.path ? (
          <Feature feature={row?.featureFlag} key={row.path}>
            <NavLinkStyled key={row.path} to={row.path} activeClassName="selected">
              {row.label}
            </NavLinkStyled>
          </Feature>
        ) : (
          <Grid item xs key={row.label}>
            <LabelStyled>{row.label}:</LabelStyled>
            <ValueWithSkeleton>
              {row.value || i18n.t('general.notInformed')}
            </ValueWithSkeleton>
          </Grid>
        ),
      )}
    </ContainerStyled>
  )
}

export default SidebarData
