import React from 'react'
import i18n from '~i18n'
import RejectDialogContent from '../RejectDialogContent'
import AverbationDialogContent from '../AverbationDialogContent'
import { initialValues, validationSchema } from '../RejectDialogContent/helper'

const REJECT_DIALOG = {
  render: () => <RejectDialogContent />,
  initialValues: initialValues,
  validationSchema: validationSchema,
  hasValidation: true,
  title: i18n.t('proposal.confirmReprove'),
}

export const TypeDialog = {
  REJECTED_RH: REJECT_DIALOG,
  REJECTED_PAKETA: REJECT_DIALOG,
  IN_ANALYSIS_RH: {
    render: () => <AverbationDialogContent />,
    initialValues: '',
    validationSchema: null,
    hasValidation: false,
    title: i18n.t('proposal.approvePaketa'),
  },
}
