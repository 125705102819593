import styled from 'styled-components'
import { DialogContent, DialogActions } from '@material-ui/core'
import DialogHeader from './DialogHeader'
import DialogWrapper from './DialogWrapper'

type JustifyContentProperty = 'center' | 'flex-start' | 'flex-end' | 'space-around'

export const DialogActionsStyled = styled(DialogActions)<{
  $justifyContent?: JustifyContentProperty
}>`
  background: ${({ theme }) => theme.palette.white[100]};
  display: flex;
  padding: 1.75rem;
  justify-content: ${({ $justifyContent }) =>
    $justifyContent ? $justifyContent : 'flex-end'};
  border-radius: 1rem;
`

export const DialogContentStyled = styled(DialogContent)`
  ::-webkit-scrollbar {
    background: ${({ theme }) => theme.palette.white[200]};
    width: 0.5rem;
    position: absolute;
    border-radius: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.gray[400]};
    border-radius: 0.25rem;
  }
`

export const DialogHeaderStyled = styled(DialogHeader)``

export const DialogWrapperStyled = styled(DialogWrapper)`
  .paper {
    box-shadow: none;
    border-radius: 1rem;
  }
`
