import { Tenant } from '@base39/ui-utils'

const tenant = Tenant.getTenant()

export const SQUARE_STATUS = {
  REQUESTED_CHANGES_PAKETA: {
    color: 'clementine',
    initial: 'ASP',
    tooltip: `loan.status.${tenant}.underReviewPaketa`,
  },
  REQUESTED_CHANGES_FINANCIAL: {
    color: 'brightSun',
    initial: 'ASF',
    tooltip: 'loan.status.underReviewFinancial',
  },
  REQUESTED_CHANGES_RH: {
    color: 'goldenFizz',
    initial: 'ASRH',
    tooltip: 'loan.status.underReviewRH',
  },
  IN_ANALYSIS_PAKETA: {
    color: 'malibu',
    initial: 'EAP',
    tooltip: `loan.status.${tenant}.inAnalysisPaketa`,
  },
  IN_ANALYSIS_FINANCIAL: {
    color: 'dodgerBlue',
    initial: 'EAF',
    tooltip: 'loan.status.inAnalysisFinancial',
  },
  IN_ANALYSIS_RH: {
    color: 'resolutionBlue',
    initial: 'AGA',
    tooltip: 'loan.status.inAnalysisRH',
  },
  REJECTED_RH: {
    color: 'carnation',
    initial: 'RPRH',
    tooltip: 'loan.status.rejectedByRH',
  },
  RESEND_FINANCIAL: {
    color: 'dodgerBlue',
    initial: 'EAF',
    tooltip: 'loan.status.inAnalysisFinancial',
  },
  REJECTED_PAKETA: {
    color: 'carnation',
    initial: 'RPP',
    tooltip: `loan.status.${tenant}.rejectedByPaketa`,
  },
  SIGNATURE_PENDING: {
    color: 'mediumPurple',
    initial: 'AAC',
    tooltip: 'loan.status.signaturePending',
  },
  SIGNED: {
    color: 'cobalt',
    initial: 'ASS',
    tooltip: 'loan.status.signed',
  },
  DONE: {
    color: 'tradewind',
    initial: 'PGA',
    tooltip: 'loan.status.done',
  },
  PAID: {
    color: 'plantation',
    initial: 'QTD',
    tooltip: 'loan.status.paid',
  },
  CANCELED: {
    color: 'lynch',
    initial: 'CAN',
    tooltip: 'loan.status.canceled',
  },
} as const
