import React, { FC } from 'react'
import { Router } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { StylesProvider } from '@material-ui/styles'
import { StoreProvider } from 'easy-peasy'
import { Router as RouterUtils } from '@base39/ui-utils'
import { Intercom } from '~components'
import PaketaProvider from '~containers/PaketaProvider'
import ToastContainer from '~components/smart/ToastContainer'
import store from '~hooks/useStore'
import 'react-toastify/dist/ReactToastify.css'
import { queryClient } from '~commons/react-query'
import ThemeProvider from './withThemeProvider'

function withProviders<T>(Component: FC<T>) {
  return (props: T) => (
    <StoreProvider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <PaketaProvider>
              <Intercom>
                <Router history={RouterUtils.history}>
                  <Component {...props} />
                  <ToastContainer hideProgressBar />
                </Router>
              </Intercom>
            </PaketaProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </StylesProvider>
    </StoreProvider>
  )
}

export default withProviders
