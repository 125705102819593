import React, { FC, useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk'
import { Tenant } from '@base39/ui-utils'
import { useStoreState } from '~hooks/useStore'
import theme from '~constants/theme'
import { FaviconProps } from './interfaces'

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY,
})

const PaketaProvider: FC = ({ children }) => {
  const { user } = useStoreState(({ user }) => user)

  const [isDatadogReady, setIsDatadogReady] = useState(false)

  if (
    process.env.REACT_APP_DATADOG_RUM_APP_ID &&
    process.env.REACT_APP_DATADOG_RUM_TOKEN &&
    !isDatadogReady
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_TOKEN,
      site: 'datadoghq.com',
      service: 'cockpit',
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_SHORT_SHA,
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [
        process.env.REACT_APP_API_URL,
        process.env.REACT_APP_SIGN_URL,
      ],
    })

    datadogRum.startSessionReplayRecording()
    datadogRum.onReady(() => {
      setIsDatadogReady(true)
    })
  }

  useEffect(() => {
    if (user) {
      const { id, cpf, name, tenant } = user ?? {}
      datadogRum.setUser({
        id,
        name,
        cpf,
        tenant,
      })
    }
  }, [user])

  const applyBrowserTheme = () => {
    const favicon: FaviconProps | null = document.getElementById('favicon')

    document.title = theme.name
    if (favicon) {
      favicon.href = theme.favicon
    }
  }

  useEffect(() => {
    applyBrowserTheme()
  }, [])

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        ...user,
        attributes: {
          tenant: Tenant.getTenant(),
          application: process.env.REACT_APP_APPLICATION,
        },
      }}
      userId={user?.id}
    >
      {children}
    </OptimizelyProvider>
  )
}

export default PaketaProvider
