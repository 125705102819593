import { Date as DateUtils, Age as AgeUtils } from '@base39/ui-utils'
import i18n from '~i18n'
import { STATUS_AVAILABLE_TO_CHANGE, ALL_LOAN_STATUS } from '~constants/loan'

export type StatusProps = keyof typeof ALL_LOAN_STATUS

interface filterAverbateStatusRuleProps {
  currentStatus: StatusProps
  status: StatusProps[]
  canAverbate: boolean
}

interface getStatusAvailableProps {
  currentStatus: StatusProps
  canAverbate: boolean
}

export const filterAverbateStatusRule = ({
  status,
  canAverbate,
  currentStatus,
}: filterAverbateStatusRuleProps) => {
  if (!status) {
    return []
  }

  return currentStatus === 'IN_ANALYSIS_PAKETA'
    ? status.filter((status) =>
        canAverbate ? status !== 'IN_ANALYSIS_RH' : status !== 'SIGNATURE_PENDING',
      )
    : [...status]
}

export const getStatusAvailable = (data: getStatusAvailableProps) => {
  const { currentStatus, canAverbate } = data || {}

  if (!currentStatus) {
    return []
  }

  return filterAverbateStatusRule({
    currentStatus,
    status: STATUS_AVAILABLE_TO_CHANGE[currentStatus]?.status,
    canAverbate,
  })
}

export const getBirthdayDescription = (birthdayDate: Date | string): string => {
  const formattedBirthdayDate = DateUtils.formatDate(birthdayDate, 'DD/MM/YYYY')
  const age = AgeUtils.getAge(birthdayDate)

  return formattedBirthdayDate
    ? `${formattedBirthdayDate} (${age}) ${i18n.t('general.years')}`
    : i18n.t('general.notInformed')
}

export const getStatusLabel = (status: string) => {
  const label = ALL_LOAN_STATUS[status]
  return label ? i18n.t(label) : status
}

const notEditableStatus = [
  'IN_ANALYSIS_RH',
  'IN_ANALYSIS_FINANCIAL',
  'REJECTED_RH',
  'REJECTED_PAKETA',
  'REJECTED_FINANCIAL',
  'DONE',
  'PAID',
  'CANCELED',
  'SIGNATURE_PENDING',
  'SIGNED',
]

export const editableProposal = (status: string): boolean =>
  !!status && !notEditableStatus.includes(status)
