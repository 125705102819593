import React, { FC, memo, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { TextFieldProps } from '@material-ui/core/TextField'
import i18n from '~i18n'
import { queryClient } from '~commons/react-query'
import { ITag } from '~models/loan'
import LoanApi from '~services/loans'
import toast from '~helpers/toast'
import { Autocomplete } from '~components/dumb/Form/Autocomplete'
import { defaultTagOptions } from './constants'
import { InputStyled, TitleStyled } from './styles'

interface IProps {
  loanId: string
  options?: ITag[]
  defaultValue?: ITag[]
  label?: string
  onChange?: (values: ITag[]) => void
}

interface IResponse extends AxiosResponse {
  id: string
  tags: ITag[]
}

export const Tags: FC<IProps> = ({ loanId, defaultValue, label, onChange, options }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [tags, setTags] = useState<ITag[]>([])

  const handleAutocomplete = (_: any, values: ITag[]) => {
    if (onChange) {
      onChange(values)
      return
    }

    setLoading(true)

    LoanApi.setTags(loanId, values)
      .then(async (response: IResponse) => {
        setTags(response?.tags)
        await queryClient.refetchQueries(['loan', loanId], { active: true, exact: true })
      })
      .catch(() => toast.error(i18n.t('general.errorUnknown')))
      .finally(() => setLoading(false))
  }

  const checkOptionsWithDefaultValue = (option: ITag, value: ITag) =>
    option.key === value.key

  const renderInput = (params: TextFieldProps) => (
    <InputStyled {...params} variant="outlined" label={label} />
  )

  useEffect(() => {
    defaultValue && setTags(defaultValue)
  }, [defaultValue])

  return (
    <div data-testid="tags">
      <TitleStyled>{i18n.t('loan.tagTitle')}</TitleStyled>
      <Autocomplete
        multiple
        disableClearable
        disabled={loading}
        options={options}
        value={tags}
        getOptionSelected={checkOptionsWithDefaultValue}
        onChange={handleAutocomplete}
        getOptionLabel={({ label }: ITag) => label}
        noOptionsText={i18n.t('general.noOptions')}
        renderInput={renderInput}
      />
    </div>
  )
}

Tags.defaultProps = {
  label: '',
  defaultValue: [],
  options: defaultTagOptions,
  onChange: null,
}

export default memo(Tags)
