import React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import MuiInput from '@material-ui/core/Input'
import { withField } from '../Field'
import { InputNumberProps } from './interfaces'

const InputNumber = ({ onChange, ...props }: InputNumberProps) => {
  const handleChange = (values: NumberFormatValues) => {
    const { name, value } = props

    if (value !== values.value) {
      onChange({ target: { name, value: values.value } })
    }
  }

  return <NumberFormat {...props} onValueChange={handleChange} customInput={MuiInput} />
}

InputNumber.defaultProps = {
  isNumericString: true,
  fixedDecimalScale: true,
  allowNegative: false,
  type: 'tel',
}

export { InputNumber }
export default withField(InputNumber)
