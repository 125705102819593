import React from 'react'
import { Grid } from '~components'
import { ContentWrapperStyled, GridStyled, DialogContentStyled } from './styles'

const Content = ({ children }) => {
  return (
    <DialogContentStyled>
      <ContentWrapperStyled>
        <GridStyled container spacing={3}>
          {children}
        </GridStyled>
      </ContentWrapperStyled>
    </DialogContentStyled>
  )
}

const Left = ({ children }) => {
  return (
    <Grid item xs={4}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Grid>
  )
}

const Right = ({ children }) => {
  return (
    <Grid item xs={8}>
      <GridStyled container spacing={2}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </GridStyled>
    </Grid>
  )
}

Content.Left = Left
Content.Right = Right

export default Content
