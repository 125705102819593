import React from 'react'
import withProviders from '~hocs/withProviders'
import Main from '~containers/Main'
import GlobalStyle from '~styles/GlobalStyle'

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Main />
    </>
  )
}

export default withProviders(App)
