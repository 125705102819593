import { Money as MoneyUtils } from '@base39/ui-utils'

interface FormatInstallmentAndValueParams {
  installments: number
  installmentValue: number
}

export const formatInstallmentValue = ({
  installments,
  installmentValue,
}: FormatInstallmentAndValueParams): string =>
  `${installments} x ${MoneyUtils.formatCurrency(installmentValue)}`
