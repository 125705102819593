import { Clipboard as copyToClipboard } from 'ts-clipboard'
import i18n from '../i18n'

const formatValue = (fieldValue: any) => {
  if (!fieldValue) {
    return i18n.t('general.notInformed')
  }

  return fieldValue
}

export { copyToClipboard, formatValue }
