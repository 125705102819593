import React, { memo } from 'react'
import { Object as ObjectUtils } from '@base39/ui-utils'
import { Field } from 'formik'
import { DatePickerProps } from './interfaces'
import { MUIDatePickerStyled } from './styles'

const DatePicker = (props: DatePickerProps) => {
  const { name } = props

  return (
    <Field
      name={name}
      component={({ field, form }) => {
        const currentError = ObjectUtils.get(form.errors, name)
        const touched = ObjectUtils.get(form.touched, name)
        const showError = !!(touched && currentError)
        const helperText = showError ? currentError : null

        return (
          <MUIDatePickerStyled
            name={name}
            value={field.value}
            helperText={helperText}
            error={showError}
            onError={(error) => {
              if (error !== currentError && !currentError) {
                form.setFieldError(name, error)
              }
            }}
            onChange={(date) => form.setFieldValue(name, date)}
            onBlur={field.onBlur}
            {...props}
          />
        )
      }}
    />
  )
}

export default memo(DatePicker)
