import styled from 'styled-components'
import { Grid } from '~components'
import { DialogContent } from '~components/dumb/Dialog'

export const GridStyled = styled(Grid)`
  height: 100%;
`

export const WrapperStyled = styled.div`
  display: flex;
  align-items: flex-end;
`

export const ContentWrapperStyled = styled.div`
  height: 100%;
  padding: 2rem 2rem 0.5rem 2rem;
`

export const DialogContentStyled = styled(DialogContent)`
  padding-top: 0;
`
