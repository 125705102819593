import React, { FC } from 'react'
import { Dialog, DialogProps } from '@material-ui/core'

const classes = {
  scrollPaper: 'scrollPaper',
  paperFullWidth: 'paperFullWidth',
  paperScrollPaper: 'paperScrollPaper',
  paper: 'paper',
}

const DialogExtended: FC<DialogProps> = (props) => (
  <Dialog data-testid="dialog-wrapper" classes={classes} {...props} />
)

export default DialogExtended
