import { useQuery } from 'react-query'
import { PaymentApi, LoanApi } from '~services'
import { formatPayments } from '~helpers/formatData'
import { PaymentsParams } from './interfaces'

const getPayments = async ({ loanId, date }: PaymentsParams) => {
  const [payments, dueBalanceResponse] = await Promise.all([
    PaymentApi.getByLoanId(loanId),
    LoanApi.getLoanDueBalance(loanId, date),
  ])

  return formatPayments({ payments, dueBalanceResponse })
}

export const useGetPayments = ({ loanId, date }: PaymentsParams) => {
  return useQuery(['payments', loanId, date], () => getPayments({ loanId, date }), {
    enabled: !!loanId,
  })
}
