const updateSliderFilter = (filter: number) => {
  const negativeValue =
    filter === -100 ? 0 : `0.${100 - Number(filter.toString()?.replace('-', ''))}`
  const positiveValue = filter === 100 ? 2 : `1.${filter}`
  return filter < 0 ? negativeValue : positiveValue
}

const calcRotation = (rotation: number) =>
  rotation === 360 || rotation === -360 ? 0 : rotation

export default { updateSliderFilter, calcRotation }
