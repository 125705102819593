import { lazy } from 'react'

const Documents = lazy(() => import('~containers/Documents'))
const DeskDocuments = lazy(() => import('~containers/DeskDocuments'))

const path = '/documentos'

const documents = [
  {
    path,
    component: Documents,
    showSidebar: false,
    exact: true,
    menu: false,
  },
  {
    path: `${path}/mesa`,
    component: DeskDocuments,
    showSidebar: false,
    showHeader: false,
    exact: true,
    menu: false,
  },
  {
    path: `${path}/mesa/:loanId`,
    component: DeskDocuments,
    showSidebar: false,
    showHeader: false,
    exact: true,
    menu: false,
  },
]

export default documents
