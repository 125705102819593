import React, { FC, memo } from 'react'
import { Object as ObjectUtils } from '@base39/ui-utils'
import { LoanApi } from '~services'
import FormAddress from '~components/smart/FormAddress'
import { FormProps } from '../interfaces'

const ProofAddress: FC<FormProps> = ({ address, loanId, setLoading, onApprove }) => {
  const initialValues = address ?? {}

  const handleConfirm = (values) => {
    setLoading(true)

    const objValueChanged = ObjectUtils.getObjectChanged({
      initialValues,
      changedValues: values,
    })
    const objHasChanged = !ObjectUtils.isObjectEmpty(objValueChanged)

    if (objHasChanged) {
      LoanApi.setUpdateLoan({ loanId, address: { ...objValueChanged } })
        .then(() => onApprove(objValueChanged))
        .finally(() => {
          setLoading(false)
        })
    } else {
      onApprove(objValueChanged)
    }
  }

  return <FormAddress initialValues={initialValues} onSubmit={handleConfirm} />
}

export default memo(ProofAddress)
