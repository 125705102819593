const base = 'installments'

export const installmentsStatus = {
  PAID: {
    color: 'green',
    label: `${base}.paid`,
    initial: `${base}.paidInitial`,
  },
  UNPAID: {
    color: 'funBlue',
    label: `${base}.unpaid`,
    initial: `${base}.unpaidInitial`,
  },
  EXPIRED: {
    color: 'red',
    label: `${base}.expired`,
    initial: `${base}.expiredInitial`,
  },
  UNCOLLECTIBLE: {
    color: 'clementine',
    label: `${base}.uncollectible`,
    initial: `${base}.uncollectibleInitial`,
  },
}

export const UNCHECKED_STATUS = ['PAID', 'UNCOLLECTIBLE']
