import axios from 'axios'
import qs from 'qs'
import defaultsDeep from 'lodash.defaultsdeep'
import { Tenant } from '@base39/ui-utils'
import { getLanguage } from '~helpers/locale'
import { onResponseError } from './interceptors'
import { CustomAxiosInstance, CustomAxiosRequestConfig } from './interfaces'

const getConfig = () => {
  const token = sessionStorage.getItem('token')

  return {
    headers: {
      Accept: 'application/json',
      'Accept-Language': getLanguage(),
      Authorization: token,
      access_token: token,
      'x-tenant-id': Tenant.getTenant(),
    },
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'indices' })
    },
  }
}

const baseApi = (baseURL: string, config?: CustomAxiosRequestConfig) => {
  const axiosApi = axios.create({
    baseURL,
    ...config,
  }) as CustomAxiosInstance

  axiosApi.customRequest = async (path, options) => {
    const mergedOptions = defaultsDeep(options, await getConfig())

    return axiosApi(path, mergedOptions).then((resp) => resp.data)
  }

  axiosApi.interceptors.response.use(null, onResponseError)

  return axiosApi
}

export default baseApi
