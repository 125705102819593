import {
  TitleStyled as Title,
  HighlightStyled as Highlight,
  ValueBiggestStyled as ValueBiggest,
  FieldStyled as Field,
  ValueStyled as Value,
  ParagraphStyled as Paragraph,
} from './styles'

export { Title, Highlight, ValueBiggest, Field, Value, Paragraph }

export default {
  Title,
  Highlight,
  ValueBiggest,
  Field,
  Value,
  Paragraph,
}
