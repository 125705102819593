import React from 'react'
import { useParams } from 'react-router'
import i18n from '~i18n'
import { useRequest } from '~hooks'
import CompaniesApi from '~services/companies'
import { useGetLoanById } from '~services/hooks'
import { ContentItemAverbation } from '..'
import { RhUsersResponse } from './interfaces'
import { TextStyled, ListStyled } from './styles'

const AverbationDialogContent: React.FC = () => {
  const { loanId } = useParams<{ loanId: string }>()

  const { data: loan } = useGetLoanById(loanId)
  const { data, loading } = useRequest<RhUsersResponse[]>(
    () => CompaniesApi.getRhUsers(loan?.company?.ref),
    {
      ready: !!loan?.company?.ref,
      cacheKey: 'rhEmails',
    },
  )

  return (
    <div>
      <TextStyled>{i18n.t('proposal.confirmApproveInAnalysisPaketa')}</TextStyled>
      <TextStyled>{i18n.t('proposal.emailsRh')}</TextStyled>
      <ListStyled>
        {data &&
          !loading &&
          data.map(
            (rhUser) =>
              rhUser?.email && (
                <ContentItemAverbation key={rhUser?.id} email={rhUser?.email} />
              ),
          )}
      </ListStyled>
    </div>
  )
}

export default AverbationDialogContent
