import React from 'react'
import {
  Money as MoneyUtils,
  Date as DateUtils,
  Router as RouterUtils,
} from '@base39/ui-utils'
import i18n from '~i18n'
import EditButton from '~components/dumb/EditButton'
import {
  CreditPolicies,
  MonthlyAndRegisterFee,
  CreditPolicyRevisions,
} from '~types/creditPolicy'

import {
  FormattedCreditPolicy,
  FormattedMonthlyAndRegisterFee,
  FormattedCreditPolicies,
  FormattedCreditPolicyRevisions,
  FormatCreditPolicyProps,
} from './interfaces'

export const formatMonthlyAndRegisterFee = (
  monthlyAndRegisterFee: MonthlyAndRegisterFee[],
): FormattedMonthlyAndRegisterFee[] => {
  return monthlyAndRegisterFee?.map?.(
    ({ monthlyFee, registerFee, insuranceType, maxMonth, minMonth }) => {
      return {
        installments: `${minMonth} ~ ${maxMonth} ${i18n.t('loan.installments')}`,
        monthlyFee: `${i18n.t('loan.monthlyFeeDescription')}: ${monthlyFee}%`,
        registerFee: `${i18n.t('loan.registerFee')}: ${MoneyUtils.formatCurrency(
          registerFee,
        )}`,
        insuranceType: `${i18n.t('loan.insurance')}: ${i18n.t(
          `proposal.insurance.${insuranceType}`,
        )}`,
      }
    },
  )
}

export const formatCreditPolicy = (
  creditPolicy: FormatCreditPolicyProps,
): FormattedCreditPolicy => ({
  ...creditPolicy,
  formatted: {
    ...creditPolicy?.rules?.[0],
    monthlyAndRegisterFee: creditPolicy?.rules?.flatMap?.(({ monthlyAndRegisterFee }) =>
      formatMonthlyAndRegisterFee(monthlyAndRegisterFee),
    ),
    minCredit: MoneyUtils.formatCurrency(creditPolicy?.rules?.[0]?.minCreditRequest),
  },
})

export const renderEditButton = (id: string) => {
  return <EditButton onClick={() => RouterUtils.push(`/politicas-credito/${id}`)} />
}

export const formatCreditPolicies = (
  creditPolicies: CreditPolicies,
): FormattedCreditPolicies => ({
  ...creditPolicies,
  rows: creditPolicies.rows.map((row) => ({
    ...row,
    formattedType: i18n.t(`creditPolicy.policyTypes.${row.type}`),
    formattedUpdatedAt: DateUtils.formatDate(row.updatedAt, 'L HH:mm'),
    actions: renderEditButton(row.id),
  })),
})

export const formatCreditRevisions = (
  revisions: CreditPolicyRevisions,
): FormattedCreditPolicyRevisions => ({
  ...revisions,
  rows: revisions.rows.map((row) => ({
    ...row,
    formattedCreatedAt: DateUtils.formatDate(row.createdAt, 'L HH:mm'),
  })),
})
