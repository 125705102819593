import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      staleTime: 60_000,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})
