export const defaultTagOptions = [
  { label: '1.0 - Em análise pela Paketa', key: 'under_review_paketa' },
  { label: '1.1 - Triagem', key: 'screening' },
  { label: '1.2 - Triagem D+1', key: 'screening_d_1' },
  { label: '1.3 - Triagem D+2', key: 'screening_d_2' },
  { label: '1.4 - Triagem D+3', key: 'screening_d_3' },
  { label: '2 - Gestão Triagem', key: 'management_screening' },
  { label: '3 - Análise PF sem SPC', key: 'pf_analysis_without_spc' },
  { label: '4 - Análise de Crédito PF', key: 'credit_analysis_pf' },
  {
    label: '5 - Quitações de Dívidas e Refinanciamento',
    key: 'debt_discharges_refinancing',
  },
  { label: '6 - Validação da Gestão', key: 'management_validation' },
  { label: '7 - Validar Zerar Margem', key: 'validate_reset_margin' },
  { label: '7.1 - Recusado - Zerar Margem', key: 'refused_reset_margin' },
  { label: '8 - Análise de Risco do Fundo', key: 'fund_risk_analysis' },
  { label: '9 - Gestão Averbação', key: 'management_endorsement' },
  { label: '10 - Gestão CCB', key: ' management_ccb' },
  { label: '11 - Assinado sem CCB', key: 'signed_without_ccb' },
  {
    label: '12.1 - Aguardando comprovante bancário D+1',
    key: 'keeping_bank_receipt_d_1',
  },
  {
    label: '12.2 - Aguardando comprovante bancário D+2',
    key: 'keeping_bank_receipt_d_2',
  },
  {
    label: '12.3 - Enviar comprovante bancário para Financeira',
    key: 'send_bank_receipt_finance',
  },
  { label: '12.4 - Gestão comprovante bancário', key: 'bank_receipt_management' },
  {
    label: '13 - Cockpit não mudou para Ajustes solicitados pela financeira',
    key: 'cockpit_not_changed_adjustments_requested_financial',
  },
  {
    label: '14 - Não alterou para Em análise pela financeira',
    key: 'not_change_under_review_financial',
  },
  { label: '15 - Cockpit não mudou para Paga', key: 'cockpit_not_changed_paid' },
  { label: '16 - Cockpit não mudou para Quitada', key: 'cockpit_not_changed_settled' },
  { label: '17 - Solicitação de Cancelamento', key: 'cancellation_request' },
]
