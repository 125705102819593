import styled from 'styled-components'

export const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background.main};
`

export const ContentStyled = styled.div`
  flex: 1 1 0%;
  position: relative;
  width: 85%;
`

export const LayoutStyled = styled.div`
  background: ${({ theme }) => theme.palette.white[400]};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const CheckListStyled = styled.div`
  width: 15%;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const GridStyled = styled.div`
  display: flex;
  flex: 1 1 0%;
`
