import styled from 'styled-components'
import { Button } from '~components'
import { ValueBiggest } from '~components/dumb/Text'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  svg {
    width: 48px;
    height: 48px;
    path {
      fill: ${({ theme }) => theme.palette.gray[550]};
    }
  }
`

export const TitleStyled = styled(ValueBiggest)`
  color: ${({ theme }) => theme.palette.gray[550]};
  max-width: 300px;
  font-size: 0.875rem;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  font-family: 'Montserrat Regular';
`

export const IconContentStyled = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
`

export const ButtonStyled = styled(Button)`
  width: 183px;
  height: 38px;
  border-radius: 4px;
  align-items: center;
`
