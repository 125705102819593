import i18n from '~i18n'
import {
  IconArchive,
  IconMail,
  IconHome,
  MoneyIconStyled,
  SettingsIconStyled,
} from './styles'

const Menu = [
  {
    path: '/propostas',
    icon: IconHome,
    tooltip: i18n.t('header.home'),
    label: i18n.t('header.home'),
  },
  {
    path: '/visualizacoes',
    icon: IconArchive,
    tooltip: i18n.t('header.visualizeProposals'),
    label: i18n.t('header.proposals'),
  },
  {
    path: '/documentos',
    icon: IconMail,
    tooltip: i18n.t('header.documentsDesk'),
    label: i18n.t('header.desk'),
  },
  {
    path: '/simulador',
    icon: MoneyIconStyled,
    tooltip: i18n.t('header.simulator'),
    label: i18n.t('header.simulator'),
  },
  {
    path: '/politicas-credito',
    icon: SettingsIconStyled,
    tooltip: i18n.t('header.backoffice'),
    label: i18n.t('header.backoffice'),
    featureFlag: 'ckpt_backoffice',
  },
]

export default Menu
