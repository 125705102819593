import React, { memo, useState, useEffect, MouseEvent, useMemo } from 'react'
import { useParams } from 'react-router'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import i18n from '~i18n'
import { useStoreState, useStoreActions } from '~hooks'
import LoanApi from '~services/loans'
import toast from '~helpers/toast'
import { getStatusAvailable, getStatusLabel, StatusProps } from '~helpers/loan'
import { useGetLoanById } from '~services/hooks'
import { Note } from '~models/loan'
import { STATUS_ASYNC } from '~commons/constants/loan'
import { LoanParams } from './interfaces'
import { ListMenuStatusOptions, DialogConfimation, TypeStatus } from './sections'
import {
  ActionsStyled,
  ButtonStatusStyled,
  ButtonChoiceStyled,
  GroupButtonStyled,
  SendAsLabelStyled,
  PopoverStyled,
} from './styles'

const LoanActions = ({ status }) => {
  const loanPendingStatus = useStoreState((state) => state.loans.pendingStatus)
  const { setPendingStatus, removePendingStatus } = useStoreActions(
    (action) => action.loans,
  )
  const [showDialog, setShowDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedStatusOption, setSelectedStatusOption] = useState('')
  const { loanId } = useParams<LoanParams>()
  const [newStatus, setNewStatus] = useState<StatusProps>(null as StatusProps)
  const { data: loan, isFetching: loadingLoan, refetch } = useGetLoanById(loanId)
  const [loadingAsyncStatus, setLoadingAsyncStatus] = useState(false)

  const canAverbate = useMemo(
    () => loan?.optionalDismiss?.averbation && status === 'IN_ANALYSIS_PAKETA',
    [loan?.optionalDismiss, status],
  )

  const allStatus = useMemo(
    () =>
      getStatusAvailable({
        currentStatus: newStatus,
        canAverbate,
      }),
    [newStatus, canAverbate],
  )

  useEffect(() => {
    setNewStatus(status)
  }, [status])

  useEffect(() => {
    if (loanPendingStatus.includes(loanId) && loan?.status === 'IN_ANALYSIS_PAKETA') {
      setLoadingAsyncStatus(true)
    } else if (
      loanPendingStatus.includes(loanId) &&
      loan?.status === 'SIGNATURE_PENDING'
    ) {
      removePendingStatus(loanId)
    }
  }, [loan?.status, loanPendingStatus, loanId, removePendingStatus])

  const isMoneyPlusAndSignedProposal = useMemo(
    () => !!loan?.moneyplus?.proposal && status === 'SIGNED',
    [loan?.moneyplus, status],
  )

  const isLoanRejectedOrCanceled = useMemo(
    () => ['REJECTED_PAKETA', 'CANCELED'].includes(status),
    [status],
  )

  const rulesToDisableChoicesButton = useMemo(
    () =>
      isLoanRejectedOrCanceled ||
      isMoneyPlusAndSignedProposal ||
      !allStatus?.length ||
      loadingLoan ||
      loadingAsyncStatus,
    [
      isLoanRejectedOrCanceled,
      isMoneyPlusAndSignedProposal,
      allStatus,
      loadingLoan,
      loadingAsyncStatus,
    ],
  )

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const alertAsyncStatus = () => {
    const title = i18n.t('loan.await')
    const description = i18n.t('loan.statusProcess')
    toast.warn(title, description)
  }

  const toastUpdatedStatus = () => {
    const title = i18n.t('loan.success')
    const description = i18n.t('loan.statusChanged')
    toast.success(title, description)
  }

  const handleError = () => {
    toast.error(i18n.t('loan.errorStatusChange'))
  }

  const updateLoanStatus = (status: string, note?: Note) => {
    LoanApi.setLoanStatus({ id: loanId, status, note })
      .then(() => {
        if (STATUS_ASYNC.includes(status)) {
          alertAsyncStatus()
          setLoadingAsyncStatus(true)
          setPendingStatus(loanId)
        } else {
          toastUpdatedStatus()
        }

        refetch()
      })
      .catch(handleError)
  }

  const handleSendStatus = (statusOption: StatusProps) => {
    setSelectedStatusOption(statusOption)

    const defaultAction = () => {
      updateLoanStatus(statusOption)
      handleClose()
    }

    const statusActions = {
      REJECTED_PAKETA: () => setShowDialog(true),
      IN_ANALYSIS_RH: () => setShowDialog(true),
    }

    const statusAction = statusActions[statusOption]

    if (statusAction) {
      statusAction()
    } else {
      defaultAction()
    }
  }

  const handleConfirm = (note) => {
    updateLoanStatus(selectedStatusOption, note?.values)
    setShowDialog(false)
  }

  return (
    <ActionsStyled>
      {showDialog && (
        <DialogConfimation
          open={showDialog}
          loading={loadingLoan}
          onClose={handleCloseDialog}
          onConfirm={handleConfirm}
          type={selectedStatusOption as TypeStatus}
        />
      )}
      <GroupButtonStyled>
        <ButtonChoiceStyled
          disabled={rulesToDisableChoicesButton}
          isLoading={loadingAsyncStatus}
        >
          {getStatusLabel(newStatus)}
          <SendAsLabelStyled>{i18n.t('loan.sendAs')}</SendAsLabelStyled>
        </ButtonChoiceStyled>
        <ButtonStatusStyled
          aria-controls="menu-options"
          aria-haspopup="true"
          onClick={handleClick}
          data-testid="menu-options"
          disabled={rulesToDisableChoicesButton}
        >
          <ExpandMoreIcon />
        </ButtonStatusStyled>
        <PopoverStyled
          id={'menu-options'}
          classes={{ paper: 'paperStyled' }}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={Boolean(anchorEl)}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <ListMenuStatusOptions onClickOption={handleSendStatus} allStatus={allStatus} />
        </PopoverStyled>
      </GroupButtonStyled>
    </ActionsStyled>
  )
}

export default memo(LoanActions)
