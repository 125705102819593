import React, { FC, useState, useEffect, memo } from 'react'
import { TextFieldProps } from '@material-ui/core/TextField'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useRequest } from '~hooks'
import i18n from '~i18n'
import { IAssign } from '~models/loan'
import { IUser } from '~models/user'
import LoanApi from '~services/loans'
import UserApi from '~services/users'
import { queryClient } from '~commons/react-query'
import { useStoreActions, useStoreState } from '~hooks/useStore'
import toast from '~helpers/toast'
import { Autocomplete } from '~components/dumb/Form/Autocomplete'
import { AssignProps, AssignUser } from './interfaces'
import { InputStyled, WrapperStyled, TitleStyled } from './styles'

export const Assign: FC<AssignProps> = ({ assign, companyId, loanId }) => {
  const { setAdminsList } = useStoreActions(({ user }) => user)
  const { adminsList } = useStoreState<{ adminsList: IUser[] }>(
    (state) => state.user,
    (prev, next) => prev.adminsList === next.adminsList,
  )
  const [requestLoading, setRequestLoading] = useState<boolean>(false)
  const [value, setValue] = useState([])

  const { data: assignedUser, loading: loadingAssignedUser } = useRequest<AssignUser>(
    () => UserApi.get(assign?.user),
    {
      ready: !!assign?.user,
    },
  )

  const { loading } = useRequest<IUser[]>(UserApi.getAdminUsers, {
    ready: !adminsList.length,
    onSuccess: (data) => {
      setAdminsList(data)
    },
    refreshDeps: [companyId],
  })

  useEffect(() => {
    if (assignedUser?.name) {
      setValue([{ label: assignedUser?.name, key: assignedUser?.id }])
    }
  }, [assignedUser])

  const handleAutocomplete = (_: any, values: IAssign[]) => {
    const alreadyHasValue = values.length > 1
    const newValue = alreadyHasValue ? values[1] : values[0]
    const requestData = { user: newValue?.key || '', group: '', id: loanId }

    if (!alreadyHasValue) setValue(values)
    else {
      setValue([newValue])
    }

    setRequestLoading(true)

    LoanApi.setAssign(loanId, requestData)
      .then(
        async () =>
          await queryClient.refetchQueries(['loan', loanId], {
            active: true,
            exact: true,
          }),
      )
      .catch(() => toast.error(i18n.t('general.errorUnknown')))
      .finally(() => setRequestLoading(false))
  }

  const options =
    adminsList
      ?.map?.((item) => ({ label: item.name, key: item.id }))
      ?.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []

  const checkOptionsWithDefaultValue = (option: IAssign, value: IAssign) =>
    option.key === value.key

  const isSelected = value.length === 1

  const renderInput = (params: TextFieldProps) => (
    <InputStyled {...params} variant="outlined" />
  )

  return (
    <WrapperStyled data-testid="assign">
      <TitleStyled>{i18n.t('loan.assignTitle')}</TitleStyled>
      <Autocomplete
        multiple
        disableClearable
        hideInput={isSelected}
        chipIcon={<AccountCircle />}
        disabled={loading || loadingAssignedUser || requestLoading}
        options={options}
        value={value}
        getOptionSelected={checkOptionsWithDefaultValue}
        onChange={handleAutocomplete}
        getOptionLabel={({ label }: IAssign) => label}
        noOptionsText={i18n.t('general.noOptions')}
        renderInput={renderInput}
      />
    </WrapperStyled>
  )
}

export default memo(Assign)
