import React, { FC, memo } from 'react'
import { ArrowBackProps } from './interfaces'
import { ContentStyled, ContainedIconStyled } from './style'

const ArrowBack: FC<ArrowBackProps> = ({ onClick }) => {
  return (
    <ContentStyled onClick={onClick}>
      <ContainedIconStyled />
    </ContentStyled>
  )
}

export default memo(ArrowBack)
