import styled from 'styled-components'

export const MenuStyled = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.white[0]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  display: flex;
  height: 32px;
  overflow: hidden;
  margin-right: 1rem;
  margin-top: 0.75rem;
  border-right: 0;
`

export const MenuContainerStyled = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`
