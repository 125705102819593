import React, { MouseEventHandler, FC } from 'react'
import { DialogHeader } from '~components/dumb/Dialog'

interface IProps {
  onClose: MouseEventHandler
  title: string
}

const Header: FC<IProps> = ({ onClose: handleClose, title }) => {
  return <DialogHeader onClose={handleClose}>{title}</DialogHeader>
}

export default Header
