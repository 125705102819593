import React, { memo } from 'react'
import { getFieldProps, areEqual } from '../Field/utils'
import FormHelperText from '../FormHelperText'
import LabelInfo from '../LabelInfo'
import { MuiFormControlStyled, InputLabelStyled } from './style'
import { FormControlProps } from './interfaces'

const FormControl = ({ customComponent: Input, ...props }: FormControlProps) => {
  const {
    id,
    label,
    helperText,
    fullWidth,
    required,
    error,
    margin,
    shrink,
    className,
    tooltip,
    name,
    ...fieldProps
  } = getFieldProps(props)
  const fieldIdentifier = id || name
  const labelledby = `${fieldIdentifier}-label`
  const describedby = `${fieldIdentifier}-helperText`

  return (
    <MuiFormControlStyled
      className={className}
      variant="standard"
      error={error}
      fullWidth={fullWidth}
      required={required}
      margin={margin}
    >
      {label && (
        <InputLabelStyled id={labelledby} shrink={shrink} htmlFor={fieldIdentifier}>
          <LabelInfo label={label} tooltip={tooltip} />
        </InputLabelStyled>
      )}
      <Input
        {...fieldProps}
        id={fieldIdentifier}
        name={name}
        shrink={shrink}
        required={required}
        error={error}
        aria-labelledby={labelledby}
        aria-describedby={describedby}
        inputProps={{
          'aria-labelledby': labelledby,
          'aria-describedby': describedby,
          ...fieldProps?.inputProps,
        }}
      />
      {helperText && (
        <FormHelperText id={describedby} aria-labelledby={labelledby} role="alert">
          {helperText}
        </FormHelperText>
      )}
    </MuiFormControlStyled>
  )
}

FormControl.defaultProps = {
  margin: 'normal',
  required: false,
}

export { MuiFormControlStyled as FormControl }
export default memo(FormControl, areEqual)
