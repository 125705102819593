import React, { FC, memo, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useMutation } from 'react-query'
import { Formik } from 'formik'
import { AddressApi } from '~services'
import { Grid, Input, Checkbox, InputMask } from '~components'
import i18n from '~commons/i18n'
import InputNumber from '~components/dumb/Form/InputNumber'
import Select from '~components/dumb/Form/Select'
import federativeUnits from '~constants/federativeUnits'
import { useStoreState } from '~commons/hooks'
import { addressValidationSchema } from '../DocumentModal/DocumentForm/Forms/model'
import { formatAddress } from '../DocumentModal/DocumentForm/Forms/utils'
import { AddressForm } from './interfaces'

const FormAddress: FC<AddressForm> = ({ initialValues, onSubmit, children }) => {
  const formikRef = useStoreState((state) => state.deskDocuments.formikRef)
  const [noNumber, setNoNumber] = useState(initialValues.number === 'S/N')

  const [formControl, setFormControl] = useState({
    cepInvalid: false,
    disabled: {
      zipCode: false,
      number: !initialValues.number,
      complement: true,
      address: true,
      city: true,
      neighborhood: true,
      state: true,
    },
  })
  const InputAddressNumber = noNumber ? Input : InputNumber
  const handleNoNumber = () => {
    setNoNumber(!noNumber)
  }

  useEffect(() => {
    return () => {
      setFormControl({
        cepInvalid: false,
        disabled: {
          zipCode: false,
          number: true,
          complement: true,
          address: true,
          city: true,
          neighborhood: true,
          state: true,
        },
      })
    }
  }, [])

  const { mutate: getAddressByZipCode, isLoading } = useMutation(
    (zipCode: string) => AddressApi.getAddressByZipCode(zipCode),
    {
      onSuccess: (response) => {
        if (!response?.zipCode) {
          setFormControl((prevState) => ({
            ...prevState,
            cepInvalid: true,
          }))
          formikRef?.current?.setTouched({
            zipCode: true,
          })
        } else {
          const values = formatAddress(response)

          setFormControl((prevState) => ({
            ...prevState,
            cepInvalid: false,
            disabled: {
              ...prevState.disabled,
              number: false,
              complement: false,
              address: !!values.address,
              city: !!values.city,
              neighborhood: !!values.neighborhood,
              state: !!values.state,
            },
          }))

          formikRef?.current?.setValues(values)
        }
      },
      onError: () => {
        setFormControl((prevState) => ({
          ...prevState,
          cepInvalid: true,
        }))
        formikRef?.current?.setTouched({
          zipCode: true,
        })
      },
    },
  )

  const fetchAddressByZipCode = useRef(debounce(getAddressByZipCode, 500)).current
  const handleZipCodeChange = (ev, handleChange) => {
    handleChange(ev)
    const {
      target: { value: zipCode },
    } = ev

    if (zipCode.length === 8) {
      fetchAddressByZipCode(zipCode)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressValidationSchema}
      onSubmit={onSubmit}
      validate={() => {
        if (formControl.cepInvalid) {
          return {
            zipCode: 'form.zipCodeInvalid',
          }
        }
      }}
      innerRef={formikRef}
    >
      {({ handleSubmit, setFieldValue, handleChange }) => (
        <form
          onSubmit={handleSubmit}
          id="PROOF_OF_ADDRESS-form"
          data-testid="PROOF_OF_ADDRESS"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputMask
                name="zipCode"
                label={i18n.t('user.zipCode')}
                fullWidth
                isLoading={isLoading}
                disabled={isLoading}
                mask="zipCode"
                type="tel"
                onChange={(ev) => handleZipCodeChange(ev, handleChange)}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="state"
                label={i18n.t('user.state')}
                margin="none"
                fullWidth
                disabled={isLoading || formControl.disabled.state}
              >
                <option value="" />
                {federativeUnits.map(({ value, label }) => (
                  <option value={value} key={`state-option-${value}`}>
                    {label}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Input
                name="address"
                label={i18n.t('user.address')}
                fullWidth
                disabled={isLoading || formControl.disabled.address}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAddressNumber
                name="number"
                label={i18n.t('user.number')}
                fullWidth
                disabled={noNumber || isLoading || formControl.disabled.number}
              />
              <Checkbox
                name="number-checkbox"
                onClick={() => {
                  handleNoNumber()
                  setFieldValue('number', noNumber ? '' : 'S/N')
                }}
                value={noNumber}
                label={i18n.t('general.notHave')}
                color="primary"
                checked={noNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="complement"
                label={i18n.t('user.complement')}
                fullWidth
                disabled={isLoading || formControl.disabled.complement}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="neighborhood"
                label={i18n.t('user.neighborhood')}
                fullWidth
                disabled={isLoading || formControl.disabled.neighborhood}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="city"
                label={i18n.t('user.city')}
                fullWidth
                disabled={isLoading || formControl.disabled.city}
              />
            </Grid>
          </Grid>
          {children}
        </form>
      )}
    </Formik>
  )
}

export default memo(FormAddress)
