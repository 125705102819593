import React, { memo, FC } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { ColoredSquareProps } from './interfaces'
import { SquareStyled } from './styles'

export enum Colors {
  clementine = '#EC7000',
  brightSun = '#FFC542',
  goldenFizz = '#F7EF35',
  malibu = '#7AADFF',
  dodgerBlue = '#3D87FF',
  resolutionBlue = '#003385',
  carnation = '#FC5A5A',
  mediumPurple = '#A461D8',
  cobalt = '#004AC2',
  tradewind = '#6DC3A8',
  plantation = '#275C3E',
  lynch = '#77869E',
  red = '#FC5A5A',
  green = '#00B894',
}

export enum Types {
  tiny = 'tiny',
  square = 'square',
  large = 'large',
}

const ColoredSquare: FC<ColoredSquareProps> = (props) => {
  const { initial, tooltip, color, type, ...rest } = props

  return (
    <Tooltip role="tooltip" title={tooltip}>
      <SquareStyled type={type} color={Colors[color]} {...rest}>
        {initial}
      </SquareStyled>
    </Tooltip>
  )
}

export default memo(ColoredSquare)
