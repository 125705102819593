import { lazy } from 'react'

const Home = lazy(() => import('~containers/Home'))

const path = '/propostas'

const home = [
  {
    path,
    component: Home,
    exact: true,
    menu: false,
  },
]

export default home
