import user, { UserModel } from './user'
import tabs, { TabsModel } from './tabs'
import loans, { LoansModel } from './loan'
import deskDocuments, { DeskDocumentsModel } from './deskDocuments'

export interface StoreModel {
  user: UserModel
  tabs: TabsModel
  deskDocuments: DeskDocumentsModel
  loans: LoansModel
}

export interface InstallmentsModel {
  id: string
  allowed: boolean
  message: string
  errorValue: number
  totalValue: number
  installments: number
  installmentValue: string
  iofValue: string
  registerFee: string
  monthlyCET: string
  monthlyFee: string
  yearlyCET: string
  yearlyFee: string
}

export interface TableHeaderModel {
  label: string
  value: string
  align: string
  bold: boolean
  noSortable: boolean
}

const model: StoreModel = {
  user,
  tabs,
  deskDocuments,
  loans,
}

export default model
