import React, { memo } from 'react'
import { Tooltip } from '~components'
import Feature from '~components/dumb/Feature'
import { NavigationProps } from './interfaces'
import { NavLinkStyled, NavTextStyled } from './styles'

const Navigation: React.FC<NavigationProps> = ({
  path,
  icon: Icon,
  children,
  tooltip,
  label,
  featureFlag,
}) => {
  return (
    <Feature feature={featureFlag}>
      <Tooltip title={tooltip}>
        <NavLinkStyled to={path} activeClassName="selected" data-testid="header-link">
          <Icon />
          <NavTextStyled data-testid="header-label">{label}</NavTextStyled>
          {children}
        </NavLinkStyled>
      </Tooltip>
    </Feature>
  )
}

export default memo(Navigation)
