import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: '1em',
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  }
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  bootstrapPopper: arrowGenerator(theme.palette.black[100]),
  bootstrapTooltip: {
    backgroundColor: theme.palette.black[100],
  },
  bootstrapPlacementLeft: {
    marginRight: 0,
  },
  bootstrapPlacementRight: {
    marginLeft: 0,
  },
  bootstrapPlacementTop: {
    marginBottom: 0,
  },
  bootstrapPlacementBottom: {
    marginTop: '0.5em',
  },
})

class CustomizedTooltips extends React.Component {
  state = {
    arrowRef: null,
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    })
  }

  render() {
    const { classes, children, title, placement } = this.props
    if (!title) return children
    return (
      <Tooltip
        placement={placement}
        title={
          <React.Fragment>
            {title}
            <span className={classes.arrow} ref={this.handleArrowRef} />
          </React.Fragment>
        }
        classes={{
          tooltip: classes.bootstrapTooltip,
          popper: classes.bootstrapPopper,
          tooltipPlacementLeft: classes.bootstrapPlacementLeft,
          tooltipPlacementRight: classes.bootstrapPlacementRight,
          tooltipPlacementTop: classes.bootstrapPlacementTop,
          tooltipPlacementBottom: classes.bootstrapPlacementBottom,
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef,
              },
            },
          },
        }}
      >
        {children}
      </Tooltip>
    )
  }
}

CustomizedTooltips.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  placement: PropTypes.string,
}

export default withStyles(styles)(CustomizedTooltips)
