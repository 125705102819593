import React from 'react'
import { OptimizelyFeature } from '@optimizely/react-sdk'
import { FeatureProps } from './interfaces'

const Feature = ({
  children,
  feature = null,
  overrideAttributes,
  onDeactivated,
}: FeatureProps) => {
  if (!feature) {
    return <>{children}</>
  }

  return (
    <OptimizelyFeature feature={feature} overrideAttributes={overrideAttributes}>
      {(isEnabled, variables) => {
        if (onDeactivated && !isEnabled) {
          onDeactivated()
        }
        return (
          isEnabled && (
            <>
              {!!variables && children && children instanceof Function
                ? children(variables)
                : children}
            </>
          )
        )
      }}
    </OptimizelyFeature>
  )
}

export default Feature
