import { Action, action } from 'easy-peasy'
import { Personal, Bank, Address, CauseOfRejection } from '~types'
import { Metadata } from '~types/metadata'
import { IUser } from './user'
import { IPayment } from './payment'
import { ICompany } from './company'
import { IProposal } from './proposal'

export interface ITag {
  key: string
  label: string
}

export interface IAssign {
  key: string
  label: string
}

export interface ISplit {
  beneficiaryCnpjCpf: string
  beneficiaryName: string
  expiresAt: string
  invalid: boolean
  line: string
  value: number
  _id: string
}

export interface IOptionalDismiss {
  averbation?: boolean
  accept?: boolean
  ccb?: boolean
}

export interface Log {
  createdAt?: string
  document?: {
    loan?: string
    user?: string
    doc?: {
      loan?: ILoan
      note?: string
      causeRejection?: CauseOfRejection
    }
  }
  id?: string
  id_str?: string
  message?: string
  type?: string
  updatedAt?: string | Date
  visibility?: string
  user?: IUser
}

export interface LogsData {
  result?: Log[]
}

type Moneyplus = {
  proposal: string
}

export interface ILoan {
  id?: string
  total?: string
  loading?: boolean
  value: number
  status?: string
  note?: Note
  documents?: string[]
  logs?: LogsData
  user?: IUser
  ccb?: number
  address?: Address
  personal?: Personal
  bank?: Bank
  acquittance?: boolean
  optionalDismiss?: IOptionalDismiss
  company?: ICompany
  proposal?: IProposal
  payments?: IPayment[]
  paymentDate?: string | Date
  requestDate?: string | Date
  tags?: ITag[]
  formatted?: ILoanFormatted
  split?: ISplit[]
  editable?: boolean
  creditDocuments?: string[]
  assign?: { user?: IUser }
  createdAt?: string
  updatedAt?: string | Date
  maxCredit?: number
  maxInstallmentValue?: number
  moneyplus?: Moneyplus
  metadata?: Metadata
  tenant: string
  hiredAt?: string
}

export interface Note {
  comment?: string
  causeRejection?: string
}

export interface NoteData {
  note?: string
  causeRejection?: string
}

export interface ILoanFormatted {
  company?: any
  user?: any
  proposal?: any
  requestDate?: string
  paymentDate?: string
  value?: string
  maxCredit?: string
  maxInstallmentValue?: string
}

export interface ILoansData {
  rows: ILoan[]
  total: number
  totalPages: number
  pageSize: number | string
  page: number | string
  sort: string
}

export interface LoansModel {
  pendingStatus: string[]
  setPendingStatus: Action<LoansModel, string>
  removePendingStatus: Action<LoansModel, string>
}

const loans: LoansModel = {
  pendingStatus: [],
  setPendingStatus: action((state, payload) => {
    state.pendingStatus.push(payload)
  }),
  removePendingStatus: action((state, payload) => {
    const pendingStatusFiltered = state.pendingStatus.filter(
      (statusId) => statusId !== payload,
    )
    state.pendingStatus = pendingStatusFiltered
  }),
}

export default loans
