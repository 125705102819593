import { Money as MoneyUtils } from '@base39/ui-utils'
import { MaxCredit } from '~types'
import { MaxCreditFormatted } from './interfaces'

export const formatMaxCredit = (data: MaxCredit): MaxCreditFormatted => ({
  ...data,
  formatted: {
    value: MoneyUtils.formatCurrency(data.maxCredit),
  },
})
