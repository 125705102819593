import styled from 'styled-components'
import { Chip } from '@material-ui/core'

export const ChipOptionStyled = styled(Chip)`
  border-radius: 6px;
  width: 30px;
  height: 24px;
  font-family: 'Montserrat Medium';
  font-size: 0.625rem;

  .label {
    padding: ${({ theme }) => theme.spacing(0.5)}px;
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.gray[400]};
    color: ${({ theme }) => theme.palette.gray[700]};
  }
`
