import React, { memo } from 'react'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { ChipStyled } from './styles'
import { AgeChipProps } from './interfaces'

const AgeChip = ({ age }: AgeChipProps) => {
  if (age > 0 && age < 18) {
    return (
      <ChipStyled
        icon={<RemoveIcon data-testid="remove" />}
        label={18}
        data-testid="age-chip"
      />
    )
  }

  if (age > 60) {
    return (
      <ChipStyled
        icon={<AddIcon data-testid="add" />}
        label={60}
        data-testid="age-chip"
      />
    )
  }

  return null
}

export default memo(AgeChip)
