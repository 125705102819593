import styled, { css } from 'styled-components'
import { DocumentImageStyledProps } from '../../interfaces'

export const DocumentImageStyled = styled.img<DocumentImageStyledProps>`
  width: ${({ zoom }) => (zoom ? `${zoom}%` : '100%')};
  border-radius: 4px;

  ${({ zoomOutMap, horizontal, wrapHeight }) =>
    zoomOutMap &&
    css`
      width: ${horizontal ? `${wrapHeight}px` : 'auto'};
      height: ${horizontal ? 'auto' : '100%'};
    `}

  ${({ brightness, contrast, rotate, horizontal, imgHeight }) =>
    css`
      filter: brightness(${brightness}) contrast(${contrast});
      transform: translate(${horizontal ? `0, -${imgHeight}px` : '0, 0'})
        rotate(${rotate}deg);
      transform-origin: ${rotate === 180 || rotate === -180 ? 'center' : 'bottom left'};
    `};

  ${({ rotate, imgHeight, zoomOutMap }) =>
    (rotate === 270 || rotate === -90) &&
    css`
      transform: ${zoomOutMap
        ? `translate(${imgHeight}px, calc(-${imgHeight}px + 406px)) rotate(${rotate}deg)`
        : `translateX(-100%) rotate(${rotate}deg)`};
      transform-origin: ${zoomOutMap ? 'bottom left' : 'top right'};
    `}
`

export const NotFoundImgStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
