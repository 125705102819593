import { Tenant } from '@base39/ui-utils'
import i18n from '~i18n'

const tenant = Tenant.getTenant()

export const ALL_LOAN_STATUS = {
  IN_ANALYSIS_PAKETA: `loan.status.${tenant}.inAnalysisPaketa`,
  REQUESTED_CHANGES_PAKETA: `loan.status.${tenant}.underReviewPaketa`,
  REJECTED_PAKETA: `loan.status.${tenant}.rejectedByPaketa`,
  IN_ANALYSIS_RH: 'loan.status.inAnalysisRH',
  REJECTED_RH: 'loan.status.rejectedByRH',
  IN_ANALYSIS_FINANCIAL: 'loan.status.inAnalysisFinancial',
  SIGNED: 'loan.status.signed',
  REQUESTED_CHANGES_FINANCIAL: 'loan.status.underReviewFinancial',
  SIGNATURE_PENDING: 'loan.status.signaturePending',
  CANCELED: 'loan.status.canceled',
  DONE: 'loan.status.done',
  PAID: 'loan.status.paid',
}

export const STATUS_WITHOUT_PAYMENTS = [
  'IN_ANALYSIS_PAKETA',
  'CANCELED',
  'REJECTED_PAKETA',
  'REQUESTED_CHANGES_PAKETA',
  'REJECTED_RH',
  'SIGNATURE_PENDING',
  'IN_ANALYSIS_RH',
]

export const STATUS_AVAILABLE_TO_CHANGE = {
  IN_ANALYSIS_PAKETA: {
    status: [
      'IN_ANALYSIS_RH',
      'SIGNATURE_PENDING',
      'REJECTED_PAKETA',
      'REQUESTED_CHANGES_PAKETA',
    ],
  },
  REQUESTED_CHANGES_PAKETA: {
    status: ['IN_ANALYSIS_PAKETA'],
  },
  REJECTED_PAKETA: {
    status: ['IN_ANALYSIS_PAKETA'],
  },
  REQUESTED_CHANGES_RH: {
    status: [],
  },
  REJECTED_RH: {
    status: ['IN_ANALYSIS_PAKETA'],
  },
  IN_ANALYSIS_FINANCIAL: {
    status: [],
  },
  REQUESTED_CHANGES_FINANCIAL: {
    status: [],
  },
  SIGNATURE_PENDING: {
    status: ['IN_ANALYSIS_PAKETA'],
  },
  SIGNED: {
    status: ['IN_ANALYSIS_PAKETA', 'CANCELED'],
  },
  CANCELED: {
    status: ['IN_ANALYSIS_PAKETA'],
  },
  IN_ANALYSIS_RH: {
    status: ['IN_ANALYSIS_PAKETA'],
  },
}

export const HEADER_TABLE_LOAN = [
  {
    label: i18n.t('home.loans'),
    value: 'formattedName',
  },
  {
    label: i18n.t('home.company'),
    value: 'formattedCompany',
  },
  {
    label: i18n.t('home.value'),
    value: 'formattedValue',
  },
  {
    label: i18n.t('home.requested'),
    value: 'formattedRequested',
  },
  {
    label: i18n.t('home.status'),
    value: 'formattedStatus',
  },
  {
    label: i18n.t('home.assigned'),
    value: 'formattedAssign',
  },
  {
    label: i18n.t('home.updatedAt'),
    value: 'formattedUpdatedAt',
  },
]
export const STATUS_ASYNC = [
  'IN_ANALYSIS_FINANCIAL',
  'SIGNED',
  'SIGNATURE_PENDING',
  'DONE',
  'REJECTED_RH',
  'REQUESTED_CHANGES_FINANCIAL',
]
export const REJECTED_STATUS = [
  'REQUESTED_CHANGES_FINANCIAL',
  'REJECTED_PAKETA',
  'NOTE',
  'REJECTED_RH',
  'CANCELED',
]

export const OPTIONS_LOAN_FILTER = [
  { value: 'userName', label: i18n.t('general.employeeName') },
  { value: 'userCPF', label: i18n.t('general.cpf') },
]
