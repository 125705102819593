import React, { FC } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as ChevronDown } from '~assets/images/svg/chevron-down.svg'
import { withField } from '../Field'
import { AutoCompleteStyled, SmallTextFieldStyled } from './styles'
import { AutoCompleteProps } from './interfaces'

const Autocomplete: FC<AutoCompleteProps> = ({
  chipIcon,
  hideInput,
  options,
  width,
  ...props
}) => {
  return (
    <AutoCompleteStyled
      {...props}
      options={options}
      $hideInput={hideInput}
      popupIcon={<ChevronDown />}
      ChipProps={{ deleteIcon: <CloseIcon />, icon: chipIcon }}
      renderInput={(params) => (
        <SmallTextFieldStyled
          {...params}
          variant="standard"
          inputProps={{ ...params.inputProps, 'data-testid': 'autocomplete-input' }}
        />
      )}
      width={width}
    />
  )
}

export { Autocomplete }

export default withField(Autocomplete)
