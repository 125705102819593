import { createRef, RefObject } from 'react'
import { action, Action } from 'easy-peasy'
import { FormikProps } from 'formik'
import { DocumentLoanProps, DocumentProps } from '~containers/Documents/interfaces'

export interface DeskDocumentsListProps extends Omit<DocumentLoanProps, 'document'> {
  documents: DocumentProps[]
}

export interface DeskDocumentsModel {
  data?: DeskDocumentsListProps
  formikRef: RefObject<FormikProps<any>>
  setDocument: Action<DeskDocumentsModel, DeskDocumentsListProps>
}

const deskDocuments: DeskDocumentsModel = {
  data: null,
  formikRef: createRef(),
  setDocument: action((state, payload) => {
    state.data = payload
  }),
}

export default deskDocuments
