import React, { FC } from 'react'
import { Skeleton } from '~components'

function withSkeleton<T>(Component: FC<T>, height: number, width?: string | number) {
  return (props: T) => (
    <Skeleton height={height} width={width}>
      <Component {...props} />
    </Skeleton>
  )
}

function withSkeletonLoading(
  Component,
  loading: boolean,
  height = 32,
  width?: number | string,
) {
  return loading ? withSkeleton(Component, height, width) : Component
}

export default withSkeletonLoading
